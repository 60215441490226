import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './TwoPartTextField.scss';

export default class TwoPartTextField extends React.Component {
    render() {
        return (
            <MDBContainer className="TwoPartTextFieldContainer">
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow>
                    <MDBCol md={this.props?.field1Length || 6} className="TextField1">
                        <BasicTextField id={this.props?.id}
                            className="TextField1"
                            value={this.props?.value1 || ""}
                            onChange={this.props?.onChangeOfValue1}
                            disabled={this.props?.isField1Disabled || false}
                            placeholder={this.props?.placeholder1 || ""} />
                    </MDBCol>
                    <MDBCol md={this.props?.field2Length || 6} className="TextField2">
                        <BasicTextField id={this.props?.id + this.props?.id}
                            className="TextField2"
                            value={this.props?.value2 || ""}
                            onChange={this.props?.onChangeOfValue2}
                            disabled={this.props?.isField2Disabled || false}
                            placeholder={this.props?.placeholder2 || ""} />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}


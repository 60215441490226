import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBModal,
    MDBContainer, MDBIcon, MDBBtn
} from "mdb-react-ui-kit";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import SearchResults from '../../components/LandingView/SearchResults/SearchResults';
import Modal from '../../components/SharedComponents/Modal/Modal';
import { withUserContext } from '../../contexts/UserContext';
import { searchOpenList, searchStruckList } from './Config';
import * as Constants from '../../constants/constants';
import SetManagerService from '../../services/service';
import './LandingPage.scss'


class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewSetModal: false,
            isLoadingSetList: false,
            filterCriteria: {
                selectedSetId: "",
                selectedShowId: "",
                selectedIdPrefix: "",
                selectedIdSuffix: "",
                selectedCategoryId: "",
                selectedConditionId: "",
                selectedLocationId: "",
                isOpen: null,
                isStruck: 0,
                keywords: "",
                sortBy: "",
                order: ""
            },
            pageNo: 1,
            rowsPerPage: 10,
            isLoadingSets: false,
            setResults: [],
        }
    }
    checkManageUser = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;

        if (userPermissions?.isManageUser) {
            this.props.history.push('/userManagement');
        }
        else {
            if(this.props?.userContext?.active_tenant?.tenant_id) {
                this.props?.userContext.fetchsetList(this.props?.userContext?.active_tenant?.tenant_id);
                this.props?.userContext.getSetStaticList(this.props?.userContext?.active_tenant?.tenant_id);
                this.fetchAllSet();
            }
        }
    }

    toggleNewSetVisibility = () => {
        this.setState({ showNewSetModal: !this.state.showNewSetModal });
    }

    componentDidMount() {
        // this.fetchAllSet();
        this.checkManageUser();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props?.userContext?.active_tenant?.tenant_id !== prevProps?.userContext?.active_tenant?.tenant_id) {
            this.checkManageUser();
        }
    }



    fetchAllSet = () => {
        this.setState({ isLoadingSets: true });
        let searchObj = {
            "page_no": this.state.pageNo || 1,
            "page_count": this.state.rowsPerPage || 10,
            "set_id": this.state.filterCriteria?.selectedSetId?.value || null,
            "show_id": this.state.filterCriteria?.selectedShowId?.value || null,
            "id_prefix": this.state.filterCriteria?.selectedIdPrefix?.value || null,
            "id_suffix": this.state.filterCriteria?.selectedIdSuffix?.value || null,
            "category_id": this.state.filterCriteria?.selectedCategoryId?.value || null,
            "condition_id": this.state.filterCriteria?.selectedConditionId?.value || null,
            "location_id": this.state.filterCriteria?.selectedLocationId?.value || null,
            "is_open": this.state.filterCriteria?.isOpen,
            "is_stuck": this.state.filterCriteria?.isStruck,
            "keywords": this.state.filterCriteria?.keywords || null,
            "sort_by": "setName",
            "order": "asc"
        }
        SetManagerService.getDataParams(Constants.setManagerServiceBaseUrl + '/getAllSet', searchObj, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ setResults: response.data, isLoadingSets: false });
            },
                (err) => {
                    //this.setState({ isLoadingSets: false });
                    console.log("Error in fetching all sets", err)
                })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ pageNo: newPage + 1 }, () => {
            this.fetchAllSet()
        });
    }

    handleSearch = () => {
        this.setState({ pageNo: 1 }, () => {
            this.fetchAllSet()
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, pageNo: 1 }, () => {
            this.fetchAllSet();
        });
    }

    handleFilterChange = (field, value) => {
        this.setState(prevState => ({
            filterCriteria: {
                ...prevState.filterCriteria,
               [field]: value
            }
        }))
    }

    setEditCallback = (set) => {
        this.props?.userContext.fetchsetList(this.props?.userContext?.active_tenant?.tenant_id);
        this.setState(prevState => ({
            filterCriteria: {
                ...prevState.filterCriteria,
                ['selectedSetId']: set,
                ['selectedShowId']: "",
                ['selectedIdPrefix']: "",
                ['selectedIdSuffix']: "",
                ['selectedCategoryId']: "",
                ['selectedConditionId']: "",
                ['selectedLocationId']: "",
                ['isOpen']: null,
                ['isStruck']: 0,
                ['keywords']: "",
                ['sortBy']: "",
                ['order']: ""
            }
        }), this.fetchAllSet)
    }

    fetchAfterDelete = () => {
        this.setState({ pageNo: 1, rowsPerPage: 10 })
        this.setState(prevState => ({
            filterCriteria: {
                ...prevState.filterCriteria,
                ['selectedSetId']: "",
                ['selectedShowId']: "",
                ['selectedIdPrefix']: "",
                ['selectedIdSuffix']: "",
                ['selectedCategoryId']: "",
                ['selectedConditionId']: "",
                ['selectedLocationId']: "",
                ['isOpen']: null,
                ['isStruck']: 0,
                ['keywords']: "",
                ['sortBy']: "",
                ['order']: ""
            }
        }), this.fetchAllSet)
        this.props?.userContext.fetchsetList(this.props?.userContext?.active_tenant?.tenant_id);
    }

    clearAllFilters = () => {
       this.setState({filterCriteria: {
        selectedSetId: "",
        selectedShowId: "",
        selectedIdPrefix: "",
        selectedIdSuffix: "",
        selectedCategoryId: "",
        selectedConditionId: "",
        selectedLocationId: "",
        isOpen: null,
        isStruck: 0,
        keywords: "",
        sortBy: "",
        order: ""
    }, pageNo:1, rowsPerPage:10},this.fetchAllSet) 
    }

    render() {
        return (
            <MDBContainer fluid className="main-content">
                {this.state.showNewSetModal && <Modal open={this.state.showNewSetModal || false}
                    modalName={'NewSet'} comfirmModalTitle={'New Set'}
                    callback={this.setEditCallback.bind(this)}
                    onClose={this.toggleNewSetVisibility} />}

                <MDBRow>
                    <MDBCol md={2}>
                        <span className="headingLable">Search for Sets</span>
                    </MDBCol>
                    <MDBCol>
                        <BasicButton
                            onClick={() => this.toggleNewSetVisibility()}
                            text={"New Set"}
                            icon={"plus-circle"} />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>


                        <div className="search-content">
                            <MDBRow>
                                <MDBCol md={4}>
                                    <BasicLabel text={"Set Name"} />
                                    <SearchSelectField
                                        id={"set-name"}
                                        width={"90%"}
                                        placeholder={"-Search-"}
                                        value={this.state.filterCriteria?.selectedSetId || ""}
                                        options={this.props?.userContext?.dropDownList?.setNames || []}
                                        onChange={(e, value) => this.handleFilterChange('selectedSetId', value)}
                                    />
                                </MDBCol>
                                <MDBCol md={4} className={"centerElement"}>
                                    <BasicLabel text={"Category"} />
                                    <SearchSelectField
                                        id={"category"}
                                        width={"90%"}
                                        placeholder={"-Search-"}
                                        options={this.props?.userContext?.dropDownList?.category?.map((item) => ({ value: item?.value, text: item?.text })) || []}
                                        value={this.state.filterCriteria?.selectedCategoryId || ""}
                                        onChange={(e, value) => this.handleFilterChange('selectedCategoryId', value)}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <SelectField
                                        id={"open-not-open"}
                                        label={"Open/Not Open"}
                                        size="small"
                                        options={searchOpenList || []}
                                        value={this.state.filterCriteria?.isOpen === 0 ? 2 : this.state.filterCriteria?.isOpen || ""}
                                        onChange={(e) => this.handleFilterChange('isOpen', e.target.value == 2 ? 0 : e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <SelectField
                                        id={"struck-not-struck"}
                                        label={"Struck/Not Struck"}
                                        size="small"
                                        options={searchStruckList || []}
                                        value={this.state.filterCriteria?.isStruck === 0 ? 2 : this.state.filterCriteria?.isStruck || ""}
                                        onChange={(e) => this.handleFilterChange('isStruck', e.target.value == 2 ? 0 : e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4}>
                                    <BasicLabel text={"Show"} />
                                    <SearchSelectField
                                        id={"show"}
                                        width={"90%"}
                                        placeholder={"-Search-"}
                                        value={this.state.filterCriteria.selectedShowId || ""}
                                        options={this.props?.userContext?.dropDownList?.show?.map((item) => ({ value: item?.value, text: item?.text })) || []}
                                        onChange={(e, value) => this.handleFilterChange('selectedShowId', value)}
                                    />
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicLabel text={"ID Prefix"} />
                                            <SearchSelectField
                                                id={"id-prefix"}
                                                width={"80%"}
                                                placeholder={"-Search-"}
                                                value={this.state.filterCriteria.selectedIdPrefix || ""}
                                                options={this.props?.userContext?.dropDownList?.idPreffixes || []}
                                                onChange={(e, value) => this.handleFilterChange('selectedIdPrefix', value)}
                                            />
                                        </MDBCol>
                                        <MDBCol>
                                            <BasicLabel text={"ID Suffix"} />
                                            <SearchSelectField
                                                id={"id-suffix"}
                                                width={"80%"}
                                                placeholder={"-Search-"}
                                                value={this.state.filterCriteria.selectedIdSuffix || ""}
                                                options={this.props?.userContext?.dropDownList?.idSuffixes || []}
                                                onChange={(e, value) => this.handleFilterChange('selectedIdSuffix', value)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md={4} className={"centerElement"}>
                                    <BasicLabel text={"Condition"} />
                                    <SearchSelectField
                                        id={"condition"}
                                        width={"90%"}
                                        placeholder={"-Search-"}
                                        value={this.state.filterCriteria.selectedConditionId || ""}
                                        options={this.props?.userContext?.dropDownList?.condition?.map((item) => ({ value: item?.value, text: item?.text })) || []}
                                        onChange={(e, value) => this.handleFilterChange('selectedConditionId', value)}
                                    />
                                    <div>
                                        <BasicLabel text={"Location"} />
                                        <SearchSelectField
                                            id={"location"}
                                            width={"90%"}
                                            placeholder={"-Search-"}
                                            value={this.state.filterCriteria.selectedLocationId || ""}
                                            options={this.props?.userContext?.dropDownList?.location?.map((item) => ({ value: item?.value, text: item?.text })) || []}
                                            onChange={(e, value) => this.handleFilterChange('selectedLocationId', value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <BasicTextArea
                                        label={"Keywords"}
                                        rows={3}
                                        id={"keywords"}
                                        value={this.state.filterCriteria?.keywords || ""}
                                        onChange={(e) => this.handleFilterChange('keywords', e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={1}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        onClick={() => this.clearAllFilters()}
                                        text={"Clear All"}
                                        />
                                </MDBCol>
                                <MDBCol md={1}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={"Search"}
                                        onClick={() => this.handleSearch()}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <SearchResults setResults={this.state.setResults || []}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        pageNo={this.state.pageNo}
                        setResults={this.state.setResults || []}
                        isLoadingSets={this.state.isLoadingSets}
                        fetchAfterDelete={this.fetchAfterDelete.bind(this)}
                    />
                </MDBRow>

            </MDBContainer>
        )
    }
}

export default withUserContext(LandingPage);
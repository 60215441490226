import React from 'react';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import * as Constants from '../../constants/constants';
import SetManagerService from '../../services/service';
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import sizeLimits from '../../Common/SizeLimits.json';
import { withUserContext } from '../../contexts/UserContext';
import { initialUserDetails, initialRoleDetails , statusOption, roleOption, tenantOption } from './config';
import {validateUSNumber, validatePhoneNumber, validateIntlNumber, validatePlusCharacter, validateNumbersOnly, validateEmail, validateCharctersSpaceOnly } from '../../Common/Helper';
import './NewUser.scss'

class NewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPostJson : JSON.parse(JSON.stringify(initialUserDetails)),
            postInitiated : false,
            isPosting:false,
            statusOption : [],
            tenantOption: [],
            roleOption: [],
            editMode : true,
            isFetchingTenants : false,
            isFetchingRoles : false
        }
    }


    handleOnChange = (field, value) => {
        this.setState(prevState => ({
            userPostJson: {
                ...prevState.userPostJson,
                [field]: value
            },
            postInitiated:false,
        }),() => {
            this.props.handleMessageModalStatus('unsaved', true);
            if(field == "is_user_provisioner") {
                this.setState(prevState => ({
                    userPostJson: {
                        ...prevState.userPostJson,
                        ['user_roles']: value == 1 ? []: [{"tenant_id": null,"role_id": null}]
                    },
                }))
            }
        })
    }

    getDistinctArray = (arr) => {
        const distincitUserRoles = arr.filter((ele, index, arr) =>
                index === arr.findIndex((item) => (
                    item.tenant_id == ele.tenant_id
                )))
        return distincitUserRoles        
    }

    validateBeforeSave = (userDetails) => {
        let canSubmit = userDetails?.user_name && validateEmail(userDetails?.wb_email_id) && userDetails?.wb_email_id && userDetails?.wb_email_id.length <= sizeLimits.emailCharacterLimit &&
                        (userDetails?.notification_email !== null  ? validateEmail(userDetails?.notification_email) && userDetails?.notification_email && userDetails?.notification_email.length <= sizeLimits?.emailCharacterLimit: true) &&
                        (userDetails?.phone ? validatePlusCharacter((userDetails.phone.trim()).charAt(0)) ?
                        validateIntlNumber(userDetails.phone) && userDetails.phone.length <= sizeLimits.phoneLimitIntl :
                        (validateUSNumber(userDetails.phone) || validatePhoneNumber(userDetails.phone)) && 
                        userDetails.phone.length <= sizeLimits.phoneLimitUS : true)
                         && this.getDistinctArray(userDetails?.user_roles).length == userDetails?.user_roles.length  && 
                        userDetails?.user_roles?.every(item => item?.tenant_id !== null && item?.role_id !== null) && 
                        !this.props?.allMails?.includes(userDetails?.wb_email_id)
        return canSubmit
    }
    postUser = () => {
        this.setState({postInitiated : true})
        if(this.validateBeforeSave(this.state?.userPostJson)) {
            this.setState({isPosting:true})
            SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl +'/user',this.state?.userPostJson,this.props?.userContext?.active_tenant?.tenant_id)
                .then((response) => {
                    if(response.data.error) {
                        this.props.handleMessageModalStatus('fail', true);
                    } else {
                        this.props.handleMessageModalStatus('success', true);
                        this.props.callback(this.props?.setId);
                    }
                    this.setState({isPosting:true, postInitiated: false})

                },
                    (err) => {
                        this.props.handleMessageModalStatus('fail', true);
                        this.setState({isPosting:true, postInitiated: false})
                        // this.props?.handleSubmit("fail");
                        // this.props.fieldChanged(true, "fail");
                        //this.setState({ isPosting: false });
                    });
        }
}


getTenant = () => {
    this.setState({isFetchingTenants: true})
    SetManagerService.getData(Constants.setManagerServiceBaseUrl + `/staticData?staticTable=tenant`, this.props?.userContext?.active_tenant?.tenant_id)
        .then(response => {
            this.setState({allTenants : response.data, isFetchingTenants : false})
        },
            (err) => {
            })
}

getRoles = () => {
    this.setState({isFetchingRoles : true})
    SetManagerService.getData(Constants.setManagerServiceBaseUrl + `/staticData?staticTable=role`, this.props?.userContext?.active_tenant?.tenant_id)
        .then(response => {
            this.setState({allRoles : response.data, isFetchingRoles : false})
        },
            (err) => {
            })
}

componentDidMount() {
    if (this.props.mode === "edit") {
        this.setState(prevState => ({
            userPostJson: {
                "is_active": this.props?.userData?.is_active,
                "is_user_provisioner": this.props?.userData?.is_user_provisioner,
                "user_name": this.props?.userData?.user_name,
                "phone": this.props?.userData?.phone,
                "wb_email_id": this.props?.userData?.email,
                "notification_email": this.props?.userData?.notification_email,
                "user_id": this.props?.userData?.user_id,
                "is_delete": 0,
                "user_roles": this.props?.userData?.is_user_provisioner == 1 ? [] : [
                {
                    "tenant_id": this.props?.userData?.user_roles[0].tenant_id,
                    "role_id": this.props?.userData?.user_roles[0]?.role_id
                }
                ]
            },
        }),() => {
            this.setState({editMode:false})
        })
    }
    this.getTenant()
    this.getRoles() 
}

handleAdd = () => {
    let roles = this.state?.userPostJson?.user_roles
    roles.push({
        "tenant_id": null,
        "role_id": null
    })
    this.handleOnChange('user_roles', roles)
}

handleRemove = (deleteIndex) => {
    let roles = this.state?.userPostJson?.user_roles
    roles.splice(deleteIndex, 1)
    this.handleOnChange('user_roles', roles)
}

handleRoleChange = (field, value, index) => {
    let roles = this.state?.userPostJson?.user_roles
    let newRole = this.state?.userPostJson?.user_roles[index]
    newRole[field] = value
    roles.splice(index,1,newRole)
    this.handleOnChange('user_roles', roles)
}

    render() {
        let isLoading = this.props?.mode == 'edit' ? this.state?.editMode || this.state.isFetchingRoles || this.state.isFetchingTenants :
        this.state.isFetchingRoles || this.state.isFetchingTenants 
        return (
            <div>   
                {  isLoading  ?
                    <CircularProgress className="ContentLoader"/>
                :
                    <MDBContainer className="newUserContainer">
                    <MDBRow>
                        <MDBCol md={4}>
                            <SelectField
                                id={"status"}
                                label={"Status"}
                                size="small"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated}
                                options={statusOption || []}
                                value={this.state.userPostJson?.is_active == 0 ? 2 : this.state.userPostJson?.is_active || null}
                                onChange={(e) => this.handleOnChange('is_active', e.target.value == 2 ? 0 : e.target.value )}
                            />
                        </MDBCol>
                        <MDBCol md={6} className="check">
                            <BasicCheckbox
                                id={"userProvisioner"}
                                label="User Provisioner"
                                checked={(this.state.userPostJson?.is_user_provisioner == 1 ? true : false)}
                                onChange={(e) => this.handleOnChange('is_user_provisioner', (e.target.checked ? 1 : 0))}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={10}>
                            <BasicTextField
                                label="Full Name"
                                isMandatory={true}
                                value={this.state.userPostJson?.user_name || ""}
                                showMandatory={this.state.postInitiated}
                                onChange={(e) => validateCharctersSpaceOnly(e.target.value) && this.handleOnChange('user_name', e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}>
                        <BasicTextField
                                label="Phone"
                                value={this.state.userPostJson?.phone || ""}
                                fieldValid={ this.state.userPostJson?.phone ?
                                    !(validatePlusCharacter((this.state.userPostJson?.phone.trim()).charAt(0)) ? 
                                    validateIntlNumber(this.state.userPostJson?.phone) : 
                                    (validateUSNumber(this.state.userPostJson?.phone) || validatePhoneNumber(this.state.userPostJson?.phone))) : false}
                                inValidInput={"Please enter valid phone"}
                                limit={this.state.userPostJson?.phone && validatePlusCharacter((this.state.userPostJson?.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                limitWarning={`Exceeded ${this.state.userPostJson?.phone && validatePlusCharacter((this.state.userPostJson?.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS} Numbers limit. Please re-enter`}
                                onChange={(e) => e.target.value.length > 0 ? this.handleOnChange('phone', e.target.value): this.handleOnChange('phone', null)}
                            />
                        </MDBCol>
                        <MDBCol md={8}>
                            <BasicTextField
                                label="Email"
                                isMandatory={true}
                                limit={sizeLimits.emailCharacterLimit}
                                limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                                value={this.state.userPostJson?.wb_email_id || ""}
                                onChange={(e) =>  this.handleOnChange('wb_email_id', e.target.value.length > 0 ? e.target.value : null)}
                                showMandatory={this.state.postInitiated}
                                inValidInput={"Invalid Email"}
                                fieldValid={!validateEmail(this.state.userPostJson?.wb_email_id) && this.state.userPostJson?.wb_email_id}
                            />
                            <span className="errorText">{this.props?.allMails?.includes(this.state.userPostJson?.wb_email_id) && "Email already exists"}</span>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}></MDBCol>
                        <MDBCol md={8}>
                            <BasicTextField
                                label="Notification Email"
                                //isMandatory={true}
                                limit={sizeLimits.emailCharacterLimit}
                                limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                                value={this.state.userPostJson?.notification_email || ""}
                                inValidInput={"Invalid Email"}
                                onChange={(e) => this.handleOnChange('notification_email', e.target.value.length > 0 ? e.target.value : null)}
                                fieldValid={!validateEmail(this.state.userPostJson?.notification_email) && this.state.userPostJson?.notification_email}
                            />
                        </MDBCol>
                    </MDBRow>
                    {this.state?.userPostJson?.is_user_provisioner == 0 ? 
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicLabel text="Tenant" isMandatory={true}/>
                            </MDBCol>
                            <MDBCol md={8} className="roleType">
                                <BasicLabel text="Role" isMandatory={true}/>
                            </MDBCol>
                        </MDBRow>
                    :null}
                    {this.state?.userPostJson?.is_user_provisioner == 0 &&this.state?.userPostJson?.user_roles?.map((item,index) => 
                            <MDBRow>
                            <MDBCol md={4}>
                                <SelectField
                                    id={"tenant-name"}
                                    value={item?.tenant_id|| ""}
                                    options={this.state.allTenants?.map(item => ({ label : item?.name , value : item?.id}))  || []}
                                    showMandatory={this.state.postInitiated}
                                    onChange={(e) => this.handleRoleChange('tenant_id', e.target.value, index)}
                                />
                            </MDBCol>
                            <MDBCol md={6} className="roleType">
                                <SelectField
                                    id={"tenant-role"}
                                    value={item?.role_id || ""}
                                    options={this.state.allRoles?.map(item => ({ label : item?.name , value : item?.id})) || []}
                                    showMandatory={this.state.postInitiated}
                                    onChange={(e) => this.handleRoleChange('role_id', e.target.value, index)}
                                />
                            </MDBCol>
                            <MDBCol md={1}> 
                                { this.state?.userPostJson?.user_roles.length > 1 ? 
                                    <RemoveCircleIcon onClick={() => this.handleRemove(index)}/>
                                    :null
                                }
                            </MDBCol>
                            <MDBCol md={1}> 
                                { item?.tenant_id !== null && item?.role_id !== null &&
                                    this.state?.userPostJson?.user_roles.length-1 === index ?
                                        <AddCircleIcon
                                        onClick={this.handleAdd} />  
                                    :null
                                } 
                            </MDBCol>
                        </MDBRow>
                    )}
                    {this.getDistinctArray(this.state?.userPostJson?.user_roles).length !== 
                        this.state?.userPostJson?.user_roles.length ? 
                        <span className="errorText">For 1 tenant only 1 role can be assigned</span> 
                    : null}
                    <MDBRow>
                        <MDBCol md={8} >
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                variant="contained"
                                type="inline"
                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.mode == 'new' ?  "Create" : "Save"}
                                onClick={this.postUser.bind(this)}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={this.props.onClose}
                                disabled={false}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                }
            </div>
        )
    }
}

export default  withUserContext(NewUser);

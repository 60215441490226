
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './Config';
import AddSetContainer from '../../../components/AddSet/AddSet';
import MessageModal from '../../../components/SharedComponents/MessageModal';

import './Modal.scss';

class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            saveFlag:true
        }
        this.submitButtonRef = React.createRef();
    }

    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false,showFail:false, showSuccess:false});
        if(this.state.saveFlag){
            this.props?.handleClose();
        }
    }
    deletePost = (save_flag) =>{
        this.setState({saveFlag:save_flag});
    }
    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'New Audition': return <NewAudition
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                fieldChanged={this.fieldChanged}
                handleSubmit = {this.props?.handleSubmit}
            />            
        }
    }


    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return classes.AddSetContainer;
            default: return '';
        }
    }
    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return 'sm';
            default: return 'md';
        }
    }

    render() {

        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
                    (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <div className="MainViewModalContainer">
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()} className={this.getClasses()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader" id={"modal_header"}>
                                                    <MDBCol md={5} className="HeaderText" >{(this.props.mode === 'Both' ? "" : this.props.mode) + " " + this.props?.headerText}</MDBCol>
                                                    <MDBCol md={6} className="HeaderText" ></MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withStyles(useStyles)(MainViewModalComponent);

import React from 'react';
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './Modal.scss';
//import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageModal from '../../SharedComponents/MessageModal';
import RevampSet from '../../RevampSet/RevampSet';
import EmailCatalog from '../../EmailCatalog/EmailCatalog';
import NewSet from '../../NewSet/NewSet';
import PrintCatalog from '../../PrintCatalog/PrintCatalog';
import ExportCatalog from '../../ExportCatalog/ExportCatalog';
import AddFile from '../../AddFiles/AddFile';
import ManageLookup from '../../ManageLookup/ManageLookup'
import NewUser from '../../NewUser/NewUser';
//import { useStyles } from './Config';
import { withUserContext } from '../../../contexts/UserContext';
import SortCatalog from "../../SortCatalog/SortCatalog"

class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMessageModalOpen: false,
            haveUnsavedChanges: false,
            isPostingSuccess: false,
            isPostingFailed: false
        }
    }

    handleMessageModalStatus = (field, value) => {
        if (field === "unsaved") {
            this.setState({ haveUnsavedChanges: value });
        } else if (field === "success") {
            this.setState({
                isPostingSuccess: true,
                isPostingFailed: false,
                haveUnsavedChanges: false,
                isMessageModalOpen: true
            });
        } else if (field === "fail") {
            this.setState({
                isPostingSuccess: false,
                isPostingFailed: true,
                haveUnsavedChanges: false,
                isMessageModalOpen: true
            });
        }

    }

    onModalClose = () => {
        if (this.state.haveUnsavedChanges) {
            this.setState({ isMessageModalOpen: true });
        } else {
            this.props.onClose();
        }
    }

    onModalConfirm = () => {
        this.setState({ isMessageModalOpen: false });
        this.props.onClose();
        if (this.props?.handleSubmit) {
            this.props?.handleSubmit()
        }
    }

    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'NewSet': return <NewSet
                onClose={this.props.onClose}
                onModalClose={this.onModalClose}
                setDetails={this.props.list || {}}
                mode={this.props.comfirmModalTitle}
                handleMessageModalStatus={this.handleMessageModalStatus}
                comfirmModalTitle={'New Set'}
                callback={this.props.callback}
            />
            case 'RevampSet': return <RevampSet
                onClose={this.props.onClose}
                setId={this.props?.setId}
                onModalClose={this.onModalClose}
                callback={this.props.callback}
                handleMessageModalStatus={this.handleMessageModalStatus}
                {...this.props}

            />
            case 'EmailCatalog': return <EmailCatalog
                onClose={this.props.onClose}
                primaryButtonText={'Create'}
                secondaryButtonText={'Cancel'}
                catalogType="email"
                {...this.props}
            />
            case 'ExportCatalog': return <ExportCatalog
                onClose={this.props.onClose}
                comfirmModalTitle={'Export Catalog'}
                catalogType="export"
                {...this.props}
            />
            case 'PrintCatalog': return <PrintCatalog
                onClose={this.props.onClose}
                comfirmModalTitle={'Print Catalog'}
                catalogType="print"
                {...this.props}
            />
            case 'SortCatalog': return <SortCatalog
                onClose={this.props.onClose}
                comfirmModalTitle={'Sort Catalog'}
                catalogType="sort"
                onModalClose={this.onModalClose}
                handleMessageModalStatus={this.handleMessageModalStatus}
                callback={this.props.callback}
                {...this.props}
            />

            case 'AddCatalogFile': return <AddFile
                onClose={this.props?.onClose}
                comfirmModalTitle={'Add Photos'}
                primaryButtonText={'Upload'}
                secondaryButtonText={'Cancel'}
                {...this.props}
            />
            case 'AddCadFiles': return <AddFile
                onClose={this.props?.onClose}
                comfirmModalTitle={'Add Cad File'}
                primaryButtonText={'Upload'}
                secondaryButtonText={'Cancel'}
                {...this.props}
            />
            case 'ManageLookup': return <ManageLookup
                onClose={this.props?.onClose}
                primaryButtonText={'Submit'}
                secondaryButtonText={'Cancel'}
                handleMessageModalStatus={this.handleMessageModalStatus}
                onModalClose={this.onModalClose}
            />
            case 'NewUser': return <NewUser
                handleMessageModalStatus={this.handleMessageModalStatus}
                callback={this.props.callback}
                onModalClose={this.onModalClose}
                onClose={this.props?.onClose}
                comfirmModalTitle={this.props.comfirmModalTitle}
                mode={this.props?.mode}
                userData={this.props?.userData}
                {...this.props}
            />
            default: return null;
        }
    }

    // getClasses = () => {
    //     const { classes } = this.props;
    //     switch (this.props?.modalName) {
    //         case 'NewSet': return classes?.modalName;
    //         case 'RevampSet': return classes?.revampSet;
    //         default: return '';
    //     }
    // }

    handlePermissions = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let showSave = false;
        switch (this.props?.modalName) {
            case 'ManageLookup':
                showSave = userPermissions?.isManageLookups ? userPermissions?.isManageLookups : false
                break;
            case 'NewSet':
                showSave = userPermissions?.isModifySet ? userPermissions?.isModifySet : false
                break;
            case 'RevampSet':
                showSave = userPermissions?.isModifySet ? userPermissions?.isModifySet : false
                break;
            case 'AddCatalogFile':
                showSave = userPermissions?.isModifySet ? userPermissions?.isModifySet : false
                break;
            case 'AddCadFiles':
                showSave = userPermissions?.isModifySet ? userPermissions?.isModifySet : false
                break;
            case 'EmailCatalog':
                showSave = userPermissions?.isViewSet ? userPermissions?.isViewSet : false
                break;
            case 'ExportCatalog':
                showSave = userPermissions?.isViewSet ? userPermissions?.isViewSet : false
                break;
            case 'PrintCatalog':
                showSave = userPermissions?.isViewSet ? userPermissions?.isViewSet : false
                break;
            case 'SortCatalog':
                showSave = userPermissions?.isViewSet ? userPermissions?.isViewSet : false
                break;
            case 'NewUser':
                showSave = userPermissions?.isManageUser ? userPermissions?.isManageUser : false
                break;
        }
        return showSave;
    }

    getClassesForModal = () => {
        switch (this.props?.modalName) {
            case 'NewSet': return 'lg';
            case 'RevampSet': return 'sm';
            case 'AddCadFiles': return 'lg';
            case 'AddCatalogFile': return 'lg';
            case 'EmailCatalog': return 'lg';
            case 'PrintCatalog': return 'lg'
            case 'ExportCatalog': return 'lg';
            case 'NewUser': return 'md';
            case 'SortCatalog': return 'lg';
            default: return 'md';
        }
    }

    render() {
        return (
            <div className="ModalContentContainer">
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader" id={"modal_header"}>
                                                    <MDBCol md={5} className="HeaderText">{this.props.comfirmModalTitle}</MDBCol>
                                                    <MDBCol md={6} className="HeaderText" ></MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onModalClose}><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody" disabled={!this.handlePermissions()}>
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                    <MessageModal
                        open={this.state.isMessageModalOpen}
                        showError={this.state.haveUnsavedChanges || false}
                        showFail={this.state.isPostingFailed || false}
                        showSuccess={this.state.isPostingSuccess || false}
                        primaryButtonText={"Ok"}
                        secondaryButtonText={"Cancel"}
                        hideCancel={this.state.isPostingFailed || this.state.isPostingSuccess || false}
                        onConfirm={() => this.onModalConfirm()}
                        handleClose={(e) => this.setState({ isMessageModalOpen: false })} />
                </MDBModal>
            </div>
        );
    }
}

// export default withUserContext(withStyles(useStyles)(Modal));
export default withUserContext(Modal);
import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import MiniField from '../../components/SharedComponents/MiniField/MiniField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import setManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { initialAudition } from "../AddSet/config"
import moment from "moment";
import './AddSet.scss';
import { setManagerServiceBaseUrl } from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';

export default withUserContext(class AddSet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            auditionDetails: initialAudition,
            statusOptions: [],
            isFetchingAuditionStatus: false,
            postInitiated: false,
            isPosting: false

        }
    }
    componentDidMount = () => {
        this.getStatusOptionData();
        if (this.props.mode === "Edit") {
            // this.getAuditionDetails(9);           
            this.getAuditionDetails(this.props?.id);
        }
        else{
            initialAudition["production_id"] = this.props?.production_id;
            initialAudition["ps_id"] = this.props?.production_season_id;
            initialAudition["episode_id"] = this.props?.episode_id;
            this.setState({ auditionDetails: initialAudition });
        }
    }
    getAuditionDetails = (id) => {
        this.setState({ isFetchingAuditionStatus: true });
        setManagerService.getData(`${Constants.setManagerServiceBaseUrl}/audition?auditionId=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                let time = moment(response.data[0].audition_time, "HH:mm:ss");
                response.data[0].audition_time = time;
                this.setState({ auditionDetails: response.data[0], isFetchingAuditionStatus: false });

            }, (err) => {
                this.setState({ isFetchingAuditionStatus: false, auditionDetails: initialAudition });
                console.log("error in fetching details", err)
            })
    }
    getStatusOptionData = () => {
        this.setState({ isFetchingAuditionStatus: true });
        setManagerService.getData(`${Constants.setManagerServiceBaseUrl}/staticData?staticTable=lu_audition_status`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let data = response.data?.map((status) => ({ value: status.id, label: status.name }))
                this.setState({ statusOptions: data, isFetchingAuditionStatus: false })
                console.log(response.data);
            }, (err) => {
                this.setState({ isFetchingAuditionStatus: false, statusOptions: [] })
                console.log("error in fetching status data:", err)
            })
    }
    handleChange = (field, value) => {
        this.setState(prevState => ({
            auditionDetails: {
                ...prevState.auditionDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.fieldChanged(true, "change");
        })
    }
    handleTime = (time) => {
        return moment(time).format("HH:mm:ss");
    }

    submitAuditionDetails = () => {
        this.setState({ postInitiated: true });

        if (this.isValidFields()) {
            let time = this.state.auditionDetails.audition_time;
            let audition_time = undefined;
            if (time) {
                audition_time = this.handleTime(time)
            }
            console.log(this.state.auditionDetails + "  " + audition_time);
            this.setState({ isPosting: true });
            setManagerService.postDataParams(`${Constants.setManagerServiceBaseUrl}/audition`, { ...this.state.auditionDetails, audition_time }
                , this.props.userContext?.active_tenant?.tenant_id)
                .then((response) => {
                    this.setState({ isPosting: false });
                    this.props?.handleSubmit("success", response?.data?.[0]?.pse_audition_id);
                    console.log(response);
                    this.props.fieldChanged(false, "error");
                    this.props.fieldChanged(true, "success");
                }, (err) => {
                    this.props?.handleSubmit("fail");
                    this.props.fieldChanged(true, "fail");
                    this.setState({ isPosting: false });
                })
        } else {
            alert("Post fail")
        }

    }
    isValidFields = () => {
        let { talent_name, role, audition_date } = this.state.auditionDetails
        let canSubmit = talent_name && role && audition_date
        return canSubmit;
    }
    render() {

        let isLoading = this.state.isFetchingEpisodeDetails && this.state.isFetchingEpisodeTypes;
        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="NewAuditionContainer">
                        <MDBRow>
                            <MDBCol md={6} className="searchSelectcontent">
                                <BasicTextField
                                    label={"Performer"}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.auditionDetails?.talent_name || ""}
                                    onChange={(e) => this.handleChange('talent_name', e.target.value)}
                                />
                                {/* <BasicLabel
                            // type={"fieldHeading"}
                            text={"Performer"}
                            isMandatory={"true"}
                        />
                        <SearchSelectField
                            className="SearchSelectWidth"
                            id={"audition-select"}
                        /> */}
                            </MDBCol>
                            <MDBCol md={6}>
                                <SelectField
                                    id={"episode-type"}
                                    size="small"
                                    label={"Status"}
                                    value={this.state.auditionDetails?.status_id || ""}
                                    options={this.state.statusOptions}
                                    onChange={(e) => this.handleChange('status_id', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    label={"Role Audition for"}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.auditionDetails?.role || ""}
                                    onChange={(e) => this.handleChange('role', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <DateField
                                    value={this.state.auditionDetails.audition_date || ""}
                                    label={"From Date"}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    onChange={(e) => this.handleChange("audition_date", new Date(e.target.value).toISOString())}
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicLabel
                                    text={"Time"}
                                />
                                <MDBRow>
                                    <TimePicker
                                        className={"timePicker"}
                                        value={this.state.auditionDetails?.audition_time || undefined}
                                        defaultValue={this.state.auditionDetails?.audition_time || undefined}
                                        onChange={(e) => this.handleChange("audition_time", e)}
                                    />
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="textArea">
                            <BasicTextArea
                                label={"Notes"}
                                id={"textarea-performer"}
                                rows={1}
                                value={this.state.auditionDetails?.notes || ""}
                                onChange={(e) => this.handleChange('notes', e.target.value)}
                            />
                        </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={8} >
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Add"}
                                    disabled={false}
                                    onClick={this.submitAuditionDetails}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose}
                                    disabled={false}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
            </>
        )
    }
}
);
import React, { Component } from 'react';

//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
//import "../styles/app.scss";
import { Route, Switch, withRouter } from 'react-router-dom';
import {Security, withOktaAuth, SecureRoute, LoginCallback } from '@okta/okta-react';
//import authService from "./app.config";
import oktaAuth, {restoreOriginalUri} from "./app.config"
import Home from "./Home";
import ErrorPage from '../error-boundary/ErrorPage';
import { ThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from './MaterialTheme';


export default  //withOktaAuth(
  withRouter(  class App extends Component {
  
  constructor(props) {
    super(props);     
    this.state = {
      isLoggedOut : false,
      errorId : 0,
      errorDesc: ''
    }
  }
 
  componentDidMount() {
    console.log("Loading App");
  }
  render() {
    return (
      this.state.isLoggedOut ? <ErrorPage isLoggedIn = {!this.state.isLoggedOut} errorId = {this.state.errorId} errorDesc = {this.state.errorDesc} ></ErrorPage>:
      <>
      <ThemeProvider theme={MaterialTheme}>
          <Security oktaAuth = {oktaAuth} restoreOriginalUri={restoreOriginalUri}>
               {/* <Switch>   */}
               <SecureRoute true path='/'  component={Home}/>
               <Route path='/callback' component={LoginCallback}/>
               
               {/* </Switch>      */}
          </Security>
      </ThemeProvider>
     </>
    );
  }
}
//);//withOktaAuth
); //withRouter



import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import TestBasicComp from '../pages/TestBasicComp/TestBasicComp';
import UserManagement from '../pages/UserManagement/UserManagement';
import LandingPage from '../home/LandingPage/LandingPage';


export default withOktaAuth(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (

      <Switch>
        <Route exact={true} path="/" render={(props) => <LandingPage {...props}/>} />
        <Route exact={true} path='/test-basic-comp' render={(props) => <TestBasicComp {...props} />} />
        <Route exact={true} path='/userManagement' render={(props) => <UserManagement {...props} />} />
      </Switch>
    );
  }
}
);

export const userListConfig = {
    headings: [
        {
            headingLabel: "Name",
            icon: "sort-alpha-up",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Phone",
            icon: "",
            width: "10%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            icon: "",
            width: "30%",
            inputType: "text"
        },
        {
            headingLabel: "Role(s)",
            icon: "",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Status",
            icon: "",
            width: "5%",
            inputType: "text"
        },

    ],
    dataNodes: ["user_name", "phone", "email", "roles", "status",],
    primaryKey: "user_id",
    hyperlinks: ["user_name"],
    actions: ["pen"],
    changeColourToRed: ["InActive"],
    changeColourToGreen: ["Active"]
}
import React from 'react';
import TextSeparatedField from '../../components/SharedComponents/TextSeparatedField';
import MiniField from '../../components/SharedComponents/MiniField/MiniField';
import DateField from '../../components/SharedComponents/DateField/DateField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import SelectFieldHeader from '../../components/SharedComponents/SelectFieldHeader/SelectFieldHeader';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const selectOptions = [
    {
        label: "Princess Elsa",
        value: 1
    },
    {
        label: "Princess Anna",
        value: 2
    },
    {
        label: "Princess Jasmin",
        value: 3
    }
]

export default class Field2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            twoPartValue1: "twoPartValue1",
            twoPartValue2: "twoPartValue2",
            miniFieldInput: 8,
            dateValue: new Date('6/25/2021'),
            selectValue: ""
        }
    }
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <div style={{ paddingBottom: '20px' }}>
                        <TextSeparatedField id={"key8"}
                            value1={this.state.twoPartValue1}
                            value2={this.state.twoPartValue2}
                            text={"Sample"}
                            onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                            onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <TextSeparatedField id={"key9"}
                            value1={this.state.twoPartValue1}
                            value2={this.state.twoPartValue2}
                            placeholder1={"Watermark"}
                            placeholder2={"Watermark"}
                            text={"Sample1"}
                            onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                            onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <TextSeparatedField id={"key10"}
                            value1={this.state.twoPartValue1}
                            value2={this.state.twoPartValue2}
                            isField1Disabled={true}
                            isField2Disabled={true}
                            text={"Sample3"}
                            onChangeOfValue1={(e) => this.setState({ twoPartValue1: e.target.value })}
                            onChangeOfValue2={(e) => this.setState({ twoPartValue2: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <MiniField id={"key11"}
                            label={"Field Name"}
                            isMandatory={true}
                            value={this.state.miniFieldInput}
                            onChange={(e) => this.setState({ miniFieldInput: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <MiniField id={"key12"}
                            value={this.state.miniFieldInput}
                            disabled={true}
                            onChange={(e) => this.setState({ miniFieldInput: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <DateField id={"key13"}
                            value={this.state.dateValue}
                            label={"Field Name"}
                            isMandatory={true}
                            onChange={(e) => this.setState({ dateValue: new Date(e.target.value) })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <DateField id={"key13"}
                            value={this.state.dateValue}
                            disabled={true}
                            onChange={(e) => this.setState({ dateValue: new Date(e.target.value) })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <SelectFieldHeader id={"key14"}
                            value={this.state.selectValue}
                            options={selectOptions}
                            onChange={(e) => this.setState({ selectValue: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <SelectField id={"key14"}
                            value={this.state.selectValue}
                            options={selectOptions}
                            onChange={(e) => this.setState({ selectValue: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '40px' }}>
                        <SelectField id={"key15"}
                            value={this.state.selectValue}
                            label={"Field Name"}
                            isMandatory={true}
                            defaultMenuText={"Default Menu Text"}
                            options={selectOptions}
                            disabled={true}
                            onChange={(e) => this.setState({ selectValue: e.target.value })} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <BasicLabel
                            text={"Field Name"} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <BasicLabel
                            type={"fieldHeading"}
                            text={"Field Name"}
                            isMandatory={true} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <BasicLabel
                            type={"subHeading"}
                            text={"Field Name"}
                            isMandatory={true} />
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                        <BasicLabel
                            type={"heading"}
                            text={"Field Name"}
                            isMandatory={true} />
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        )
    }
}

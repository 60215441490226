import React from 'react';
import TableComponent from '../../components/SharedComponents/Table';
import Modal from '../../components/SharedComponents/Modal/Modal';
import { userListConfig } from '../UserManagement/config';
import "../UserManagement/usermanagement.scss";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { MDBRow, MDBCol } from "mdbreact";
import IconTextField from '../../components/SharedComponents/IconTextField/IconTextField';
import { handlePaginationList } from '../../../src/Common/TableHelper';
import TablePagination from '@material-ui/core/TablePagination';
import * as Constants from '../../constants/constants';
import SetManagerService from '../../services/service';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../contexts/UserContext';
class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allData: [],
            tableList: [],
            tableListSorted: [],
            a_z: false,
            page: 0,
            pageCount: 10,
            newUserModal: false,
            isFetchingUserDetails: false,
            mode: null,
            userId: null,
            totalCount: 0,
            searchTerm:null,
            filteredListData : [],
        }
    }

    closeNewUserModal = () => {
        this.setState({ newUserModal: false, userId: null, mode: null })
    }

    componentDidMount = () => {
        this.getAllUsers()
    }

    handleSearch = (value) => {
        this.setState({
            searchTerm: value,
            page:0,
        }, () => {
            if (value) {
                let filteredList = this.state.tableList?.filter(item => item.user_name?.toLowerCase().includes(value.toLowerCase()));
                this.setState({ tableListSorted: handlePaginationList(this.state.page, this.state.pageCount, filteredList), totalCount: filteredList?.length, filteredListData:filteredList});
            } else {
                this.setState({ tableListSorted: handlePaginationList(this.state.page, this.state.pageCount, this.state.tableList), totalCount: this.state.tableList?.length, filteredListData:[]});
            }
        })
    }

    getAllUsers = () => {
        this.setState({ isFetchingUserDetails: true , page: 0, pageCount:10, searchTerm:null, filteredListData:[]});
        //let userObj = { "page_count": this.state?.pageCount, "page_no": this.state?.page }
        SetManagerService.getDataParams(Constants.setManagerServiceBaseUrl + `/usersAll`, null, this.props?.userContext?.active_tenant?.tenant_id, true)
            .then(response => {
                let tableData = response?.data?.data.map((item) => {
                    let tablerow = {
                        "user_id": item?.user_id,
                        "user_name": item?.user_name,
                        "phone": item?.phone,
                        "email": item?.email,
                        "roles": item?.is_user_provisioner == 1 ? ["User Provisioner"] : item?.user_roles?.map(ele => ele?.role_name),
                        "status": item?.is_active == 1 ? 'Active' : 'InActive'
                    }
                    return tablerow
                })
                this.setState({ allData: response?.data?.data, tableList: tableData, tableListSorted: handlePaginationList(this.state.page, this.state.pageCount, tableData), a_z: false, isFetchingUserDetails: false, totalCount: response?.data?.total_count });
            },
                (err) => {
                    console.log("Error in fetching set Details:", err)
                    this.setState({ isLoadingSetDetails: false });
                })
    }

    handleChangePage = (event, newPage) => {
        // this.setState({ page: newPage + 1 }, () => {
        //     this.getAllUsers()
        // });
        this.setState({
            page: newPage,
            tableListSorted: handlePaginationList(newPage, this.state.pageCount, this.state.searchTerm ? this.state.filteredListData  : this.state.tableList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        // this.setState({ pageCount: event.target.value, page: 1 }, () => {
        //     this.getAllUsers();
        // });
        this.setState({
            pageCount: parseInt(event.target.value, 10), page: 0,
            tableListSorted: handlePaginationList(0, parseInt(event.target.value, 10), this.state.searchTerm ? this.state.filteredListData :this.state.tableList)
        });
    }

    sortUserName = (val) => {
        if (val == 'Name') {
            this.setState({page:0}, () => {
                let sortList = this.state.searchTerm ? this.state.filteredListData: [...this.state.tableList]
            if (this.state.a_z) {
                sortList.reverse()
                if(userListConfig?.headings[0]?.icon == "sort-alpha-down") {
                    userListConfig.headings[0].icon = "sort-alpha-up"
                } else {
                    userListConfig.headings[0].icon = "sort-alpha-down"
                }
            } else {
                sortList.sort((a, b) => a.user_name.localeCompare(b.user_name))
                this.setState({ a_z: true })
                userListConfig.headings[0].icon = "sort-alpha-down"
            }
            this.setState({ tableList : this.state.searchTerm ? this.state.tableList : sortList , filteredListData: this.state.searchTerm? sortList : [],
                 tableListSorted: handlePaginationList(this.state.page, this.state.pageCount, sortList) })
            })  
        }
    }

    handleEditUser = (val, item) => {
        this.setState({ mode: "edit", userId: val, newUserModal: true })
    }

    handleUserSelection = (item, node) => {
        if (node == 'user_name') {
            this.setState({ mode: "edit", userId: item?.user_id, newUserModal: true })
        }
    }

    handleUserDetailsUpdate = () => {
        this.setState({ page: 1, pageCount: 10 })
        this.getAllUsers()
    }

    render() {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        return (
            <div>
                {userPermissions?.isManageUser ?
                    <div>
                        {this.state.newUserModal && <Modal open={this.state.newUserModal || false}
                            modalName={'NewUser'} onClose={this.closeNewUserModal}
                            userData={this.state.mode == "edit" ? this.state?.allData?.find(item => item?.user_id == this.state.userId) : null}
                            comfirmModalTitle={this.state.mode == "edit" ? 'Edit User' : 'New User'}
                            mode={this.state.mode}
                            allMails={this.state.mode == "edit" ? 
                                        this.state?.allData?.map(item => {
                                            if(item?.user_id !== this.state.userId) {
                                                return item?.email
                                            } else {
                                                return null
                                            }
                                        })
                                        : this.state?.allData?.map(item => item?.email)}
                            callback={this.handleUserDetailsUpdate.bind(this)}
                        />}
                        <div className='User-Management'>
                            <MDBRow>
                                <MDBCol md={4}>
                                    <h2>User Management</h2>
                                </MDBCol>
                                <MDBCol  md={4} className="search">
                                    <IconTextField placeholder={'Search Users' }
                                        value={this.state.searchTerm || ""}
                                        onChange={(e) => this.handleSearch(e.target.value)}
                                        icon='search' />
                                </MDBCol>
                                <MDBCol md={4}>
                                    <BasicButton
                                        className="FR MR1"
                                        text={"New User"}
                                        onClick={() => this.setState({ mode: "new", newUserModal: true })}
                                        type="inline"
                                        variant="outlined"
                                        //disabled={this.state?.history}
                                        icon={"plus"} />
                                </MDBCol>
                            </MDBRow>
                            <div class='row'>
                                <div class='col-xs-12' id='usermanagement'>
                                    <TableComponent
                                        list={this.state?.tableListSorted || []}
                                        config={userListConfig}
                                        tableMode={"light"}
                                        isLoading={this.state.isFetchingUserDetails || false}
                                        sortCallback={this.sortUserName}
                                        hyperLinkNavigationCallback={(item, node) => this.handleUserSelection(item, node)}
                                        editCallback={(val, item) => this.handleEditUser(val, item)}
                                    />
                                    <TablePagination
                                        component="div"
                                        count={this.state.totalCount || 0}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.pageCount}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <MDBRow className='User-Management'>You do not have permissions to manage users</MDBRow>
                }
            </div>
        );
    }
}

export default withUserContext(UserManagement);
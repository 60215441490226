import React from 'react';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CommonCatalog from '../CommonCatalog/CommonCatalog';
import './PrintCatalog.scss'
import ReactToPrint from 'react-to-print';
class PrintCatalog extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            selectedCatalogFiles:[]
        }       
    }

    getSelectedCatalogFiles(selectedArray){       
        this.setState({selectedCatalogFiles:selectedArray})
    }
  
    render() {
        return (
            <MDBContainer fluid className='PrintCatalogContainer'>
                 <MDBRow>
                    <CommonCatalog 
                     key={"printCatalog"}
                     id={"printCatalog"}
                     showId={this.props?.showId}
                     showName={this.props?.showName}
                     setDetails={this.props?.setDetails}
                     showCheckbox={this.props?.showCheckbox} 
                     catalogFiles={this.props?.catalogFiles}
                     uploadType="images"
                     catalogType="print"
                     getAllSelectedFiles={this.getSelectedCatalogFiles.bind(this)}
                   
                    />
                    
                </MDBRow>
                <div style={{ display: "none" }}>
                        <CommonCatalog 
                         key={"printCatalog"}
                         id={"printCatalog"}
                        showId={this.props?.showId}
                        showName={this.props?.showName}
                        setDetails={this.props?.setDetails}
                        showCheckbox={false} 
                        catalogFiles={this.state?.selectedCatalogFiles}
                        uploadType="images"
                        catalogType="printPreview"                        
                        ref={el => (this.componentRef = el)}
                        />
                    </div>
                <MDBRow className="p-2">
                    <MDBCol md={8} >
                    </MDBCol>
                   
                   
                    <MDBCol md={2}>
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return  <BasicButton
                                        variant="contained"
                                        type="inline"
                                        text={"Print"}
                                        icon="print"
                                        disabled={false}
                            />;
                        }}
                        content={() => this.componentRef}
                    />
                       
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            disabled={false}
                            onClick={this.props.onClose}
                        />
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        )
    }
}

export default PrintCatalog;

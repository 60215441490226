import { MDBRow,MDBCol } from 'mdbreact'
import React, { Component } from 'react'
import  {MDBFileUpload}  from 'mdb-react-file-upload';
import {DropzoneArea} from 'material-ui-dropzone'
import './fileupload.scss'
class FileUpload extends Component {
  constructor(props){
    super(props);
    this.state={
      files:[]
    }
  }
  handleFile=files=>{
   
    console.log(files);
    this.setState({files:files})
    this.props?.handleFile(files);
    return;
    let base64Files =[];
    for(let i=0;i<files.length;i++){ 
      let file = files[i]   
    const reader = new FileReader();
    try {
        // let file = e.target.files[0];       
        reader.onload = () => {     
            if (reader.readyState === 2) {               
              base64Files.push(reader.result);
              console.log(reader.result);
            }
        };
        reader.readAsDataURL(file);

    } catch (error) {
       console.log(error)
    }
  }
  this.setState({files:base64Files})
  }  
  render() {
    return (
      <div>
          <MDBRow center>
              <MDBCol md={12} >
            
               <DropzoneArea
                onChange={(files)=>this.handleFile(files)}
                clearOnUnmount={true}
                {...this.props?.accept ? {acceptedFiles:this.props?.accept}: null} 
                // acceptedFiles={this.props?.accept}
                showAlerts={['error', 'info']}
                filesLimit={18}
                showPreviewsInDropzone={false}
                showPreviews={true}
                maxFileSize={52428800} //50MB max
                showFileNamesInPreview={true}
                previewGridClasses={{container: "previewContainer", item: "previewItem", image: "previewImg"}}
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}               
                dropzoneClass={'file-upload-drag-drop'}
                dropzoneParagraphClass={'drag-drop-text'}
              />
              {/* <input type="file" multiple
              MuiDropzoneArea-root 
              {...this.props?.accept ? {"accept":this.props?.accept}: null} 
              ref={this.props?.innerRef} onChange={this.handleFile} /> */}
              </MDBCol>
              <span className="errorText">{this.state.files?.length > 0 && this.state.files?.some(item => item?.name?.includes("+") || item?.name?.includes("%")) && "Please remove + OR % from file name"}</span>
          </MDBRow>
      </div>
    )
  }
}

// const FileUpload = React.forwardRef((props,ref)=>{
//   const handleFile =(files)=>{
//     console.log(files)
    
//   }
//   return (
//    <MDBRow center>
//              <MDBCol md={11} >
//               <MDBFileUpload 
//               getInputFiles={(files) =>handleFile(files)} 
//               multiple
//               mainError={"something went wrong"}
//               fileUploadRef={ref}      
//               acceptedExtensions={props?.accept}        
//               />
              
//               </MDBCol>
//           </MDBRow>
//  )
// })
export default FileUpload;

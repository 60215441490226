import React from 'react';
import ReactDOM from "react-dom";
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { MDBBtn } from 'mdb-react-ui-kit';
import DeleteIcon from '@material-ui/icons/Delete';
import { cadDrawings } from './config';
import SetManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import './CadDrawing.scss';
import Messages from "../../Common/Messages.json"
import MessageModal from '../SharedComponents/MessageModal';
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel"
import BasicButton from "../SharedComponents/BasicButton/BasicButton"
import Modal from '../SharedComponents/Modal/Modal';
import { format } from 'date-fns';
import FileParams from "../AddFiles/FileParams.json"
import { withUserContext } from '../../contexts/UserContext';
class CadDrawing extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            fileParams: FileParams,
            showAddFiles:false,
            //cadDrawings: [],
            clickedFileName:"",
            showError: false,
            showSuccess: false,
            showFail: false,
            showDelete:false,
            showCustom:false,  
            customMsg:"",
            deleteMessage:"",
            fileData:"",
            fileName:""

        }
        this.fileUploadDrag = React.createRef();
        // this.fileUploadDrag = null;
    }
    // componentDidMount() {
    //     this.getCadFiles()
    // }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.setId !== this.props.setId) {
    //         this.getCadFiles();
    //     }
    // }
    getFiles=(files)=>{
        // console.log(file);
    //     let x = this.fileUploadDrag;
    //     console.log(x);
    //    console.log(this.fileUploadDrag.current.querySelector(".file-upload-input").file);
        this.setState({fileData:files})
    }
    handleFileName(name){
        this.setState({fileName:name})
    }   
    handleShowAddFile(val){
        this.setState({showAddFiles:val})
    }
    uploadFile(){
        // let files = [...this.fileUploadDrag.current.files];
        let files = [...this.state?.fileData]
        if(files.length === 0){
            this.setState({showCustom:true,customMsg : "File is required"})
            return;
        }
        var listFileParams = { ...this.state.fileParams };
        listFileParams.setId = this.props?.setId
        listFileParams.IDSuffix = this.props?.idSuffix
        listFileParams.fileName = this.state.fileName;
        listFileParams.signedURLType = "upload"
        listFileParams.uploadLocation ="cad"
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        listFileParams.files = this.state.fileData?.map(item => item?.name)

        //let formData = new FormData();
        // Object.keys(listFileParams).forEach(key => formData.append(key, listFileParams[key]));
        // files.map(obj=>{
        //     console.log(obj)
        //     formData.append('file',obj)
        // })
          
        this.setState({isLoading:true})
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl +'/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => { 
                if(response.data.error){
                    this.setState({showFail:true,isLoading:false})
                    return;
                } 
                response?.data?.data?.map((item ,index)=> {
                    SetManagerService.uploadFile(item, this.state.fileData[index]).then(response => {
                      if( index == this.state.fileData?.length - 1) {
                        if(response.data.error){
                            this.setState({showFail:true, isLoading:false})
                            return;
                        }
                        this.setState({showSuccess:true,isLoading:false})                   
                        this.props?.getCadFiles(this.props?.setId, this.props?.idSuffix);
                      }
                    }, (err) => {
                        this.setState({showFail:true,isLoading:false})
                        return;
                    });
                  })           
                }, (err) => {
                    console.log("Cad File Upload Failed:" + err);
                    this.setState({showFail:true,isLoading:false})
                });
            // }, (err) => {
            //     console.log("Pre Signed Cad Url Upload Failed:" + err);
            //     this.setState({isLoading:false})
            // });
    }
    
    deleteFile(fileName) {
        var listFileParams = { ...this.state.fileParams };
        listFileParams.setId = this.props?.setId
        listFileParams.IDSuffix = this.props?.idSuffix
        listFileParams.fileName = this.state.clickedFileName;
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        SetManagerService.deleteDataParams(Constants.setManagerServiceBaseUrl + '/deleteFiles',
            listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error) {
                    this.setState({showFail:true})
                    console.log("error from delete files", response.data.error)
                    return
                }                
                this.setState({showSuccess:true})
                this.props?.getCadFiles(this.props?.setId, this.props?.idSuffix);
            }, (err) => {
                this.setState({showFail:true})
                console.log("from delete cad files", err);
            });
    }
    downloadFile(fileName) {
        let url = this.props?.cadDrawings?.filter((obj)=>obj.name === fileName)?.[0].url;        
        if(url){
            fetch(url).then(response=>response.blob()).then(blob=>{
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })           
        }
    }
    onDeleteClick(filename){
        let msg = Messages.deleteMessage.replace("[filename]",filename);
        this.setState({showCustom:true,showDelete:true,deleteMessage:msg,clickedFileName:filename,showError:true})
    }
    messagePopupConfirm(){  
        if(this.state.showSuccess){
            this.handleShowAddFile(false);
        }
        this.setState({ showError: false, showFail: false, showSuccess: false,showCustom:false })
        if(this.state?.showDelete){
            this.deleteFile();
            this.setState({showDelete:false})
        } 
    }
    render() {
        let comfirmModalTitle = this.state.showCustom ? this.state.showDelete ? Messages.deleteTitle : Messages.defaultTitle : Messages.defaultTitle;
        let message =  this.state.showCustom ? this.state.showDelete ? this.state.deleteMessage : this.state.customMsg:Messages.defaultMessage;
        return (
            <>            
             <MDBRow className="mt-2">
                    <MDBCol md={10} className="underlineLabel">
                        <BasicLabel text="CAD / Drawings" />
                    </MDBCol>
                    {/* <MDBCol md={3}></MDBCol> */}
                    <MDBCol md={2}>
                        <BasicButton
                            className="FR MR1"
                            onClick={() => this.handleShowAddFile(true)}
                            text={"Add File"}
                            disabled={this.props?.history}
                            icon={"plus-circle"} />
                    </MDBCol>
            </MDBRow>
            <div className='CadDrawingContainer'>
            <MessageModal
                open={this.state.showCustom || this.state.showError || this.state.showFail || this.state.showSuccess || false}
                title={comfirmModalTitle}
                showError={this.state.showError || false}
                showFail={this.state.showFail || false}
                showSuccess={this.state.showSuccess || false}                
                message={message}
                showCustom={this.state.showCustom || false}
                hideCancel={this.state.showFail || this.state.showSuccess || false}
                primaryButtonText={"Ok"}
                secondaryButtonText={"Cancel"}  
                onConfirm={() => this.messagePopupConfirm()}
                handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false,showCustom:false,customMsg:''})}
                 
                   />
                {this.state.isLoading ? <div className="ContentLoader">
                    <CircularProgress />
                </div> :
                    // this.state.cadDrawings?.length > 0 ?
                    this.props?.cadDrawings?.map((item, index) => {
                        return (
                            <div>
                                <MDBRow >
                                    <MDBCol md={10}>                                        
                                        <span className="cadList action-icon" onClick={this.downloadFile.bind(this, item.name)}>{item.name}</span>                                       
                                    </MDBCol>
                                    <MDBCol className="cadListTrash" md={2}>
                                        {!this.props?.history && <MDBIcon icon="trash-alt"
                                            onClick={() => this.onDeleteClick(item.name)}
                                        />}
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        )
                    })
                    // : <div><span className="cadList"> No files to show</span></div>
                }
            </div>
            {this.state.showAddFiles && <Modal
            open={this.state.showAddFiles || false}
            comfirmModalTitle={'Add Cad File'}
            modalName={'AddCadFiles'}   
            uploadType="cad"
            accept={["application/pdf","text/csv",".vwx", ".csv", ".pdf"]}
            handleFile={this.getFiles}
            handleFileName={this.handleFileName.bind(this)}
            onClose={()=>this.handleShowAddFile(false)} 
            handleSubmit={()=>this.uploadFile()}
            isSubmit={this.state.isLoading || false}
            innerRef={this.fileUploadDrag} 
            files={this.state.fileData}
            />}
            </>
        )
    }
}
export default withUserContext(CadDrawing);

import React, { Component } from 'react'
import defaultImg from "../../assets/defaultImg.png"

class ImageCard extends Component {

  onError = (e) => {       
    // this.props?.handleProfileImg(null)  
    e.target.src= defaultImg;
    e.target.className = 'default-img'
  }  
  render() {
    return (
      <>
          <img
            src={this.props?.src || defaultImg}
            className={this.props?.src ? 'img-thumb':'default-img'}
            alt='image'
            onClick={this.props?.onClick}
            onContextMenu={this.props?.onContextMenu}
            onError={this.onError}
          />
      </>
    )
  }
}
export default ImageCard;


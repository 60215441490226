export const initialUserDetails = {
    "is_active": 1,
    "is_user_provisioner": 0,
    "user_name": null,
    "phone": null,
    "wb_email_id": null,
    "notification_email": null,
    "user_id": null,
    "is_delete": 0,
    "user_roles": [
      {
        "tenant_id": null,
        "role_id": null
      }
    ]
  }

  export const initialRoleDetails = {
    "tenant_id": null,
    "role_id": null
  }

  export const statusOption = [
      { label : "Active" , value : 1},
      { label : "Inactive" , value : 2}
 ]


  export const tenantOption = [
    { label : "WB Television" , value : 1}
  ]

  export const roleOption = [
    { label : "Admin" , value : 1},
    { label : "Regular User" , value : 2},
    { label : "Read-Only" , value : 3}
  ]

import React from 'react';
import InlineSelectField from '../../components/SharedComponents/InlineSelectField/InlineSelectField';
import InlineButtonField from '../../components/SharedComponents/InlineButtonField/InlineButtonField';
import ListField from '../../components/SharedComponents/ListField/ListField';

const selectOptions = [
    {
        label: "Value 1",
        value: 1
    },
    {
        label: "Value 2",
        value: 2
    },
    {
        label: "Value 3",
        value: 3
    }
]

export default class Field3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inlineTextField: "Value",
            inlineSelectValue: "",
            inlineButtonField: "",
            listFieldValue: ""
        }
    }
    render() {
        return (
            <div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineSelectField id={"key16"}
                        fieldValue={this.state.inlineTextField}
                        onFieldChange={(e) => this.setState({ inlineTextField: e.target.value })}
                        selectValue={this.state.inlineSelectValue}
                        options={selectOptions}
                        onSelectChange={(e) => this.setState({ inlineSelectValue: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineSelectField id={"key17"}
                        textFieldLength={3}
                        selectFieldLength={9}
                        textFieldposition="end"
                        label="Field Name"
                        isMandatory={true}
                        fieldValue={this.state.inlineTextField}
                        onFieldChange={(e) => this.setState({ inlineTextField: e.target.value })}
                        placeholder={"Watermark Text"}
                        selectValue={this.state.inlineSelectValue}
                        options={selectOptions}
                        onSelectChange={(e) => this.setState({ inlineSelectValue: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineSelectField id={"key18"}
                        fieldValue={this.state.inlineTextField}
                        onFieldChange={(e) => this.setState({ inlineTextField: e.target.value })}
                        disabled={true}
                        selectValue={this.state.inlineSelectValue}
                        options={selectOptions}
                        onSelectChange={(e) => this.setState({ inlineSelectValue: e.target.value })} />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineButtonField id={"key19"}
                        value={this.state.inlineButtonField}
                        label={"Field Name"}
                        isMandatory={true}
                        buttonOnClick={() => alert("button clicked")}
                        onChange={(e) => this.setState({ inlineButtonField: e.target.value })}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineButtonField id={"key20"}
                        value={this.state.inlineButtonField}
                        placeholder={"Watermark Text"}
                        buttonOnClick={() => alert("button clicked")}
                        onChange={(e) => this.setState({ inlineButtonField: e.target.value })}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <InlineButtonField id={"key21"}
                        value={this.state.inlineButtonField}
                        placeholder={"Watermark Text"}
                        disabled={true}
                        onChange={(e) => this.setState({ inlineButtonField: e.target.value })}
                        buttonOnClick={() => alert("button clicked")}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <ListField id={"key22"}
                        value={this.state.listFieldValue}
                        label={"Field Name"}
                        isMandatory={true}
                        showAdd={true}
                        showRemove={true}
                        addCallback={() => alert("add")}
                        removeCallback={() => alert("remove")}
                        onChange={(e) => this.setState({ listFieldValue: e.target.value })}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <ListField id={"key23"}
                        value={this.state.listFieldValue}
                        showAdd={true}
                        showRemove={false}
                        placeholder={"Watermark Text"}
                        onChange={(e) => this.setState({ listFieldValue: e.target.value })}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <ListField id={"key24"}
                        value={this.state.listFieldValue}
                        showAdd={false}
                        disabled={true}
                        showRemove={true}
                        onChange={(e) => this.setState({ listFieldValue: e.target.value })}
                         />
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <ListField id={"key25"}
                        value={this.state.listFieldValue}
                        showAdd={true}
                        disabled={true}
                        showRemove={true}
                        addCallback={() => alert("add")}
                        removeCallback={() => alert("remove")}
                        onChange={(e) => this.setState({ listFieldValue: e.target.value })}
                         />
                </div>
            </div>
        )
    }
}

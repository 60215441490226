import React from 'react';
import BasicTextField from '../BasicTextField/BasicTextField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './MiniField.scss';

export default class MiniField extends React.Component {
    render() {
        return (
            <div className="MiniFieldContainer">
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                <BasicTextField id={this.props?.id}
                    value={this.props?.value || ""}
                    limitWarning={this.props.limitWarning}
                    limit={this.props.limit}
                    onChange={this.props?.onChange}
                    inputProps={{ maxLength: this.props?.maxLength || 1 }}
                    disabled={this.props?.disabled || false} />
            </div>
        );
    }
}

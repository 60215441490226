import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdbreact';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import SetManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import FileParams from "../AddFiles/FileParams.json"
import ImageCard from '../../Common/ImageComponent/ImageCard';
import { withUserContext } from '../../contexts/UserContext';
import '../PrintCatalog/PrintCatalog.scss'
import './SortCatalog.scss'
// import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
import MDBSortable from 'mdb-react-sortable';
class SortCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortItemList: [],
            fileParams: FileParams,
            isLoading: false
        }
    }

    componentDidMount = () => {
        this.setState({ sortItemList: this.props.catalogFiles?.map(item => ({ name: item.name, order: item.order, url: item.url })) })
    }
    sortFile() {
        var listFileParams = { ...this.state.fileParams };
        listFileParams.setId = this.props?.setId;
        listFileParams.IDSuffix = this.props?.setDetails?.id_suffix
        listFileParams.tenantId = this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName = this.props?.userContext?.active_tenant?.tenant_name
        listFileParams.fileName = "";
        listFileParams.signedURLType = "upload"
        listFileParams.uploadLocation = "images"
        listFileParams.isSort = 1,
            listFileParams.files = this.state.sortItemList?.map((item, index) => {
                return { 'name': item?.name, 'order': index }
            })

        this.setState({ isLoading: true })
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isLoading: false }, () => {
                        this.props.handleMessageModalStatus('fail', true);
                    })
                } else {
                    this.props.handleMessageModalStatus('success', true);
                    this.props.callback(this.props?.setId, this.props.setDetails.id_suffix)
                }
            }, (err) => {
                console.log("catalog File Upload Failed:" + err);
                this.setState({ isLoading: false }, () => {
                    this.props.handleMessageModalStatus('fail', true);
                })
            });
    }

    onSortEnd(obj, e) {
        let imgArr = [...this.state.sortItemList]
        let actionItem = imgArr.splice(obj.oldIndex, 1)
        let newArr = new Array(this.state.sortItemList.length)
        for (let i = 0; i < this.state.sortItemList.length; i++) {
            if (i < obj.newIndex)
                newArr[i] = imgArr[i];
            else if (i == obj.newIndex)
                newArr[i] = actionItem[0];
            else
                newArr[i] = imgArr[i - 1];
        }
        this.setState({ sortItemList: newArr })
    }
    render() {
        return (
            <div fluid className='SortCatalogContainer' id="SortCatalogContainer">
                <MDBSortable id="sortable-grid"
                    helperContainer={() => document.getElementById('SortCatalogContainer')}
                    data-mdb-sortable="sortable"
                    className='d-flex flex-wrap'
                    helperClass='sortableHelper'
                    axis="xy"
                    itemClassName="SortableItem"
                    listClassName="SortableList"
                    onSortEnd={(obj, e) => this.onSortEnd(obj, e)}
                    items=
                    {this.state?.sortItemList?.map((item, index) =>

                        <div id={index + "_" + item.name} key={index + "_" + item.name}>
                            <ImageCard src={item.url} />
                        </div>

                    )}
                />

                <MDBRow className="p-2">
                    <MDBCol md={8} >
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            variant="contained"
                            type="inline"
                            text={this.state?.isLoading ? <CircularProgress color="inherit" size={15} /> : "Save"}
                            onClick={this.sortFile.bind(this)}
                            disabled={this.state.sortItemList === 0}
                        />

                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            disabled={false}
                            onClick={this.props.onModalClose}
                        />
                    </MDBCol>
                </MDBRow>

            </div>
        )
    }
}

export default withUserContext(SortCatalog)
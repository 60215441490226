import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import SearchFieldWithAddValueContainer from '../../components/SharedComponents/SearchFieldWithAddValue';
import DateField from '../../components/SharedComponents/DateField/DateField';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import { newSet, setFieldSizeLimits, filePostJson } from './Config';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import * as Constants from '../../constants/constants';
import SetManagerService from '../../services/service';
import MessageModal from '../../components/SharedComponents/MessageModal';
import { withUserContext } from '../../contexts/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUpload from '../../components/AddFiles/FileUpload';
import sizeLimits from '../../Common/SizeLimits.json'
import { getFormattedDate, validateCharctersOnly, validateCharNumOnly, validateNumDotOnly } from '../../Common/Helper';

import './NewSet.scss';

class NewSet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setPostJson: JSON.parse(JSON.stringify(newSet)),
            isPosting: false,
            isLoading: false,
            selectedList: {
                selectedShowId: null,
                selectedCategoryId: null,
                selectedConditionId: null,
                selectedLocationId: null,
                //selectedLastShowId: null,
            },
            idPrefix: this.props?.userContext?.dropDownList?.idPreffixes?.map((item) => ({ value: item?.value, text: item?.text, idPrefix : item?.value })),
            lastShowOptions: this.props?.userContext?.dropDownList?.show?.map((item) => ({ value: item?.value, text: item?.text, show : item?.value, is_active : item?.is_active })),
            selectedPrefix : null,
            selectedLastShow : null,
            cadList: [],
            catalogPhotos: [],
            filePostJson: JSON.parse(JSON.stringify(filePostJson)),
            postInitiated: false,
            showStrikeSet:false,
            timeStamp: new Date().getTime(),
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props?.userContext?.dropDownList?.idPreffixes !== prevProps?.userContext?.dropDownList?.idPreffixes) {
            this.setState({idPrefix: this.props?.userContext?.dropDownList?.idPreffixes?.map((item) => ({ value: item?.value, text: item?.text, idPrefix : item?.value }))})
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props?.setDetails?.set_id !== state?.setPostJson?.set_id && props?.mode == 'Edit Set') {
            return {
                setPostJson: props.setDetails ,
                selectedPrefix : props?.setDetails?.id_prefix,
                selectedLastShow : props.setDetails.last_show_used_id,
                selectedList:  {
                    selectedShowId: props?.userContext?.dropDownList.show.find(item => item.value == props.setDetails.show_id),
                    selectedCategoryId: props?.userContext?.dropDownList.category.find(item => item.value == props.setDetails.category_id),
                    selectedConditionId: props?.userContext?.dropDownList.condition.find(item => item.value == props.setDetails.condition_id),
                    selectedLocationId: props?.userContext?.dropDownList.location.find(item => item.value == props.setDetails.location_id),
                    //selectedLastShowId: props?.userContext?.dropDownList.show.find(item => item.value == props.setDetails.last_show_used_id),

                }
            }
        }
        return null
    }

    handleOnChange = (field, value) => {
        this.setState(prevState => ({
            setPostJson: {
                ...prevState.setPostJson,
                [field]: value
            },
            postInitiated: false
        }));
        this.props.handleMessageModalStatus('unsaved', true);
    }

    handleSelectionOnChange = (field, value) => {
        this.setState(prevState => ({
            selectedList: {
                ...prevState.selectedList,
                [field]: value?.is_active == 0 ?  null : value
            },
            postInitiated: false
        }))
        this.props.handleMessageModalStatus('unsaved', true);
    }

    handleSubmit = () => {
        this.setState(prevState => ({
            postInitiated: true,
            showStrikeSet : false,
            setPostJson: {
                ...prevState.setPostJson,
                default_image: this.props?.mode === 'Edit Set' ? prevState.setPostJson?.default_image : ( this.state.catalogPhotos?.length > 0 ? `${this.state.timeStamp}_${this.state.catalogPhotos?.[0]?.name}` : null),
                show_id: parseInt(this.state.selectedList.selectedShowId?.value) || null,
                location_id: parseInt(this.state.selectedList.selectedLocationId?.value) || null,
                category_id: parseInt(this.state.selectedList.selectedCategoryId?.value) || null,
                condition_id: parseInt(this.state.selectedList.selectedConditionId?.value) || null,
                last_show_used: null,
            }
        }), () => {
            if (this.validateSetFields()) {
                this.setState({ isPosting: true })
                if(this.state.setPostJson?.last_show_used_id && isNaN(this.state.setPostJson?.last_show_used_id)) {
                    this.addNewShow()
                } else {
                    this.handlePost();
                }
            }
        })
    }

    addNewShow = () => {
        let showList = this.props?.userContext?.dropDownList.show?.map(item => ({name : item?.text, id : item?.value , is_active : item?.is_active}))
        showList.push({"name":this.state.setPostJson.last_show_used_id,"id":null,"is_active":1})
        let lookupJson = {"table_name":"lu_show","values":showList}
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/staticData', lookupJson, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                        if(response.data.error) {
                            this.props.handleMessageModalStatus('fail', true);
                        } else {
                            this.props.userContext?.getSetStaticList(this.props?.userContext?.active_tenant?.tenant_id);
                            this.getNewShowId()
                        }       
            },
                (err) => {
                    console.log("Error in fetching Work Details:", err)
                    this.props.handleMessageModalStatus('fail', true);
                })
    }

    getNewShowId = () => {
        SetManagerService.getData(Constants.setManagerServiceBaseUrl + `/staticData?staticTable=lu_show`,  this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let lastShow = response?.data?.find(item => item?.name === this.state.setPostJson.last_show_used_id)
                this.setState(prevState => ({
                    setPostJson: {
                        ...prevState.setPostJson,
                        ['last_show_used_id']: lastShow?.id,
                        ['last_show_used'] : null
                    }
                  }), this.handlePost)
            },
                (err) => {
                    console.log("Error in fetching set List:", err)
                })
      } 

    handlePost = () => {
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/setData', this.state.setPostJson, this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({ isPosting: false, postInitiated: false });
                if (response.data.error) {
                    this.props.handleMessageModalStatus('fail', true);
                } else {
                    this.props.handleMessageModalStatus('success', true);
                    if (this.state.cadList?.length !== 0) this.uploadCadFiles(response.data[0].set_id, this.state.setPostJson?.id_suffix);
                    if (this.state.catalogPhotos?.length !== 0) this.uploadPhotos(response.data[0].set_id, this.state.setPostJson?.id_suffix);
                    if (this.props.mode !== 'New Set') {
                        this.props.callback(response.data[0].set_id, true);
                    } else {
                        this.props.callback({'text' : this.state.setPostJson?.set_name , 'value' : response?.data[0]?.set_id});
                    }
                }
            },
                (err) => {
                    this.props.handleMessageModalStatus('fail', true);
                    this.setState({ isPosting: false, postInitiated: false });
                    console.log("Post set details error: " + err);
                });
    }

    validateSetFields = () => {
        let canSubmit = this.state.setPostJson?.set_name && this.state.setPostJson?.id_prefix && this.state.setPostJson?.id_suffix &&
            this.validateIdSuffix() &&
            this.state.setPostJson?.show_id && this.state.setPostJson?.location_id && this.state?.setPostJson?.category_id &&
            this.state?.setPostJson?.condition_id &&
            (this.state.setPostJson?.build_cost?.length ? this.state.setPostJson?.build_cost?.length <= 10 : true) &&
            (this.state.setPostJson?.revamp_cost?.length ? this.state.setPostJson?.revamp_cost?.length <= 10 : true) &&
            (this.state.setPostJson?.keywords ? this.state.setPostJson?.keywords.length <= sizeLimits?.notesCharacterLimit : true) &&
            (this.state.setPostJson?.comments ? this.state.setPostJson?.comments.length <= sizeLimits?.notesCharacterLimit : true)
        return canSubmit;
    }

    uploadPhotos(set_id, idSuffix) {
        let files = [...this.state.catalogPhotos]
        if (files.length === 0) {
            return;
        }
        var listFileParams = { ...this.state.filePostJson };
        listFileParams.setId = parseInt(set_id) || null
        listFileParams.IDSuffix =  idSuffix || null
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        listFileParams.signedURLType = "upload"
        listFileParams.uploadLocation = "images"
        listFileParams.files = this.state.catalogPhotos?.map((item,index) => ({name: `${this.state.timeStamp}_${item.name}` , order : index}))
        // let formData = new FormData();
        // Object.keys(listFileParams).forEach(key => formData.append(key, listFileParams[key]));
        // files.map(obj => {
        //     formData.append('file', obj)
        // })
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if(response.data.error){
                    console.log('failed catalog files')
                    return
                  } 
                  response?.data?.data?.map((item ,index)=> {
                    SetManagerService.uploadFile(item, this.state?.catalogPhotos[index]).then(response => {
                      console.log('files uploaded')
                    }, (err) => {
                     console.log('failed catalog files')
                      return;
                    });
                  })
            }, (err) => {
                console.log("catalog File Upload Failed:" + err);
            });
    }

    uploadCadFiles(set_id, idSuffix) {
        let files = [...this.state?.cadList]
        if (files.length === 0) {
            return;
        }
        var listFileParams = { ...this.state.filePostJson };
        listFileParams.setId = parseInt(set_id) || null
        listFileParams.IDSuffix = idSuffix || null
        listFileParams.signedURLType = "upload"
        listFileParams.uploadLocation = "cad"
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        listFileParams.files = this.state?.cadList?.map(item => item?.name)
        let formData = new FormData();
        // Object.keys(listFileParams).forEach(key => formData.append(key, listFileParams[key]));
        // files.map(obj => {
        //     formData.append('file', obj)
        // })
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if(response.data.error){
                    console.log('failed cad files')
                    return
                  } 
                  response?.data?.data?.map((item ,index)=> {
                    SetManagerService.uploadFile(item, this.state?.cadList[index]).then(response => {
                      console.log('files uploaded')
                    }, (err) => {
                     console.log('failed cad files')
                      return;
                    });
                  })
            }, (err) => {
                console.log("Cad File Upload Failed:" + err);
            });
    }

    validateIdSuffix = () => {
        let validId = this.props?.mode == 'Edit Set' ? true :
            !this.props?.userContext?.dropDownList?.idSuffixes?.map(item => item?.value)?.includes(this.state.setPostJson?.id_suffix)
        return validId
    }

    handleCreateEditSet = () => {
        if(this.props?.mode == 'Edit Set' && !this.props?.setDetails?.strike_date && this.state?.setPostJson?.strike_date) {
            this.setState({showStrikeSet : true})
        } else {
           this.handleSubmit() 
        }
    }

    onChangeIDPrefix = (item) => {
        if(item === null) {
            this.handleOnChange('id_prefix', null)
            this.setState({selectedPrefix : null})
        } else {
            if(!item.hasOwnProperty('idPrefix')) {
                let id_prefix = [...this.state.idPrefix]
                id_prefix.push({'value': item.value, 'text': item.value, 'idPrefix': item.value})
                this.handleOnChange('id_prefix', item.value)
                this.setState({selectedPrefix : item.value, idPrefix : id_prefix})
            } else {
                this.handleOnChange('id_prefix', item.value)
                this.setState({selectedPrefix : item.value})
            }
        }
    }

    onChangeLastShow= (item) => {
        if(item === null) {
            this.handleOnChange('last_show_used_id', null)
            this.setState({selectedLastShow : null})
        } else {
            if(!item.hasOwnProperty('show')) {
                let last_show = [...this.state.lastShowOptions]
                last_show.push({'value': item.value, 'text': item.value, 'show': item.value, 'is_active':1})
                this.handleOnChange('last_show_used_id', item.value)
                this.setState({selectedLastShow : item.value, lastShowOptions : last_show})
            } else {
                let checkActive = this.state.lastShowOptions.find(ele => ele?.value === item?.value)?.is_active
                this.handleOnChange('last_show_used_id', checkActive === 1 ? item.value : null)
                this.setState({selectedLastShow : checkActive === 1 ? item.value : null})
            }
        }
    }

    render() {
        return (
            <div className='NewSetContainer'>
                {
                    this.state.isLoading ? <div className="New-Set-Loader"> <CircularProgress color="inherit" size={24} /> </div> :
                        <MDBCol>
                            <MessageModal
                                open={this.state.showStrikeSet || false}
                                title={"Strike Set"}
                                showCustom={true}
                                message={`Are you sure you would like to strike this set with a strike date of ${getFormattedDate(this.state.setPostJson?.strike_date)}`}
                                primaryButtonText={"Confirm"}
                                secondaryButtonText={"Cancel"}
                                //hideCancel={this.state.showDeleteSuccess || this.state.showDeleteFail || false}
                                onConfirm={this.handleSubmit}
                                handleClose={(e) => this.setState({ showStrikeSet: false })}
                            />
                            <MDBRow className="ModalBody p-2">
                                <span className="detailFont">Details</span>
                                <MDBCol md={12} className="Content">
                                    <MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicTextField
                                                    label="Set Name"
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated || false}
                                                    value={this.state.setPostJson?.set_name || ""}
                                                    onChange={(e) => validateCharctersOnly(e.target.value) && this.handleOnChange('set_name', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={3}>
                                            <BasicLabel text={"ID Prefix"} isMandatory={true}/>
                                                <SearchFieldWithAddValueContainer 
                                                    id={"id_prefix"}
                                                    value={this.state.selectedPrefix}
                                                    options={this.state?.idPrefix}
                                                    type={'idPrefix'}
                                                    validateMethod={validateCharNumOnly.bind(this)}
                                                    onChange={(e, value) => this.onChangeIDPrefix(value)}
                                                />
                                                <span className="errorText">{this.state.postInitiated && this.state.selectedPrefix == null ? "Please complete this mandatory field" : null}</span>
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicTextField
                                                    label="ID Suffix"
                                                    isMandatory={true}
                                                    disabled={this.props?.mode == 'Edit Set'}
                                                    fieldValid={!(this.state.setPostJson?.id_suffix ? this.validateIdSuffix() : true)}
                                                    inValidInput={"ID already exists"}
                                                    showMandatory={this.state.postInitiated || false}
                                                    value={this.state.setPostJson?.id_suffix || ""}
                                                    onChange={(e) => validateCharNumOnly(e.target.value) && this.handleOnChange('id_suffix', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Show"} isMandatory={true} />
                                                <SearchSelectField
                                                    noOptionsText={"Must add new item in Lookups"}
                                                    width={"100%"}
                                                    placeholder={"-Search-"}
                                                    value={this.state.selectedList?.selectedShowId || {}}
                                                    options={this.props?.userContext?.dropDownList?.show || []}
                                                    onChange={(e, value) => this.handleSelectionOnChange('selectedShowId', value)}
                                                />
                                                <span className="errorText">{this.state.postInitiated && !this.state.selectedList?.selectedShowId ? "Please complete this mandatory field" : null}</span>
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Location"} isMandatory={true} />
                                                <SearchSelectField
                                                    noOptionsText={"Must add new item in Lookups"}
                                                    width={"100%"}
                                                    placeholder={"-Search-"}
                                                    value={this.state.selectedList?.selectedLocationId || {}}
                                                    options={this.props?.userContext?.dropDownList?.location || []}
                                                    onChange={(e, value) => this.handleSelectionOnChange('selectedLocationId', value)}
                                                />
                                                <span className="errorText">{this.state.postInitiated && !this.state.selectedList?.selectedLocationId ? "Please complete this mandatory field" : null}</span>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Category"} isMandatory={true} />
                                                <SearchSelectField
                                                    noOptionsText={"Must add new item in Lookups"}
                                                    width={"100%"}
                                                    placeholder={"-Search-"}
                                                    value={this.state.selectedList?.selectedCategoryId || {}}
                                                    options={this.props?.userContext?.dropDownList?.category || []}
                                                    onChange={(e, value) => this.handleSelectionOnChange('selectedCategoryId', value)}
                                                />
                                                <span className="errorText">{this.state.postInitiated && !this.state.selectedList?.selectedCategoryId ? "Please complete this mandatory field" : null}</span>
                                            </MDBCol>
                                            <MDBCol md={2} className="check">
                                                <BasicCheckbox
                                                    id={"open-checkbox"}
                                                    label="Open"
                                                    checked={(this.state.setPostJson?.is_open === 1 ? true : false) || false}
                                                    onChange={(e) => this.handleOnChange('is_open', (e.target.checked ? 1 : 0))}
                                                />
                                            </MDBCol>
                                            <MDBCol md={4}>
                                                <BasicLabel text={"Condition"} isMandatory={true} />
                                                <SearchSelectField
                                                    noOptionsText={"Must add new item in Lookups"}
                                                    width={"100%"}
                                                    placeholder={"-Search-"}
                                                    value={this.state.selectedList?.selectedConditionId || {}}
                                                    options={this.props?.userContext?.dropDownList?.condition || []}
                                                    onChange={(e, value) => this.handleSelectionOnChange('selectedConditionId', value)}
                                                />
                                                <span className="errorText">{this.state.postInitiated && !this.state.selectedList?.selectedConditionId ? "Please complete this mandatory field" : null}</span>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={3}>
                                                <BasicTextField
                                                    label="Cost to Build($)"
                                                    limit={setFieldSizeLimits.buildCost}
                                                    limitWarning={`Exceeded ${setFieldSizeLimits.buildCost} Numbers limit. Please re enter`}
                                                    value={this.state.setPostJson?.build_cost || ""}
                                                    onChange={(e) => validateNumDotOnly(e.target.value) && this.handleOnChange('build_cost', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicTextField
                                                    label="Revamp Cost($)"
                                                    limit={setFieldSizeLimits.revampCost}
                                                    limitWarning={`Exceeded ${setFieldSizeLimits.revampCost} Numbers limit. Please re enter`}
                                                    value={this.state.setPostJson?.revamp_cost || ""}
                                                    onChange={(e) => validateNumDotOnly(e.target.value) && this.handleOnChange('revamp_cost', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Last Show Used Set"}/>
                                                <SearchFieldWithAddValueContainer 
                                                    id={"last_show_used_set"}
                                                    value={this.state.selectedLastShow}
                                                    options={this.state?.lastShowOptions}
                                                    type={'lastShowUsedSet'}
                                                    validateMethod={(text) => {return true}}
                                                    onChange={(e, value) => this.onChangeLastShow(value)}
                                                />
                                                {/* <BasicLabel text={"Last Show Used Set"} /> */}
                                                {/* <SearchSelectField
                                                    width={"100%"}
                                                    placeholder={"-Search-"}
                                                    value={this.state.selectedList?.selectedLastShowId || {}}
                                                    options={this.props?.userContext?.dropDownList?.show || []}
                                                    onChange={(e, value) => this.handleSelectionOnChange('selectedLastShowId', value)}
                                                /> */}
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={3}>
                                                <DateField
                                                    label={"First Build Date"}
                                                    className="date"
                                                    value={this.state.setPostJson?.first_build_date || ""}
                                                    onChange={(e) => this.handleOnChange('first_build_date', e.target.value ? 
                                                    e.target.value : null)}
                                                />

                                            </MDBCol>
                                            <MDBCol md={3}>
                                                {
                                                    this.props.mode !== 'New Set' ?
                                                        this.props?.setDetails?.strike_date ?
                                                            <div>
                                                                <BasicLabel text={"Strike Date"} />
                                                                <span className="detailFont">{getFormattedDate(this.state.setPostJson?.strike_date)}</span>
                                                            </div>
                                                            :
                                                            <DateField
                                                                label={"Strike Date"}
                                                                className="date"
                                                                value={this.state.setPostJson?.strike_date || ""}
                                                                onChange={(e) => this.handleOnChange('strike_date', e.target.value ? e.target.value : null)}
                                                            /> : null
                                                }
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <DateField
                                                    label={"Date of Last Use"}
                                                    className="date"
                                                    value={this.state.setPostJson?.last_used_date || ""}
                                                    onChange={(e) => this.handleOnChange('last_used_date', e.target.value ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <DateField
                                                    label={"Date Photo Taken"}
                                                    className="date"
                                                    value={this.state.setPostJson?.photos_taken_date || ""}
                                                    onChange={(e) => this.handleOnChange('photos_taken_date', e.target.value ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicTextArea
                                                    label={"Keywords"}
                                                    id={"textarea-performer"}
                                                    value={this.state.setPostJson?.keywords || ""}
                                                    limit={sizeLimits?.notesCharacterLimit}
                                                    limitWarning={`Exceeded ${sizeLimits.notesCharacterLimit} characters limit. Please re enter`}
                                                    rows={4}
                                                    onChange={(e) => this.handleOnChange('keywords', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <BasicTextArea
                                                    label={"Comments"}
                                                    id={"textarea-performer"}
                                                    rows={4}
                                                    limit={sizeLimits?.notesCharacterLimit}
                                                    limitWarning={`Exceeded ${sizeLimits.notesCharacterLimit} characters limit. Please re enter`}
                                                    value={this.state.setPostJson?.comments || ""}
                                                    onChange={(e) => this.handleOnChange('comments', e.target.value.length > 0 ? e.target.value : null)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        {
                                            this.props.mode === 'New Set' &&
                                            <MDBRow>
                                                <MDBCol md={12}>
                                                    <BasicLabel text={"Catalog Photos"} />
                                                    <FileUpload accept={["image/*"]} handleFile={(files) => this.setState({ catalogPhotos: files })} />
                                                </MDBCol>
                                                <MDBCol md={12}>
                                                    <BasicLabel text={"CAD/Drawings"} />
                                                    <FileUpload accept={["application/pdf","text/csv",".vwx", ".csv", ".pdf"]} handleFile={(files) => this.setState({ cadList: files })} />
                                                </MDBCol>
                                            </MDBRow>
                                        }
                                        <MDBRow className="p-3">
                                            <MDBCol md={6}>
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicButton
                                                    type="inline"
                                                    variant="contained"
                                                    disabled={!this.state.setPostJson.set_name || 
                                                             (this.state.catalogPhotos?.length > 0 && this.state.catalogPhotos?.some(item => item?.name?.includes("+") || item?.name?.includes("%"))) ||
                                                             (this.state.cadList?.length > 0 && this.state.cadList?.some(item => item?.name?.includes("+") || item?.name?.includes("%")))}
                                                    onClick={() => this.handleCreateEditSet()}
                                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={12} /> : this.props.mode === 'New Set' ? 'Create' : 'Save'}
                                                />
                                            </MDBCol>
                                            <MDBCol md={3}>
                                                <BasicButton
                                                    className="btnwidth"
                                                    variant="outlined"
                                                    type="inline"
                                                    text={'Cancel'}
                                                    onClick={() => this.props.onModalClose()}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                }
            </div>

        )
    }
}
export default withUserContext(NewSet);

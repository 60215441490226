import * as React from 'react';

export const UserContext = React.createContext({
 });

export const withUserContext = Component => {
    class UserContextComponent extends React.Component {
      render() {
        return (
          <UserContext.Consumer>
            {(value) => <Component userContext = {value} {...this.state} {...this.props}/>}
          </UserContext.Consumer>
        );
      }
    }
  
    return UserContextComponent;
  };

  //export default withUserContext;
import * as Constants  from '../constants/constants';
import { AuthService } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { createBrowserHistory } from 'history'
//import { __esModule } from '@okta/okta-react/dist/Security';
 
const history = createBrowserHistory();
//const authService = new AuthService({
const  oktaAuth = new OktaAuth({
  history,
  issuer: Constants.OKTA_ISSUER_URL,
  clientId: Constants.OKTA_CLIENT_ID,
  redirectUri: Constants.OKTA_REDIRECT_URL,
  url: Constants.OKTA_URL,
  scopes: ['email', 'profile', 'openid', 'offline_access']
});



export const restoreOriginalUri = async (_oktaAuth, originalUri) => {  
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

export default oktaAuth;
//export default restoreOriginalUri;
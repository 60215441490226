import React, { Component } from 'react'
import {  MDBCol, MDBRow } from 'mdbreact';
import { getFormattedDate } from '../../Common/Helper';

export default class catalogHeader extends Component {
    constructor(props){
        super(props);
    }
  render() {
      let {setDetails} = this.props;
    return (
        <MDBRow className='justify-content-between mt-3'>
        <MDBCol md={4} className="text-start">
            <span>Comments</span>
            <div className='comments'>{setDetails?.comments}</div>
        </MDBCol>
        <MDBCol md={4} className="text-center">
            <div><b>{this.props?.showName}</b></div>
            <div><b>{setDetails?.set_name}</b></div>
            <div><b>{setDetails?.id_prefix}-{setDetails?.id_suffix}</b></div>
        </MDBCol>
        <MDBCol md={4} className="text-end">
            {
                <>
                    <span>{setDetails?.last_show_used || "-"}</span>&nbsp;
                    <span>{setDetails?.last_used_date ? getFormattedDate(setDetails?.last_used_date): "-"}</span>
                </>
            }
        </MDBCol>
    </MDBRow>
    )
  }
}

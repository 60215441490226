import React from 'react';
import DateField from '../SharedComponents/DateField/DateField';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import SetManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import './RevampSet.scss'

class RevampSet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting:false,
            revampDate:null,
            filePostJson: {
                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                "tenantId": this.props.userContext?.active_tenant?.tenant_id,
                "showName": "",
                "IDSuffix" : null,
                "showId": null,
                "uploadLocation": "cad",
                "fileName": "",
                "signedURLType": "",
                "oldFile":""   
            }
        }
    }

    postRevampSet = () => {
            let postJson = {
                "revamp_history_id": null,
                "set_id":this.props?.setId,
                "id_suffix":this.props?.idSuffix,
                "revamp_date": this.state?.revampDate,
                "uploadLocation": "",
                "tenantName": this.props?.userContext?.active_tenant?.tenant_name,
                "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            }
            this.setState({isPosting : true})
            SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl+'/setRevampHistory', postJson,this.props?.userContext?.active_tenant?.tenant_id)
                    .then((response) => {
                        if(response.data.error) {
                            console.log(response.data.message)
                            this.props.handleMessageModalStatus('fail', true);
                        } else {
                            this.deleteFile()
                        }
                    },
                        (err) => {
                            this.props.handleMessageModalStatus('fail', true);
                            this.setState({ isPosting: false });
                        });
        // }
    }

    deleteFile() {
        var listFileParams = { ...this.state.filePostJson };
        listFileParams.setId = this.props?.setId
        listFileParams.IDSuffix = this.props?.idSuffix
        listFileParams.fileName = "";
        listFileParams.isDeleteAll = 1
        listFileParams.uploadLocation = ""
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        SetManagerService.deleteDataParams(Constants.setManagerServiceBaseUrl + '/deleteFiles',
            listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if(response.data.error) {
                    this.props.handleMessageModalStatus('fail', true);
                } else {
                    this.props.handleMessageModalStatus('success', true);
                    this.props.callback(this.props?.setId);
                }
                this.setState({isPosting : false})                
            }, (err) => {
                this.props.handleMessageModalStatus('fail', true);
                this.setState({isPosting : false})      
            });
    }



    render() {
        return (
            <MDBContainer className='RevampContainer'>
                {/* className='alertText' */}
                <h6>Are you sure you would like to revamp this set?</h6>
                <MDBRow>
                    <MDBCol md={5}>
                        <DateField
                            label={"Revamp Date"}
                            id="revampdate"
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            value={this.state?.revampDate|| ""}
                            onChange={(e) => {
                                this.setState({revampDate: e.target.value.length > 0 ? e.target.value : null})
                                this.props.handleMessageModalStatus('unsaved', true);
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="p-2">
                    <MDBCol md={6} >
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            variant="contained"
                            type="inline"
                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> :"Confirm"}
                            disabled={this.state?.revampDate == null}
                            onClick={this.postRevampSet.bind(this)}
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={this.props.onModalClose}
                            disabled={false}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(RevampSet);


export const searchResultsConfig = {
    headings: [
        {
            headingLabel: "Set Name",
            icon: "sort-alpha-down"
        },
        {
            headingLabel: "Show",
            icon: ""
        },
        {
            headingLabel: "ID Prefix",
            icon: ""
        },
        {
            headingLabel: "ID Suffix",
            icon: ""
        },
        {
            headingLabel: "Category",
            icon: ""
        },
        {
            headingLabel: "Condition",
            icon: ""
        },
        {
            headingLabel: "Location",
            icon: ""
        }
    ],
    dataNodes: ["set_name", "show_name", "id_prefix", "id_suffix", "category_name", "condition_name", "location_name"],
    primaryKey: "set_id",
    hyperlinks: ["set_name"],
    isSearchBar: true
}

export const SearchData =[
    {
        "id":1,
        "set-name": "set1",
        "show": "show1",
        "id-prefix" : "idp1",
        "id-suffix" : "ids1",
        "category" : "cat1",
        "condition" : "cond1",
        "location" : "loc1"
    },
    {
        "id":3,
        "set-name": "set2",
        "show": "show2",
        "id-prefix" : "idp2",
        "id-suffix" : "ids2",
        "category" : "cat2",
        "condition" : "cond2",
        "location" : "loc2"
    },
    {
        "id":3,
        "set-name": "set3",
        "show": "show3",
        "id-prefix" : "idp3",
        "id-suffix" : "ids3",
        "category" : "cat3",
        "condition" : "cond3",
        "location" : "loc3"
    },
    {
        "id":4,
        "set-name": "set4",
        "show": "show4",
        "id-prefix" : "idp4",
        "id-suffix" : "ids4",
        "category" : "cat4",
        "condition" : "cond4",
        "location" : "loc4"
    },
    {
        "id":5,
        "set-name": "set5",
        "show": "show5",
        "id-prefix" : "idp5",
        "id-suffix" : "ids5",
        "category" : "cat5",
        "condition" : "cond5",
        "location" : "loc5"
    },
    {
        "id":6,
        "set-name": "set6",
        "show": "show6",
        "id-prefix" : "idp6",
        "id-suffix" : "ids6",
        "category" : "cat6",
        "condition" : "cond6",
        "location" : "loc6"
    },
    {
        "id":7,
        "set-name": "set7",
        "show": "show7",
        "id-prefix" : "idp7",
        "id-suffix" : "ids7",
        "category" : "cat7",
        "condition" : "cond7",
        "location" : "loc7"
    },
    {
        "id":8,
        "set-name": "set8",
        "show": "show8",
        "id-prefix" : "idp8",
        "id-suffix" : "ids8",
        "category" : "cat8",
        "condition" : "cond8",
        "location" : "loc8"
    },
    {
        "id":9,
        "set-name": "set9",
        "show": "show9",
        "id-prefix" : "idp9",
        "id-suffix" : "ids9",
        "category" : "cat9",
        "condition" : "cond9",
        "location" : "loc9"
    },
    {
        "id":10,
        "set-name": "set10",
        "show": "show10",
        "id-prefix" : "idp10",
        "id-suffix" : "ids10",
        "category" : "cat10",
        "condition" : "cond10",
        "location" : "loc10"
    },
]


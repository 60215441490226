export const searchOpenList = [
    {
        "label": "Select",
        "value": null
    },
    {
        "label": "Open",
        "value": 1
    },
    {
        "label": "Not Open",
        "value": 2
    }
]

export const searchStruckList = [
    {
        "label": "Select",
        "value": null
    },
    {
        "label": "Struck",
        "value": 1
    },
    {
        "label": "Not Struck",
        "value": 2
    }
]
import React from 'react';
import { MDBIcon } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import './shared.scss';
import './SearchFieldWithAddValue.scss'

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

const SearchFieldWithAddValue = (props) => {
    return (
        <div className="SearchFieldWithAddValueContainer">
            <Autocomplete
                id={props?.id}
                disabled={props?.disabled ? props?.disabled : false}
                options={props?.options ? props?.options : []}
                value={props?.value ? props?.options.find(item => item.value === props?.value) !== undefined ? props?.options.find(item => item.value === props?.value).text: null : null}
                //value={props?.value !== null && props?.value.length > 0 ? props?.options.find(item => item.value === props?.value).text : null}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option ? option : "";
                    } else
                        if (option?.inputValue) {
                           return option.inputValue;
                        } else {
                            return option.text ? option.text : "";
                        }
                }}
                renderOption={(props, option) => (
                    <>
                        <MenuItem key={props?.value} disabled={props?.is_active === 0 ? true : false} value={props?.value}>
                                {props?.text} 
                        </MenuItem>
                    </>
                )}
                getOptionSelected={(option, value) => option.value === value.value}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if(props.validateMethod(params?.inputValue)){
                        let checkOption = options.find(item => item[props?.type] === params?.inputValue)
                        if (params?.inputValue !== '' && checkOption === undefined) {
                            filtered.push({
                                value: params.inputValue,
                                text: `Add '${params.inputValue}'`,
                            });
                        }
                    }
                    return filtered;
                    }
                }
                style={{ width: props?.width, marginTop: '-1rem'}}
                popupIcon={<MDBIcon icon="chevron-right" size='sm'/>}
                onChange={props?.onChange}
                renderInput={(params) => <TextField 
                    //className={"textFieldContainer"}
                    placeholder="Add Or Select"
                    {...params} variant="outlined" 
                    // style={{height:'1.4rem', border: '1px solid',}}
                    />}
            />
        </div>
    );
}

export default SearchFieldWithAddValue;

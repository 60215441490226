import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import { MDBSpinner } from 'mdb-react-ui-kit';
//import MainContent from '../main-content/MainContent';
import LandingPage from './LandingPage/LandingPage';
import HeaderContent from '../header/HeaderContent';
//import FooterContent from '../components/PageElements/FooterContent';
import ErrorPage from '../error-boundary/ErrorPage';
//import "bootstrap-css-only/css/bootstrap.min.css";
import { withOktaAuth } from '@okta/okta-react';
import setManagerService from '../services/service';
import * as Constants from '../constants/constants';
import {
  BrowserRouter as Router, withRouter
} from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { UserContext } from '../contexts/UserContext';
import MainContent from './MainContent';

export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        //this.props.authService.logout('/');
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        isValidUser: true,
        isLoading: true,
        isLoggedIn: false,
        user_name: null,
        user_full_name: null,
        user_profile: null,
        user_profile_okta: null,
        errorId: 0,
        errorDesc: '',
        active_tenant: {
          tenant_id: null,
          tenant_name: null,
          role: null,
          permissions: {
            isAdmin: false,
            }
        },
        active_route: '/',
        dropDownList: {
          setNames: [],
          show: [],
          idPreffixes: [],
          idSuffixes: [],
          category: [],
          condition: [],
          location: [],
      },
      }
    }

    _onIdle(e) {

      //authService.logout();
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }
    

    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            let name = response.name
            let email = response.email;

            //console.log("User Info :" + JSON.stringify(response));
            localStorage.setItem('user_email', email);
            //localStorage.setItem('user_name', name);
            localStorage.setItem('user_full_name', name);
            //localStorage.setItem('user_profile_okta', JSON.stringify(UserProfile));
            this.setState({ user_full_name: name }); //isLoading : false,

            setManagerService.getData(Constants.setManagerServiceBaseUrl + '/getLoggedInUser', 0)//"./user_data.json") //Constants.Set ManagerSharedServiceBaseUrl + 'User?userEmail=' + email, this.props.userContext?.active_tenant?.tenant_id)
              .then(response => {
                if (this._isMounted) {
                  //console.log("User Profile -->" + JSON.stringify(response));
                  if (response && response?.data?.length > 0) { // (response.data && response.data.length > 0) {
                    let userProfile = response.data[0];// UserProfile; // response.json(); //data[0];
                    //if(userprofile )
                    if(userProfile) {
                      this.getSetStaticList(response?.data[0]?.user_roles[0]?.tenant_id)
                      this.fetchsetList(response?.data[0]?.user_roles[0]?.tenant_id)
                    }
                    let userId = userProfile.user_id;
                    localStorage.setItem('user_id', userId);
                    localStorage.setItem('user_profile', JSON.stringify(userProfile));

                    var is_user_provisioner = userProfile.is_user_provisioner;
                    var is_active_user = userProfile.is_active;

                    if (!is_active_user) {
                      this.setState({
                        isValidUser: false,
                        isLoggedIn: false,
                        isLoading: false,
                        errorId: '401',
                        errorDesc: 'User is marked as inactive in the system. Please contact admin for Set Manager application'
                      });
                    }
                    else {
                      if (is_user_provisioner) {
                        userProfile.default_tenant_id = 0;
                      }
                      this.setState({
                        isValidUser: true,
                        isLoading: false,
                        isLoggedIn: true,
                        user_name: userProfile.name,
                        user_profile: userProfile
                      });
                    }
                    this.processPermissions(userProfile, is_user_provisioner);
                  }
                  else {
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for Set Manager application'
                    });
                  }
                }
              },
                (err) => {
                  console.log('Service Error : Log In -> Getting User Profile ' + err);
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '503',
                    errorDesc: 'User profile service is not available. Please try again and contact admin for Set Manager application'
                  });
                });


          },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: '503',
                errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for Set Manager application'
              });
            });
      }
      catch (err) { }
    }
    processPermissions(userProfile, is_user_provisioner) {

      //let permissions = appConstants.PERMISSIONS;
      //let permissionObjs = [];
      if (userProfile) {
        // debugger;
        let tenants = [];
        var default_tenant_id = 1; // userProfile.default_tenant_id;
        var active_tenant = localStorage.getItem("active_tenant_id");
        var active_tenant_name = localStorage.getItem("active_tenant_name");
        var active_tenant_role;
        // this.setState({ tenants: userProfile.last_logged_at });
        // userProfile.last_logged_at.forEach((tenant) => {
        this.setState({ tenants: userProfile.user_roles });
        userProfile.user_roles.forEach((tenant) => {
          if (tenant.tenant_id == null) {
            tenant.tenant_id = 0;
            tenant.tenant_name = "All Tenants";
          }
          tenants.push({
            "tenant_id": tenant.tenant_id,
            "tenant_name": tenant.tenant_name
          });
          localStorage.setItem("tenants", tenants);

          if ((active_tenant == "null" || !active_tenant) && default_tenant_id == tenant.tenant_id) {
            localStorage.setItem("active_tenant_id", tenant.tenant_id);
            localStorage.setItem("active_tenant_name", tenant.tenant_name);
            active_tenant = tenant.tenant_id;
          }

          
          if (active_tenant == tenant.tenant_id || userProfile.is_user_provisioner === 1) {
            this.processPermissionsforTenant(tenant, is_user_provisioner);
          }
          //permissionObjs.push(permObj);
        });
        //localStorage.setItem("permissions", permissionObjs);     

      }

    }


    processPermissionsforTenant(tenant, is_user_provisioner) {
      var isAdmin = false;
      //var isViewData = false;
      //var isViewUser = false;


      // var active_tenant_name = localStorage.getItem("active_tenant_name");
      // var active_tenant_role;
      var active_tenant_id = tenant.tenant_id;
      var active_tenant_name = tenant.tenant_name;
      var active_tenant_role = tenant.role;


      // }
      // if(active_tenant === tenant.tenant_id){
      var active_tenant = { ...this.state.active_tenant };
      var permissions_tenant = active_tenant.permissions;

      var isAdmin = tenant?.role_name && tenant?.role_name?.toLowerCase() == 'admin' ? true : false;
      var isRegular = tenant?.role_name && tenant?.role_name?.toLowerCase() == 'regular user' ? true : false;
      var isReadOnly = tenant?.role_name && tenant?.role_name?.toLowerCase() == 'read-only user' ? true : false;

      tenant.permissions.map((permission) => {
        permissions_tenant.isAdmin = isAdmin;
        permissions_tenant.isRegular = isRegular;
        permissions_tenant.isReadOnly = isReadOnly;
        if (is_user_provisioner === 1) {
          permissions_tenant.isManageUser = true;
        }
        if (permission.permission_name === "Manage Lookups") {
          permissions_tenant.isManageLookups = true;
        }
        if (permission.permission_name === "Edit Set") {
          permissions_tenant.isModifySet = true;
        }

        if (permission.permission_name === "View Set") {
          permissions_tenant.isViewSet = true;
        }

        if (is_user_provisioner) {
          active_tenant_role = "User Provisioner";
          // permissions_tenant.isViewUser = true;
          // permissions_tenant.isModifyUser = true;

        }

      });

      active_tenant.tenant_id = active_tenant_id;
      active_tenant.tenant_name = active_tenant_name;
      active_tenant.role = active_tenant_role;
      active_tenant.permissions = permissions_tenant;

      console.log("User Profile - Active Tenant:" + JSON.stringify(active_tenant));

      this.setState({
        // isAdmin: isAdmin,
        // isCreateShow: isCreateShow,
        // isCreateSeason: isCreateSeason,
        // isCreateEpisode: isCreateEpisode,
        // isCreateTalent: isCreateTalent,
        // isViewShow: isViewShow,
        // isViewSeason: isViewSeason,
        // isViewEpisode: isViewEpisode,
        // isViewTalent: isViewTalent,
        active_tenant: active_tenant
      });

      localStorage.setItem("active_tenant_id", tenant.tenant_id);
      localStorage.setItem("active_tenant_name", tenant.tenant_name);
      // localStorage.setItem("active_tenant_event_role", eventRole);
      // localStorage.setItem("active_tenant_gift_role", giftRole);
      // localStorage.setItem("active_tenant_card_role", cardRole);
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    // handleSideNavActive = (value, routePath) => {       
    //   //this.props.history.push(routePath);
    //   //this.props.handleSideNavActive(value, routePath);
    //   //this.setState({active_route : routePath});
    //   this.mainContent.current.handleSideNavActive1(value, routePath);
    // }

    // handleSideNavActive1 = (value, routePath) => {
    //   this.mainContent.current.handleSideNavActive1(value, routePath);
    // }

    switchTenant = (tenant_id) => {
      //alert("Switched to Tenant -->"+tenant_id);
      var tenant = this.state.user_profile.user_roles.find(tenant => tenant.tenant_id == tenant_id);
      this.processPermissionsforTenant(tenant, this.state.user_profile.is_user_provisioner);
      this.props.history.push('/', this.state);
      window.location.reload();
    }

    getSetStaticList = (tenantId) => {
      this.fetchstaticData('lu_category', tenantId)
      this.fetchstaticData('lu_condition', tenantId)
      this.fetchstaticData('lu_location', tenantId)
      this.fetchstaticData('lu_show', tenantId)
    }

    fetchsetList = (tenantId) => {
      setManagerService.getData(Constants.setManagerServiceBaseUrl + '/getSetList', tenantId)
          .then(response => {
              if (response?.data[0]) {
                this.setState(prevState => ({
                  dropDownList: {
                      ...prevState.dropDownList,
                      ['setNames']: response?.data[0]?.set?.map(item => ({ text: item?.set_name, value: item?.set_id })).sort((a,b) => a.text.localeCompare(b.text)),
                      ['idPreffixes']: response?.data[0]?.id_prefix?.map(item => ({ text: item, value: item })).sort((a,b) => a.text.localeCompare(b.text)),
                      ['idSuffixes']: response?.data[0]?.id_suffix?.map(item => ({ text: item, value: item })).sort((a,b) => a.text.localeCompare(b.text)),
                  }
                }))
              }
          },
              (err) => {
                  console.log("Error in fetching set List:", err)
              })
  }

    fetchstaticData = (type, tenantId) => {
      setManagerService.getData(Constants.setManagerServiceBaseUrl + `/staticData?staticTable=${type}`, tenantId)
          .then(response => {
              this.setState(prevState => ({
                dropDownList: {
                    ...prevState.dropDownList,
                    [`${type.split('_')[1]}`]: response?.data?.map(item => ({ text: item?.name, value: item?.id , is_active : item?.is_active})).sort((a,b) => a.text.localeCompare(b.text))
                }
              }))
          },
              (err) => {
                  console.log("Error in fetching set List:", err)
              })
    }

    render() {
      return (
        (this.state.isLoading ?

          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">
              </MDBSpinner>
              <div>
                <span style={{ textAlign: 'center' }}>Loading Set Manager....</span>
              </div>
            </div>
          </div>

          : (
            !this.state.isValidUser ?
              <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId}
                errorDesc={this.state.errorDesc} ></ErrorPage>
              :

              <>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onIdle={this.onIdle}
                  debounce={250}
                  timeout={1000 * 60 * Constants.IDLE_TIMEOUT} />
                <UserContext.Provider value={{
                  user_profile: this.state.user_profile,
                  active_tenant: this.state.active_tenant,
                  switchTenant: this.switchTenant.bind(this),
                  dropDownList:this.state.dropDownList,
                  getSetStaticList:this.getSetStaticList.bind(this),
                  fetchsetList:this.fetchsetList.bind(this)
                }}>
                  <Router>
                    <div className="h-100">
                      <HeaderContent {...this.state}
                        authState={this.props.authState} switchTenant={this.switchTenant.bind(this)} />
                      <MainContent {...this.state} {...this.props} />

                      {/* <LandingPage {...this.state} {...this.props}/> */}
                     {/* <MainContent {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
              */}
                    </div>
                  </Router>
                </UserContext.Provider>
              </>
          )
        )
      );
    }
  }
  )
);

import React, { Component } from 'react'
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import ImageCard from '../../../Common/ImageComponent/ImageCard';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import SetManagerService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import Modal from '../../SharedComponents/Modal/Modal';
import CommonCatalog from '../../CommonCatalog/CommonCatalog';
import "./Catalog.scss";
import CircularProgress from '@material-ui/core/CircularProgress';
import MessageModal from '../../SharedComponents/MessageModal';
import Messages from "../../../Common/Messages.json"
import FileParams from "../../AddFiles/FileParams.json"
import { withUserContext } from '../../../contexts/UserContext';
import { MDBIcon } from 'mdbreact';
class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCatalogFileModal: false,
      showExportCatalogModal: false,
      showEmailCatalogModal: false,
      showPrintCatalogModal: false,
      showSortCatalogModal:false,
      fileParams: FileParams,
      showCustom: false,
      customMsg: false,
      showFail: false,
      showError: false,
      showSuccess: false,
      isLoading: false,
      catalogFiles: [],
      fileData: null,
      fileName: null,
      showDelete: false,
      deleteMessage: '',
      clickedFileName: '',
      isChangeImage: false,
    }
    this.catalogRef = React.createRef();
    this.fileUploadDrag = React.createRef();
  }
  getFiles = (files) => {
    // console.log(file);
    //     let x = this.fileUploadDrag;
    //     console.log(x);
    //    console.log(this.fileUploadDrag.current.querySelector(".file-upload-input").file);
    this.setState({ fileData: files })
  }
  handleFileName(name) {
    this.setState({ fileName: name })
  }
  showCatalogFileModal = () => {
    this.setState({ showAddCatalogFileModal: true });
  }
  hideCatalogFileModal = () => {
    this.setState({ showAddCatalogFileModal: false });
  }

  toggleExportCatalogVisibility = () => {
    this.setState({ showExportCatalogModal: !this.state.showExportCatalogModal });
  }

  toggleEmailCatalogVisibility = () => {
    this.setState({ showEmailCatalogModal: !this.state.showEmailCatalogModal })
  }

  togglePrintCatalogVisibility = () => {
    this.setState({ showPrintCatalogModal: !this.state.showPrintCatalogModal })
  }
  toggleSortCatalogVisibility = () => {
    this.setState({ showSortCatalogModal: !this.state.showSortCatalogModal })
  }
  onDeleteClick(filename) {
    let msg = Messages.deleteMessage.replace("[filename]", filename);
    this.setState({ showCustom: true, showDelete: true, clickedFileName: filename, deleteMessage: msg, showError: true })
  }
  onChangeClick(filename) {
    this.setState({ clickedFileName: filename, isChangeImage: true });
    this.showCatalogFileModal();
  }
  messagePopupConfirm() {
    if(this.state?.showSuccess){
      this.hideCatalogFileModal();
    }
    this.setState({ showError: false, showFail: false, showSuccess: false, showCustom: false })
    if (this.state?.showDelete) {
      this.deleteCatalog();
      this.setState({ showDelete: false })
    }
   
  }
  uploadFile() {
    let files = [...this.state.fileData];
    if (files.length === 0) {
      this.setState({ showCustom: true, customMsg: "File is required" })
      return;
    }
    if( this.state.isChangeImage && files.length > 1) {
      this.setState({ showCustom: true, customMsg: "Only 1 file can be uploaded for change" })
      return;
    }
    var listFileParams = { ...this.state.fileParams };
    listFileParams.setId = this.props?.setId
    listFileParams.IDSuffix = this.props?.idSuffix
    listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
    listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
    listFileParams.fileName = this.state.fileName;
    listFileParams.signedURLType = "upload"
    listFileParams.uploadLocation = "images"
    listFileParams.isSort = null,
    listFileParams.files = this.state.fileData?.map((item,index) => {
      if(this.state.isChangeImage) {
        return {'name' :  `${new Date().getTime()}_${item.name}` , 'order' : this.props?.catalogFiles?.find(ele => ele?.name === this.state.clickedFileName)?.order}
      } else {
        let order = this.props?.catalogFiles.length === 0 ? index : this.props?.catalogFiles.length + index
        return {'name' :  `${new Date().getTime()}_${item.name}` , 'order' : order}
      }
    })
    if (this.state.isChangeImage) {
      listFileParams.oldFile = this.state.clickedFileName;
    }
   
    // let formData = new FormData();
    // Object.keys(listFileParams).forEach(key => formData.append(key, listFileParams[key]));
    // files.map(obj => {
    //   console.log(obj)
    //   formData.append('file', obj)
    // })
    this.setState({ isLoading: true })
    SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        if(response.data.error){
          this.setState({showFail:true,isLoading:false})
          return;
        } 
        response?.data?.data?.map((item ,index)=> {
          SetManagerService.uploadFile(item, this.state.fileData[index]).then(response => {
            if( index == files?.length - 1) {
              if(response.data.error){
                this.setState({showFail:true, isLoading:false})
                return;
              }
              if(this?.props?.setDetails?.default_image == null || this?.props?.setDetails?.default_image == this.state.clickedFileName ){
                this.onClickMakePreview(listFileParams.files?.[0]?.name);
              } else {
                this.props?.fetchSetDetails(this.props?.setDetails?.set_id)
              }
              this.setState({ showSuccess: true, isLoading: false,isChangeImage:false,clickedFileName:"" })
              
            }
          }, (err) => {
            this.setState({ showFail: true, isLoading: false })
            return;
          });
        })
      }, (err) => {
        console.log("catalog File Upload Failed:" + err);
        this.setState({ showFail: true, isLoading: false })
      });
  }
  deleteCatalog() {
    var listFileParams = { ...this.state.fileParams };
    listFileParams.setId = this.props?.setId
    listFileParams.IDSuffix = this.props?.idSuffix
    listFileParams.fileName = this.state?.clickedFileName;
    listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
    listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
    listFileParams.uploadLocation = "images"
    if(this?.props?.setDetails?.default_image == this.state?.clickedFileName && this.props?.catalogFiles.length > 0){
      let fileListAfterDelete = this.props?.catalogFiles?.filter(item => item?.name != this.state?.clickedFileName)
      if(fileListAfterDelete.length === 0) {
        this.onClickMakePreview(null)
      } else {
        this.onClickMakePreview(fileListAfterDelete[0]?.name)
        this.afterDeleteSort(fileListAfterDelete)
      }
    }
    SetManagerService.deleteDataParams(Constants.setManagerServiceBaseUrl + '/deleteFiles',
      listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        if (response?.data?.error) {
          this.setState({ showFail: true })
          console.log("error from delete files", response.data.error)
          return
        }
        this.setState({ showSuccess: true })
        this.props?.fetchSetDetails(this.props?.setDetails?.set_id)
      }, (err) => {
        this.setState({ showFail: true })
        console.log("from delete cad files", err);
      });
  }
  afterDeleteSort(files) {
    for(let i = 0 ; i < files.length ;i ++) {
      if(files[i].order === i) {
        continue
      } else {
        files[i].order = i - 1
      }
    }
    var listFileParams = { ...this.state.fileParams };
    listFileParams.setId = this.props?.setId
    listFileParams.IDSuffix = this.props?.idSuffix
    listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
    listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
    listFileParams.fileName = "";
    listFileParams.signedURLType = "upload"
    listFileParams.uploadLocation = "images"
    listFileParams.isSort = 1,
    listFileParams.files = files?.map((item,index) => {
        return {'name' : item?.name , 'order' : files.order}
    })
   
    this.setState({ isLoading: true })
    SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        if(response.data.error){
          console.log('files did not ordered')
        } else {
            this.props.getFiles(this.props?.setId,this.props?.idSuffix )
        }
      }, (err) => {
        console.log('files did not ordered')
      });
  }
  onClickMakePreview(fileName) {
    let postJson = { ...this.props?.setDetails }
    postJson.default_image = fileName;
    Object.keys(postJson).forEach(key => {
      if (postJson[key] === "") {
        postJson[key] = null
      }
    });
    this.setState({ isLoading: true })
    SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/setData', postJson, this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        if (response.data.error) {
          this.setState({ showFail: true, isLoading: false })
          return;
        }
        this.setState({ showSuccess: true, isLoading: false })
        this.props?.fetchSetDetails(this.props?.setDetails?.set_id)
      }, err => {
        this.setState({ showFail: true, isLoading: false })
        console.log('error from set preview image')
      })
  }
  render() {
    let comfirmModalTitle = Messages.defaultTitle;
    let message = this.state.showCustom ? this.state?.showDelete ? this.state.deleteMessage : this.state.customMsg : Messages.defaultMessage;
    return (
      <>
        {this.state?.isLoading && <CircularProgress />}

        <MessageModal
          open={this.state.showCustom || this.state.showError || this.state.showFail || this.state.showSuccess || false}
          title={comfirmModalTitle}
          showError={this.state.showError || false}
          showFail={this.state.showFail || false}
          showSuccess={this.state.showSuccess || false}
          message={message}
          showCustom={this.state.showCustom || false}
          hideCancel={this.state.showFail || this.state.showSuccess || false}
          primaryButtonText={"Ok"}
          secondaryButtonText={"Cancel"}
          onConfirm={() => this.messagePopupConfirm()}
          handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, showCustom: false, customMsg: '' })}

        />
        {this.state.showAddCatalogFileModal &&
          <Modal open={this.state.showAddCatalogFileModal || false}
            modalName={'AddCatalogFile'}
            comfirmModalTitle={'Add Catalog'}
            onClose={this.hideCatalogFileModal}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setDetails={this.props?.setDetails}
            handleFile={this.getFiles}
            accept={["image/*"]}
            handleFileName={this.handleFileName.bind(this)}
            handleSubmit={this.uploadFile.bind(this)}
            isSubmit = {this.state?.isLoading}
            innerRef={this.fileUploadDrag}
            files={this.state.fileData}
          />}
        {this.state.showExportCatalogModal &&
          <Modal open={this.state.showExportCatalogModal || false}
            key={"exportCatalogModal"}
            modalName={'ExportCatalog'}
            comfirmModalTitle={'Export Catalog'}
            onClose={this.toggleExportCatalogVisibility}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setDetails={this.props?.setDetails}
            catalogFiles={this.props?.catalogFiles}
            showCheckbox={true}
          />}

        {this.state.showEmailCatalogModal &&
          <Modal open={this.state.showEmailCatalogModal || false}
          key={"EmailCatalogModal"}
            modalName={'EmailCatalog'}
            comfirmModalTitle={'Email Catalog'}
            onClose={this.toggleEmailCatalogVisibility}
            cadDrawings={this.props?.cadDrawings}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setId={this.props?.setId}
            //setName={this.props?.setDetails?.set_name}
            idSuffix={this.props?.idSuffix}
            setDetails={this.props?.setDetails}
            catalogFiles={this.props?.catalogFiles}
            showCheckbox={true}
          />}

        {this.state.showPrintCatalogModal &&
          <Modal open={this.state.showPrintCatalogModal || false}
            modalName={'PrintCatalog'}
            key={"PrintCatalogModal"}
            comfirmModalTitle={'Print Catalog'}
            onClose={this.togglePrintCatalogVisibility}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setDetails={this.props?.setDetails}
            catalogFiles={this.props?.catalogFiles}
            showCheckbox={true}
          />}
           {this.state.showSortCatalogModal &&
          <Modal open={this.state.showSortCatalogModal || false}
            modalName={'SortCatalog'}
            key={"SortCatalogModal"}
            comfirmModalTitle={'Sort Catalog'}
            onClose={this.toggleSortCatalogVisibility}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setDetails={this.props?.setDetails}
            catalogFiles={this.props?.catalogFiles}
            showCheckbox={true}
            callback={this.props.getFiles}
            setId={this.props?.history ? this.props.setDetails?.revamp_set_id  : this.props.setDetails?.set_id}
          />}


        <div className='catalog-section'>
          <MDBRow className='justify-content-between'>
            <MDBCol md={7} sm={12} className="underlineLabel">
              <MDBRow>
                <div className="catalog-header">
                  <BasicLabel text="Catalog" />
                  <MDBIcon fas className={(this.props.indexPos === 'first' || this.props.indexPos === 'comb')  ? 'disabled' : 'enabled'}
                    size='2x'
                    icon={"chevron-circle-left"}
                    onClick={() => this.props.getPreviousSet(this.props.setDetails)} />
                  <MDBIcon fas className={(this.props.indexPos === 'last' || this.props.indexPos === 'comb') ? 'disabled' : 'enabled'}
                    size='2x'
                    icon={"chevron-circle-right"}
                    onClick={() => this.props.getNextSet(this.props.setDetails)} />
                </div>
              </MDBRow>
            </MDBCol>
            <MDBCol md={5} className='flex justify-content-between'>          
           
              <BasicButton
                icon="plus-circle"
                onClick={this.showCatalogFileModal}
                text={"Add Photos"}
                comfirmModalTitle={'Add Photos'}
                disabled={this.props?.history}
              />
           
              <BasicButton
                onClick={() => this.toggleSortCatalogVisibility()}
                variant="outlined"
                text={"Sort"}
                icon={"sort-alpha-down"}
                comfirmModalTitle={'Sort Catalog'} />
           
              <BasicButton
                onClick={() => this.toggleExportCatalogVisibility()}
                icon="file-export"
                variant="outlined"
                text={"Export"}
                comfirmModalTitle={'Export catalog'} />
          
              <BasicButton
                onClick={() => this.toggleEmailCatalogVisibility()}
                icon="envelope"
                // icon="telegram-plane"
                setId={this.props?.setId}
                setName={this.props?.setName}
                variant="outlined"
                text={"Email"}
                comfirmModalTitle={'Print'} />
           
              <BasicButton
                onClick={() => this.togglePrintCatalogVisibility()}
                icon="print"
                variant="outlined"
                text={"Print"}
                comfirmModalTitle={'Print Catalog'} />
            </MDBCol>
          </MDBRow>
          <CommonCatalog
           history={this.props?.history}
           key={"landingCatalog"}
           id={"landingCatalog"}
            // catalogFiles={this.state.catalogFiles}
            showId={this.props?.showId}
            showName={this.props?.showName}
            setId={this.props?.setId}
            setName={this.props?.setName}
            idSuffix={this.props?.idSuffix}
            setDetails={this.props?.setDetails}
            showCheckbox={false}
            catalogType="main"
            catalogFiles={this.props?.catalogFiles}
            catalogRef={this.catalogRef}
            onClickMakePreview={this.onClickMakePreview.bind(this)}
            onDeleteClick={this.onDeleteClick.bind(this)}
            onChangeClick={this.onChangeClick.bind(this)}
          />
        </div>
      </>
    )
  }
}

export default withUserContext(Catalog)
import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBModal,
    MDBContainer, MDBIcon, MDBBtn
} from "mdb-react-ui-kit";
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import VersionHistory from '../../../../components/VersionHistory/VersionHistory';
import * as Constants from '../../../../constants/constants';
import SetManagerService from '../../../../services/service';
import CadDrawing from '../../../../components/CadDrawing/CadDrawing';
import Catalog from '../../Catalog/Catalog';
import {getFormattedDate} from '../../../../Common/Helper';
import './SetDetails.scss';
import FileParams from "../../../AddFiles/FileParams.json"
import ImageCard from '../../../../Common/ImageComponent/ImageCard';
import { withUserContext } from '../../../../contexts/UserContext'




class SetDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setDetails : this.props?.setDetails,
            isLoading:false,
            //catalogFiles:[],
            fileParams:FileParams,
            previewImgUrl:null
        }
     }

    showHistoryDetails = (historySetDetail) => {
        this.setState({setDetails: historySetDetail}, () => {
            this.props?.getCatalogFiles(historySetDetail?.revamp_set_id, historySetDetail?.id_suffix)
            this.props?.getCadFiles(historySetDetail?.revamp_set_id, historySetDetail?.id_suffix)
        })
        this.props?.toggleHisorty(historySetDetail)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.setDetails !== state.setDetails && !props.history) {
            return {
                setDetails: props.setDetails
            }
        }
        return null
    }

    componentDidMount(){
        //this.getFiles();
    }
    
    render() {
        //const { setDetails } = this.state?.setDetails
        return (
            <>
                <MDBRow className='setDetailContainer'>
                    <MDBCol md={7}>
                        <div className="setDetail">
                            <MDBRow>

                                <MDBCol md={6}>
                                    <BasicLabel text="Set Name" />
                                    <span className="readOnly">{this.state?.setDetails?.set_name || "-"}</span>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <BasicLabel text="ID" />
                                    <span className="readOnly">{this.state?.setDetails?.id_prefix + " - " + this.state?.setDetails?.id_suffix || "-"}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <BasicLabel text="Show" />
                                    <span className="readOnly">{this.state?.setDetails?.show_name || "-"}</span>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <BasicLabel text="Location" />
                                    <span className="readOnly">{this.state?.setDetails?.location_name || "-"}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <BasicLabel text="Category" />
                                    <span className="readOnly">{this.state?.setDetails?.category_name || "-"}</span>
                                </MDBCol>
                                <MDBCol md={2} className="mt-4">
                                    <BasicCheckbox
                                        id="set-open"
                                        label="Open"
                                        disabled={true}
                                        checked={this.state?.setDetails?.is_open || 0 }
                                        align="start"
                                    />
                                </MDBCol>
                                <MDBCol md={4}>
                                    <BasicLabel text="Condition" />
                                    <span className="readOnly">{this.state?.setDetails?.condition_name || "-"}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3}>
                                    <BasicLabel text="Cost to Build" />
                                    <span className="readOnly">{this.state?.setDetails?.build_cost ? "$" + this.state?.setDetails?.build_cost : "-"}</span>
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicLabel text="Revamp Cost" />
                                    <span className="readOnly">{this.state?.setDetails?.revamp_cost ? "$" + this.state?.setDetails?.revamp_cost : "-"}</span>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <BasicLabel text="Last Show Used Set" />
                                    <span className="readOnly">{this.state?.setDetails?.last_show_used || "-"}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {/* MM/DD/YYYY */}
                                <MDBCol md={3}>
                                    <BasicLabel text="First Build Date" />
                                    <span className="readOnly">{getFormattedDate(this.state?.setDetails?.first_build_date) || "-"}</span>
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicLabel text="Strike Date" />
                                    <span className="readOnly">{getFormattedDate(this.state?.setDetails?.strike_date) || "-"}</span>
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicLabel text="Date of Last Use" />
                                    <span className="readOnly">{getFormattedDate(this.state?.setDetails?.last_used_date) || "-"}</span>
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicLabel text="Date Photos Taken" />
                                    <span className="readOnly">{getFormattedDate(this.state?.setDetails?.photos_taken_date) || "-"}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <BasicLabel text="Keywords" />
                                    <span className="readOnly">{this.state?.setDetails?.keywords || "-"}</span>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <BasicLabel text="Comments" />
                                    <span className="readOnly">{this.state?.setDetails?.comments || "-"}</span>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    </MDBCol>
                    <MDBCol md={5}>
                        <div className="setTitlePic">
                            <ImageCard
                            src={this.props?.catalogFiles?.find(item => item?.name == this.state?.setDetails?.default_image)?.url}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>                
                <MDBRow>
                    <MDBCol md={7}>

                        <CadDrawing className="cadListBox" 
                            // showId={this.props?.setDetails.show_id || null}
                            // showName={this.props?.setDetails.show_name || null}
                            setId={this.props?.history ? this.state?.setDetails?.revamp_set_id : this.props?.setDetails.set_id || null}
                            // setName={this.props?.setDetails.set_name || null}
                            idSuffix={this.state?.setDetails?.id_suffix || null}
                            cadDrawings={this.props?.cadDrawings}
                            getCadFiles={this.props?.getCadFiles}
                            history={this.props?.history}
                         />
                    </MDBCol>
                    <MDBCol md={5}>
                        <VersionHistory 
                            setDetailsRevampHistory={this.props.setDetailsRevampHistory}
                            showHistoryDetails={this.showHistoryDetails.bind(this)}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='catalog mt-2'>
                        <Catalog 
                            showId={this.state?.setDetails?.show_id || null}
                            cadDrawings={this.props?.cadDrawings}
                            showName={this.state?.setDetails?.show_name || null}
                            setId={this.props?.history ? this.state.setDetails?.revamp_set_id : this.props?.setDetails.set_id || null}
                            setName={this.state?.setDetails.set_name || null}
                            idSuffix={this.state?.setDetails?.id_suffix || null}
                            getNextSet={this.props?.getNextSet}
                            getPreviousSet={this.props?.getPreviousSet}
                            fetchSetDetails={this.props?.fetchSetDetails}
                            getFiles={this.props.getCatalogFiles}
                            catalogFiles={this.props?.catalogFiles}
                            setDetails={this.state?.setDetails}
                            indexPos={this.props.indexPos}
                            history={this.props?.history}
                            />
                </MDBRow>              

            </>
        )
    }
}           
export default withUserContext(SetDetails)
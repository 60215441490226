import React from 'react';
import jsPDF from 'jspdf';
import CircularProgress from "@material-ui/core/CircularProgress";
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import { getFormattedDate } from '../../Common/Helper';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CommonCatalog from '../../components/CommonCatalog/CommonCatalog';
import './ExportCatalog.scss'

class ExportCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allSelectedImage: [],
            isExport: false
        }
    }

    getSelectedCatalogFiles(selectedArray) {
        this.setState({ allSelectedImage: selectedArray })
    }

    exportCatalog = () => {
        let getAllSelectedImges = [...this.state.allSelectedImage]
        this.setState({ isExport: true })
        // page width - 594 page height - 459 in px
        // getTextWidth gives 950 the width of text which covers the whole 594 px width of page
        // So for aligning puprpose ) 0.62 factor is used at the plcae where getTextWidth is used
        var doc = new jsPDF('l', 'px', 'letter')
        doc.addFont('Times-Roman');
        doc.setFont('Times-Roman');
        var ids = (this.props?.setDetails?.id_prefix || this.props?.setDetails?.id_suffix) ?
        ((this.props?.setDetails?.id_prefix ? 
         this.props?.setDetails?.id_prefix : '-') 
        +' '+
        (this.props?.setDetails?.id_suffix ? this.props?.setDetails?.id_suffix : '-' )) : "-"
        var photoDateLastShow = (this.props?.setDetails?.last_show_used || this.props?.setDetails?.last_used_date) ?
        ((this.props?.setDetails?.last_show_used ? 
         this.props?.setDetails?.last_show_used : '-') 
        +' '+
        (this.props?.setDetails?.last_used_date ? getFormattedDate(this.props?.setDetails?.last_used_date) : '-' ))
        : "-"
        var showNameWidth = this.props?.setDetails?.show_name ? Math.ceil(doc.getTextWidth(this.props?.setDetails?.show_name)*0.62) : 0
        var setNameWidth = this.props?.setDetails?.set_name ? Math.ceil(doc.getTextWidth(this.props?.setDetails?.set_name)*0.62) : 0
        var idWidth = ids ? Math.ceil(doc.getTextWidth(ids))*0.62 : 0
        var photoDateLastShowWidth = Math.ceil(doc.getTextWidth(photoDateLastShow)*0.62) 
        var y = 0
        var self = this
        async function header  () {
            doc.setFontSize(10)
            doc.setTextColor(0, 0, 0)
            doc.setFont('Times-Roman', 'normal')
            doc.text(10, 20, 'Comments')
            doc.text(10, 30, self.props?.setDetails?.comments?.replaceAll('\n',' ') || "-",{ maxWidth: 180 })
            doc.setFont('Times-Roman', 'bold')
            doc.text(300 - showNameWidth/2, 20, self.props?.setDetails?.show_name || "-")
            doc.text(300 - setNameWidth/2, 30, self.props?.setDetails?.set_name || "-")
            doc.text(300 - idWidth/2, 40, ids || "-")
            doc.setFont('Times-Roman', 'normal')
            doc.text(584-photoDateLastShowWidth, 20, photoDateLastShow)
            return 60
        }
        if (getAllSelectedImges.length > 0) {
            try {
            var catalogName = `${this.props?.setDetails?.set_name}_${self.props?.setDetails?.id_prefix}_${self.props?.setDetails?.id_suffix}_Catalog.pdf`
            buttonClick()
            function getDataUri(url) {
                return new Promise(resolve => {
                    var image = new Image();
                    image.setAttribute('crossOrigin', 'anonymous'); 

                    image.onload = function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = this.naturalWidth;
                        canvas.height = this.naturalHeight;

                        var ctx = canvas.getContext('2d');
                        ctx.fillStyle = '#fff';  
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        canvas.getContext('2d').drawImage(this, 0, 0);

                        resolve(canvas.toDataURL('image/jpeg'));
                    };

                    image.src = url+`&${new Date().getTime()}`;
                })
            }
            async function generatePdf(imageUrls) {
                for (const [i, url] of imageUrls.entries()) {
                    const image = await getDataUri(url);
                    let x = 0
                    if(i % 4 === 0) {
                        if(i !== 0) {
                            doc.addPage()
                        }
                        y = await header()
                    }
                    if(i % 2 === 0) {
                        x = 10
                        y = i % 4 === 0 ? y : y + 195
                    } else {
                        x = 300
                    }
                    doc.addImage(image, 'PNG', x, y, 285, 190)
                    if(i === imageUrls.length-1 ) {
                        self.setState({ isExport: false }, self.props?.onClose)
                    }
                }
                return doc;
            }

            function buttonClick() {
                savePdf().then(console.log("Sa  ved"));
            }

            async function savePdf() {
                const multiPng = await generatePdf(getAllSelectedImges?.map(item => item?.url));

                const dataURLString = multiPng.output("dataurlstring", catalogName);

                multiPng.output("save", catalogName.replaceAll('"',''));
            }         
        } catch (error) {
                alert(error);
        }
        } else {
            let x = header()
            doc.save(`${this.props?.setDetails?.set_name}_${self.props?.setDetails?.id_prefix}_${self.props?.setDetails?.id_suffix}_Catalog.pdf`.replaceAll('"',''))
            this.setState({ isExport: false}, this.props?.onClose) 
        }
    }

    render() {
        return (
            <MDBContainer fluid className='ExportCatalogContainer'>
                <MDBRow>
                    <CommonCatalog
                        key={"exportCatalog"}
                        id={"exportCatalog"}
                        showId={this.props?.showId}
                        showName={this.props?.showName}
                        setDetails={this.props?.setDetails}
                        showCheckbox={this.props?.showCheckbox}
                        catalogFiles={this.props?.catalogFiles}
                        catalogType="export"
                        uploadType="images"
                        //catalogRef={this.catalogRef}
                        getAllSelectedFiles={this.getSelectedCatalogFiles.bind(this)}
                    />
                </MDBRow>
                <MDBRow className="p-2">
                    <MDBCol md={8} >
                    </MDBCol>

                    <MDBCol md={2}>
                        <BasicButton
                            variant="contained"
                            type="inline"
                            text={this.state?.isExport ? <CircularProgress color="inherit" size={15} /> : "Export"}
                            icon="file-export"
                            onClick={this.exportCatalog}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            disabled={false}
                            onClick={this.props.onClose}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default ExportCatalog;

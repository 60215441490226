export const newSet = {
	"set_id": null,
	"set_name": null,
	"id_prefix": null,
	"id_suffix": null,
	"show_id": null,
	"location_id": null,
	"category_id": null,
	"condition_id": null,
	"is_open": 0,
	"build_cost": null,
	"revamp_cost": null,
	"last_show_used_id": null,
	"first_build_date": null,
	"strike_date": null,
	"last_used_date": null,
	"photos_taken_date":null,
	"keywords": null,
	"comments": null,
	"default_image": null
}

export const setFieldSizeLimits = {
	"buildCost": 10,
	"revampCost": 10
}

export const filePostJson =  {
    "tenantName": "WB Television",
    "tenantId": 1,
    "showName": "",
    "showId": null,
    "uploadLocation": "cad",
    "fileName": "",
    "signedURLType": "",
    "oldFile":""   
}
export const manageLookupType = [
    {value:"lu_category",label:"Category"},
    {value:"lu_condition",label:"Condition"},
    {value:"lu_location",label:"Location"},
    {value:"lu_show",label:"Show"}
    
]

export const manageLookupConfig = {
    headings: [
        {
            headingLabel: "Value",
            icon: "sort-alpha-up"
        },
        {
            headingLabel: "Status",
            icon: ""
        }
    ],
    dataNodes: ["label","check"],
    primaryKey: "value"
}
export const selectOptionsTV = [
    {
        label: "All",
        value: 1,
        searchType : "ALL"
    },
    {
        label: "Shows",
        value: 2,
        searchType : "SHOWS"
    },
    {
        label: "Episodes",
        value: 3,
        searchType : "EPISODES"
    },
    {
        label: "Roles",
        value: 4,
        searchType : "ROLES"
    },
    {
        label: "Talent",
        value: 5,
        searchType : "TALENT"
    },
    {
        label: "Agencies",
        value: 6,
        searchType : "AGENCY"
    },
    {
        label: "Representative",
        value: 7,
        searchType : "REPRESENTATIVES"
    },
    {
        label: "Production Companies",
        value: 8,
        searchType : "PRODUCTION_COMPANIES"
    },
    {
        label: "Crew",
        value: 9,
        searchType : "CREW"
    }
]

export const selectOptionsFeature = [
    {
        label: "All",
        value: 1,
        searchType : "ALL"
    },
    {
        label: "Feature",
        value: 2,
        searchType : "SHOWS"
    },
    {
        label: "Roles",
        value: 3,
        searchType : "ROLES"
    },
    {
        label: "Talent",
        value: 4,
        searchType : "TALENT"
    },
    {
        label: "Agencies",
        value: 5,
        searchType : "AGENCY"
    },
    {
        label: "Representative",
        value: 6,
        searchType : "REPRESENTATIVES"
    },
    {
        label: "Production Companies",
        value: 7,
        searchType : "PRODUCTION_COMPANIES"
    },
    {
        label: "Crew",
        value: 8,
        searchType : "CREW"
    }
]
import React, { Component } from 'react'
import FileUpload from './FileUpload'
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import BasicButton from "../SharedComponents/BasicButton/BasicButton"
import CircularProgress from "@material-ui/core/CircularProgress";
class AddFile extends Component {
    render() {

        return (
            <>
                <MDBRow center>
                    <MDBCol md={11}>
                        <FileUpload
                            accept={this.props?.accept}
                            handleFile={this.props?.handleFile}
                            innerRef={this.props?.innerRef}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className="p-3">
                    <MDBCol md={6}>
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            type="inline"
                            text={this.props?.isSubmit ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                            onClick={() => this.props?.handleSubmit()}
                            disabled={this.props.files?.length > 0 && this.props.files?.some(item => item?.name?.includes("+") || item?.name?.includes("%"))}
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={this.props?.secondaryButtonText}
                            onClick={() => this.props?.onClose()}
                        // disabled={false}
                        />
                    </MDBCol>

                </MDBRow>
            </>
        )
    }
}
export default AddFile 

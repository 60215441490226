import React from 'react';
import jsPDF from 'jspdf'
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBModal,
    MDBContainer, MDBIcon, MDBBtn,
    MDBListGroup, MDBListGroupItem
} from "mdb-react-ui-kit";
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import * as Constants from '../../../constants/constants';
import SetManagerService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { searchResultsConfig, SearchData } from './SearchResultsConfig';
import CircularProgress from "@material-ui/core/CircularProgress";
import SetDetails from './SetDetails/SetDetails';
import { getFormattedDate } from '../../../Common/Helper';
import Modal from '../../SharedComponents/Modal/Modal';
import MessageModal from '../../SharedComponents/MessageModal';
import FileParams from "../../AddFiles/FileParams.json"
import './SearchResults.scss';
import {updateTableSortConfigObject} from "../../../Common/TableHelper";

class SearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setDetails: [],
            setDetailsRevampHistory: [],
            isLoadingSetDetails: false,
            isLoadingSetDetailsRevampHistory: false,
            isSetDetailsSectionOpen: false,
            showRevampSetModal: false,
            showEditSetModal: false,
            showtooltip: false,
            history: false,
            isDeleteSet: false,
            showDeleteSet: false,
            showDeleteFail: false,
            showDeleteSuccess: false,
            exportSetDetails: false,
            indexPos: null,
            fileParams: FileParams,
            catalogFiles: [],
            cadDrawings: [],
            searchResults: [],
            a_z: false,
            historyDetails: null,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.setResults?.data !== prevState.searchResults) {
            return { searchResults: nextProps.setResults?.data }
        } else {
            return null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.setResults?.data !== this.props.setResults?.data) {
            let findSetIndex = this.props?.setResults?.data?.findIndex(item => item?.set_id == this.state?.setDetails[0]?.set_id)
            if (findSetIndex !== -1) {
                this.checkIndexPosition(findSetIndex)
            } else {
                this.setState({
                    setDetails: [],
                    setDetailsRevampHistory: [],
                    isSetDetailsSectionOpen: false,
                    showtooltip: false,
                    history: false,
                    isDeleteSet: false,
                    showDeleteSet: false,
                    indexPos: null,
                    catalogFiles: [],
                    cadDrawings: [],
                    historyDetails: null,
                })
            }
            this.setState({ a_z: false }, () => {
                this.sortSetName('Set Name')
            })
        }
    }


    getCatalogFiles(setId, idSuffix) {
        var listFileParams = { ...this.state.fileParams };
        listFileParams.setId = setId
        listFileParams.IDSuffix = idSuffix
        listFileParams.uploadLocation = "images"
        listFileParams.tenantId = this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName = this.props?.userContext?.active_tenant?.tenant_name
        SetManagerService.getDataParams(Constants.setManagerServiceBaseUrl + '/getFiles',
            listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("List Prod Files:" + response.data);
                //this.setState({ statusDatesData: response.data });
                var files = [];
                var listFilesResp = response?.data;
                var filesList = listFilesResp?.Contents;
                filesList?.map((file) => {
                    files.push(
                        {
                            "name": file.Key.replace(listFilesResp.Prefix + "/", ""),
                            "path": listFilesResp.Prefix,
                            "url": file.url,
                            "size": file?.Size,
                            "order": file?.order,
                        });
                });
                files?.sort((a,b) => a.order-b.order)
                this.setState({
                    catalogFiles: files
                });
            }, (err) => {
                console.log("from get cad files", err)
            });
    }

    getCadFiles(set_id, idSuffix) {
        var listFileParams = { ...this.state.fileParams };
        listFileParams.setId = set_id
        listFileParams.IDSuffix = idSuffix
        listFileParams.tenantId = this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName = this.props?.userContext?.active_tenant?.tenant_name
        this.setState({ isLoading: true });
        SetManagerService.getDataParams(Constants.setManagerServiceBaseUrl + '/getFiles',
            listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("List Prod Files:" + response.data);
                //this.setState({ statusDatesData: response.data });
                var files = [];
                var listFilesResp = response?.data;
                var filesList = listFilesResp?.Contents;
                filesList?.map((file) => {
                    files.push(
                        {
                            "name": file.Key.replace(listFilesResp.Prefix + "/", ""),
                            "path": listFilesResp.Prefix,
                            "url": file.url,
                            "size": file?.Size
                        });
                });
                this.setState({
                    cadDrawings: files
                });
            }, (err) => {
                console.log("from get cad files", err)
            });
    }

    fetchSetDetails = (setId, hideLoading = false, openSetAFterRevamp = false) => {
        if (!hideLoading) this.setState({ isLoadingSetDetails: true });
        SetManagerService.getData(Constants.setManagerServiceBaseUrl + '/getSetData?setId=' + setId, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ isLoadingSetDetails: false, setDetails: response?.data }, () => {
                    this.checkIndexPosition(null);
                    this.getCatalogFiles(this.state.setDetails[0]?.set_id, this.state.setDetails[0]?.id_suffix);
                    this.getCadFiles(this.state.setDetails[0]?.set_id, this.state.setDetails[0]?.id_suffix)
                    if(openSetAFterRevamp) {
                        this.toggleEditSetVisibility()
                    }
                });
            },
                (err) => {
                    console.log("Error in fetching set Details:", err)
                    this.setState({ isLoadingSetDetails: false });
                })
    }
    fetchSetDetailsRevampHistory = (setId) => {
        this.setState({ isLoadingSetDetailsRevampHistory: true });
        SetManagerService.getData(Constants.setManagerServiceBaseUrl + '/getRevampHistoryAll?setId=' + setId, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ isLoadingSetDetailsRevampHistory: false, setDetailsRevampHistory: response?.data });
            },
                (err) => {
                    console.log("Error in fetching set Details:", err)
                    this.setState({ isLoadingSetDetailsRevampHistory: false });
                })
    }

    checkIndexPosition = (index) => {
        if (index || index == 0) {
            if ((index + 1) === (parseInt(this.state.searchResults.length) - 1)) {
                this.setState({ indexPos: 'last' });
            } else if ((index - 1) === 0) {
                this.setState({ indexPos: 'first' });
            } else {
                this.setState({ indexPos: null });
            }
        } else if (parseInt(this.state.searchResults.length) == 1) {
            this.setState({ indexPos: 'comb' });
        } else if (this.state.searchResults[0]?.set_id == this.state.setDetails[0]?.set_id) {
            this.setState({ indexPos: 'first' });
        } else if (this.state.searchResults[(parseInt(this.state.searchResults.length) - 1)]?.set_id == this.state.setDetails[0]?.set_id) {
            this.setState({ indexPos: 'last' });
        } else {
            this.setState({ indexPos: null });
        }
    }

    getNextSet = (set) => {
        if (this.state.indexPos !== 'last' && this.state.indexPos !== 'comb') {
            let index = this.state.searchResults?.findIndex(list => list.set_id == set.set_id);
            if (index != (parseInt(this.state.searchResults?.length) - 1)) {
                this.setState({ setDetails: [this.state.searchResults[index + 1]], indexPos: null }, () => {
                    this.fetchSetDetails(this.state.setDetails[0]?.set_id)
                    this.fetchSetDetailsRevampHistory(this.state.setDetails[0]?.set_id)
                });
            }
            this.checkIndexPosition(index);
        }
    }

    getPreviousSet = (set) => {
        if (this.state.indexPos !== 'first' && this.state.indexPos !== 'comb') {
            let index = this.state.searchResults?.findIndex(list => list.set_id == set.set_id);
            if (index != 0) {
                this.setState({ setDetails: [this.state.searchResults[index - 1]], indexPos: null }, () => {
                    this.fetchSetDetails(this.state.setDetails[0]?.set_id)
                    this.fetchSetDetailsRevampHistory(this.state.setDetails[0]?.set_id)
                });
            }
            this.checkIndexPosition(index);
        }
    }

    handleSetSelection = (obj, node) => {
        this.setState({ isSetDetailsSectionOpen: true, history: false, historyDetails: null });
        this.fetchSetDetails(obj?.set_id)
        this.fetchSetDetailsRevampHistory(obj?.set_id)
    }

    handleChangePage = (event, newPage) => {
        this.setState({ pageNo: newPage + 1 }, () => {
            this.fetchAllSet()
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, pageNo: 1 }, () => {
            this.fetchAllSet();
        });
    }

    toggleRevampSetVisibility = () => {
        this.setState({ showRevampSetModal: !this.state.showRevampSetModal });
    }

    toggleEditSetVisibility = () => {
        this.setState({ showEditSetModal: !this.state.showEditSetModal });
    }

    toggleHisorty = (historyDetails) => {
        this.setState({ history: true, showtooltip: false, historyDetails: historyDetails });
    }

    confirmDelete = () => {
        if (this.state.showDeleteSet) {
            this.deleteSet(this.state?.setDetails[0]?.set_id, this.state?.setDetails[0]?.id_suffix)
        } else if (this.state.showDeleteSuccess) {
            this.setState({ showDeleteSuccess: false, showtooltip: false }, this.props?.fetchAfterDelete)
        } else {
            this.setState({ showDeleteFail: false })
        }
    }
    deleteSet = (setId, idSuffix) => {
        this.setState({ isDeleteSet: true, showDeleteSet: false });
        let deleteJson = {
            "setId": setId,
            //"setName": this.state?.setDetails[0]?.set_name,
            "tenantName": "WB Television",
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "IDSuffix": idSuffix,
            // "showName": this.state?.setDetails[0]?.show_name,
            // "showId": this.state?.setDetails[0]?.show_id,
            "uploadLocation": ""
        }
        SetManagerService.deleteDataParams(Constants.setManagerServiceBaseUrl + '/deleteSet', deleteJson, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log(response.data.message)
                    this.setState({ showDeleteFail: true })
                } else {
                    console.log('success')
                    this.setState({ setDetails: [], setDetailsRevampHistory: [], isSetDetailsSectionOpen: false, showDeleteSuccess: true })
                }
                this.setState({ isDeleteSet: false });
            },
                (err) => {
                    console.log("Error in fetching set Details:", err)
                    this.setState({ isDeleteSet: false, showDeleteFail: true });
                })
    }


    generateSetDetails = () => {
        let setDetails = this.state.history ? this.state.historyDetails : this.state.setDetails[0]
        this.setState({ exportSetDetails: true })
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.setFontSize(10)
        doc.setTextColor(0, 39, 69)
        doc.text(50, 20, 'Set Name')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 40, setDetails?.set_name || "-",{ maxWidth: 200 })
        doc.setTextColor(0, 39, 69)
        doc.text(300, 20, 'ID')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 40, setDetails?.id_prefix + " - " + setDetails?.id_suffix || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(50, 70, 'Show')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 90, setDetails?.show_name || "-",{ maxWidth: 200 })
        doc.setTextColor(0, 39, 69)
        doc.text(300, 70, 'Location')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 90, setDetails?.location_name || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(50, 120, 'Category')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 140, setDetails?.category_name || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(300, 120, 'Open')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 140, setDetails?.is_open == 1 ? 'Yes' : 'No')
        doc.setTextColor(0, 39, 69)
        doc.text(430, 120, 'Condition')
        doc.setTextColor(0, 0, 0)
        doc.text(430, 140, setDetails?.condition_name || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(50, 170, 'Cost To Build')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 190, setDetails?.build_cost ? "$" + setDetails?.build_cost : "-")
        doc.setTextColor(0, 39, 69)
        doc.text(170, 170, 'Revamp Cost')
        doc.setTextColor(0, 0, 0)
        doc.text(170, 190, setDetails?.revamp_cost ? "$" + setDetails?.revamp_cost : "-")
        doc.setTextColor(0, 39, 69)
        doc.text(300, 170, 'Last Show Used Set')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 190, setDetails?.last_show_used || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(50, 220, 'First Build Date')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 240, getFormattedDate(setDetails?.first_build_date) || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(170, 220, 'Strike Date')
        doc.setTextColor(0, 0, 0)
        doc.text(170, 240, getFormattedDate(setDetails?.strike_date) || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(300, 220, 'Date of Last Use')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 240, getFormattedDate(setDetails?.last_used_date) || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(430, 220, 'Date of Photos Taken')
        doc.setTextColor(0, 0, 0)
        doc.text(430, 240, getFormattedDate(setDetails?.photos_taken_date) || "-")
        doc.setTextColor(0, 39, 69)
        doc.text(50, 270, 'Keywords')
        doc.setTextColor(0, 0, 0)
        doc.text(50, 290, setDetails?.keywords || "-",{ maxWidth: 200 })
        doc.setTextColor(0, 39, 69)
        doc.text(300, 270, 'Comments')
        doc.setTextColor(0, 0, 0)
        doc.text(300, 290, setDetails?.comments || "-",{ maxWidth: 200 })
        let commentsORKeyWordSLength = setDetails?.keywords?.split("\n").length > setDetails?.comments?.split("\n").length ?
                                       setDetails?.keywords?.split("\n").length : setDetails?.comments?.split("\n").length ;
        if (this.state?.catalogFiles.length > 0) {
            var catalogName = `${setDetails?.set_name}_${setDetails?.id_prefix}_${setDetails?.id_suffix}.pdf`
            var allFiles = [...this.state?.catalogFiles]
            var self = this
            buttonClick()
            let y = 350 + (commentsORKeyWordSLength  ?  commentsORKeyWordSLength * 15 : 0)
            function addImageProcess(url) {
                return new Promise(resolve => {
                    var image = new Image();
                    image.setAttribute('crossOrigin', 'anonymous'); 

                    image.onload = function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = this.naturalWidth;
                        canvas.height = this.naturalHeight;

                        var ctx = canvas.getContext('2d');
                        ctx.fillStyle = '#fff';  
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        canvas.getContext('2d').drawImage(this, 0, 0);

                        resolve(canvas.toDataURL('image/jpeg'));
                    };

                    image.src = url+`&${new Date().getTime()}`;
                })
            }

            async function generatePdf(imageUrls) {
                for (const [i, url] of imageUrls.entries()) {
                    const image = await addImageProcess(url);
                    let x = 0
                    if (doc.internal.getCurrentPageInfo().pageNumber == 1) {
                        if (i % 2 == 0 && y + 200 > 700) {
                            doc.addPage()
                            y = 20
                            x = 10
                        } else {
                            if (i % 2 == 0) {
                                y = i == 0 ? y: y + 200
                                x = 10
                            } else {
                                x = 300
                            }
                        }
                    } else {
                        if (i % 2 == 0 && y + 200 > 700) {
                            doc.addPage()
                            y = 20
                            x = 10
                        } else {
                            if (i % 2 == 0) {
                                y = y + 200
                                x = 10
                            } else {
                                x = 300
                            }
                        }
                    }
                    doc.addImage(image, 'PNG', x, y, 280, 180)
                    if(i === imageUrls.length-1 ) {
                        self.setState({exportSetDetails : false })
                    }
                }
                return doc;
            }

            function buttonClick() {
                savePdf().then(console.log("Saved"));
            }

            async function savePdf() {
                const multiPng = await generatePdf(allFiles?.map(item => item?.url));

                const dataURLString = multiPng.output("dataurlstring", catalogName);

                multiPng.output("save", catalogName.replaceAll('"',''));
            }
        } else {
            doc.save(`${setDetails?.set_name}_${setDetails?.id_prefix}_${setDetails?.id_suffix}.pdf`.replaceAll('"',''))
            this.setState({ exportSetDetails: false })
        }
    }
    
    sortSetName = (val) => {
        if (val == 'Set Name') {
            let sortList = this.state?.searchResults
            if (this.state.a_z) {
                sortList.reverse()
                if(searchResultsConfig?.headings[0]?.icon == "sort-alpha-down") {
                    searchResultsConfig.headings[0].icon = "sort-alpha-up"
                } else {
                    searchResultsConfig.headings[0].icon = "sort-alpha-down"
                }
            } else {
                sortList.sort((a, b) => a.set_name.localeCompare(b.set_name))
                this.setState({ a_z: true })
                searchResultsConfig.headings[0].icon = "sort-alpha-down"
            }
            this.setState({ searchResults: sortList })
        }
    }

    afterRevampSet = (setId) => {
        this.fetchSetDetailsRevampHistory(setId)
    }

    handleSetAfterRevamp = () => {
        this.fetchSetDetails(this.state.setDetails[0]?.set_id,false, true)
    }

    setEditCallback = (setid, hideLoading) => {
        this.props?.userContext?.fetchsetList(this.props?.userContext?.active_tenant?.tenant_id)
        this.fetchSetDetails(setid, hideLoading)
    }

    loadParentSet = () => {
        this.setState({history:false},() => {
            this.getCadFiles(this.state.setDetails[0]?.set_id,this.state.setDetails[0]?.id_suffix)
            this.getCatalogFiles(this.state.setDetails[0]?.set_id,this.state.setDetails[0]?.id_suffix)
        })
    }

    render() {
        let message = this.state.showDeleteSet ? "Are you sure you want to delete the set? The action cannot be undone" :
            this.state.showDeleteSuccess ? "Set deleted" : "Set deletion failed"
        return (
            <div className="search-result">
                {this.state.showRevampSetModal && <Modal open={this.state.showRevampSetModal || false}
                    modalName={'RevampSet'} onClose={this.toggleRevampSetVisibility}
                    setId={this.state?.setDetails[0]?.set_id}
                    idSuffix={this.state?.setDetails[0]?.id_suffix}
                    callback={this.afterRevampSet.bind(this)}
                    catalogFiles={this.state.catalogFiles}
                    handleSubmit={this.handleSetAfterRevamp}
                    cadDrawings={this.state.cadDrawings}
                    comfirmModalTitle={'Revamp Set'}
                />}
                <MessageModal
                    open={this.state.showDeleteSet || this.state.showDeleteSuccess || this.state.showDeleteFail || false}
                    title={"Delete Set"}
                    showCustom={true}
                    message={message}
                    primaryButtonText={"Confirm"}
                    secondaryButtonText={"Cancel"}
                    hideCancel={this.state.showDeleteSuccess || this.state.showDeleteFail || false}
                    onConfirm={this.confirmDelete}
                    handleClose={(e) => this.setState({ showDeleteSet: false, showtooltip: false })}
                />
                <MDBRow>
                    <MDBCol md={11}>
                        <span className="headingLable">Search Results</span>
                    </MDBCol>
                    <MDBCol md={1}>
                        <span className='results'>{`${this.props.setResults?.total_count || 0} Results`}</span>
                    </MDBCol>
                </MDBRow>
                <div class='row'>
                    <div class='col-xs-12' id='tblSearchResults'>
                        <TableComponent
                            selectedRowId={this.state?.setDetails[0]?.set_id}
                            list={this.state?.searchResults || []}
                            config={searchResultsConfig}
                            tableMode={"light"}
                            isLoading={this.props.isLoadingSets || false}
                            sortCallback={this.sortSetName}
                            hyperLinkNavigationCallback={this.handleSetSelection}
                        />
                        <TablePagination
                            component="div"
                            count={this.props.setResults?.total_count || 0}
                            page={this.props.pageNo - 1}
                            onChangePage={this.props.handleChangePage}
                            rowsPerPage={this.props.rowsPerPage}
                            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                {
                    this.state.isLoadingSetDetails ? <div className="setDetailsLoader"> <CircularProgress /> </div> :
                        this.state.isSetDetailsSectionOpen && (
                            <>
                                {this.state.showEditSetModal && <Modal open={this.state.showEditSetModal || false}
                                    modalName={'NewSet'} comfirmModalTitle={'Edit Set'}
                                    callback={this.setEditCallback.bind(this)}
                                    list={this.state.setDetails[0] || {}}
                                    onClose={this.toggleEditSetVisibility} />}
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <span className="headingLable">{this.state.setDetails[0]?.set_name || ""}</span>
                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <MDBRow>
                                            <MDBCol md={2}>
                                                <MDBIcon fas size='2x' icon="ellipsis-h" className="mt-1" onClick={() => { this.state?.history ? null : this.setState({ showtooltip: !this.state.showtooltip }) }} />
                                            </MDBCol>
                                            <MDBCol md={10}>
                                                <MDBListGroup className={this.state.showtooltip && this.props?.userContext?.active_tenant?.permissions?.isModifySet ? "deleteShow" : "deleteHide"}>
                                                    {this.state?.isDeleteSet  ?
                                                        <CircularProgress color="inherit" size={20} /> :
                                                        <MDBListGroupItem onClick={() => this.setState({ showDeleteSet: true })}>Delete Set</MDBListGroupItem>
                                                    }
                                                </MDBListGroup>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                    <MDBCol md={2} className={this.state?.history ? "historicalShow" : "historicalHide"}>
                                        <span className="historical">Historical Version</span>
                                    </MDBCol>                                    
                                    <MDBCol md={2}>
                                        <BasicButton
                                            className="FR MR1"
                                            text={this.state?.exportSetDetails ? <CircularProgress color="inherit" size={10} /> : "Set Details"}
                                            variant="outlined"
                                            icon={"file-pdf"}
                                            onClick={this.generateSetDetails.bind(this)} />
                                    </MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            className="FR MR1"
                                            text={"Revamp Set"}
                                            disabled={this.state?.history}
                                            onClick={() => this.toggleRevampSetVisibility()}
                                            icon={"sync"} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-2 mb-2">
                                    <MDBCol md={6} className="underlineLabel">
                                        <BasicLabel text="Details" />
                                    </MDBCol>
                                    <MDBCol md={1}>
                                        <BasicButton
                                            className="FR MR1"
                                            text={"Edit"}
                                            onClick={() => this.toggleEditSetVisibility()}
                                            variant="outlined"
                                            disabled={this.state?.history}
                                            icon={"pen"} />
                                    </MDBCol>
                                    <MDBCol md={3}></MDBCol>
                                    {this.state?.history && <MDBCol md={2}>
                                        <BasicButton
                                            className="FR MR1"
                                            text={"Go to Parent"}
                                            onClick={() => this.loadParentSet()}
                                            variant="outlined"
                                            />
                                    </MDBCol>}
                                </MDBRow>
                                <SetDetails
                                    setDetails={this.state.setDetails[0] || {}}
                                    setDetailsRevampHistory={this.state.setDetailsRevampHistory}
                                    getNextSet={this.getNextSet}
                                    getPreviousSet={this.getPreviousSet}
                                    toggleHisorty={this.toggleHisorty.bind(this)}
                                    fetchSetDetails={this.fetchSetDetails.bind(this)}
                                    indexPos={this.state.indexPos}
                                    history={this.state.history}
                                    catalogFiles={this.state.catalogFiles}
                                    getCatalogFiles={this.getCatalogFiles.bind(this)}
                                    cadDrawings={this.state.cadDrawings}
                                    getCadFiles={this.getCadFiles.bind(this)}
                                />

                            </>
                        )
                }
            </div>
        )
    }
}

export default withUserContext(SearchResults);
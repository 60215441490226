import React from 'react';
import BasicTextField from './BasicTextField/BasicTextField';
import './shared.scss';

export default class TextSeparatedField extends React.Component {
    render() {
        return (
            <div className="TextSeparatedFieldContainer">
                <BasicTextField id={this.props?.id}
                    value={this.props?.value1 ? this.props?.value1 : ""}
                    onChange={this.props?.onChangeOfValue1}
                    disabled={this.props?.isField1Disabled ? this.props?.isField1Disabled : false}
                    placeholder={this.props?.placeholder1 ? this.props?.placeholder1 : ""} />
                <label>{this.props?.text ? this.props?.text : ""}</label>
                <BasicTextField id={this.props?.id + this.props?.id}
                    value={this.props?.value2 ? this.props?.value2 : ""}
                    onChange={this.props?.onChangeOfValue2}
                    disabled={this.props?.isField2Disabled ? this.props?.isField2Disabled : false}
                    placeholder={this.props?.placeholder2 ? this.props?.placeholder2 : ""} />
            </div>
        );
    }
}
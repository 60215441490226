import React from 'react';
import DateField from '../SharedComponents/DateField/DateField';
import jsPDF from 'jspdf';
import { getFormattedDate } from '../../Common/Helper';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import CommonCatalog from '../CommonCatalog/CommonCatalog';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import SetManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import FileParams from "../AddFiles/FileParams.json"
import Messages from "../../Common/Messages.json"
import MessageModal from '../SharedComponents/MessageModal';
import { validateEmail } from '../../Common/Helper';
import CircularProgress from "@material-ui/core/CircularProgress";
// import SearchSelectField from '../SharedComponents/SearchSelectField/SearchSelectField';
import './EmailCatalog.scss'
import { withUserContext } from '../../contexts/UserContext';

class EmailCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fileParams: FileParams,
            cadDrawings: [],
            postInitiated:false,
            postJson: {
                "emailTo": null,
                "emailCc":  null,
                "message": null,
                "subject": null,
                "isImages": false,
                "images": [],
                "isCad": false,
                "cads": [],
                "tenantName": "WB Television",
                "tenantId": 1,
                "setName": null,
                "setId": null,
                "uploadLocation": null,
                "fileName": "",
                "signedURLType": null
            },
            showImageMandatory:false,
            emailinValidInputTo:false,
            emailinValidInputCc:false,
            showError: false,
            showSuccess: false,
            showFail: false,            
            showCustom:false,  
            customMsg:"",

        }
        this.catalogRef = React.createRef();
    }

    componentDidMount() {
        let userMail = this.props?.userContext?.user_profile?.email;
        if(userMail){
            this.handleChange('emailCc',userMail)
        }
        this.handleChange('subject',`WB Scenery - ${this.props?.setDetails?.set_name} ${this.props?.setDetails?.id_prefix}-${this.props?.setDetails?.id_suffix}`)
    }
    handleChange=(field,value)=>{
        this.setState(prevState=>({
            postJson:{
                ...prevState.postJson,
                [field]:value
            }
        })) 
        this.setState({showSizeError:false})
        if(this.state.postInitiated && this.catalogRef.current?.getAllSelected().length == 0 && this.props?.catalogFiles.length > 0){
            this.setState({showImageMandatory:true})
        }
        else{
            this.setState({showImageMandatory:false})
        }
    }

    checkDuplicateEmails = (toList, ccList) => {
        let allToEmails = toList ? toList.split(";") : []
        let allCcEmails = ccList ? ccList.split(";") : []
        let allEmails = [...allToEmails,...allCcEmails]
        const distincitEmails = allEmails.filter((ele, index, allEmails) =>
                index === allEmails.findIndex((item) => (
                    item === ele
                )))
        return allEmails.length === distincitEmails.length
    }
    
    validateEmailFields(postJson){
        let allValid = true;
       
        postJson.emailTo.forEach(element => {
            if(!validateEmail(element)){
                allValid = false;
            }
        })
        let allValidCc= true;
       
        postJson.emailCc.forEach(element => {
            if(!validateEmail(element)){
                allValidCc = false;
            }
        })
        if(!allValid){
            this.setState({emailinValidInputTo:true})
        }
        if(!allValidCc){
            this.setState({emailinValidInputCc:true})
        }
        if(this.catalogRef.current?.getAllSelected().length == 0 && this.props?.catalogFiles.length > 0){
            this.setState({showImageMandatory:true})
        }
        else{
            this.setState({showImageMandatory:false})
        }
       return  (postJson.IDSuffix && postJson.emailTo?.length > 0 && allValid && allValidCc && (this.props?.catalogFiles.length > 0 ? this.catalogRef.current?.getAllSelected().length > 0 : true ) && postJson.message && postJson?.message?.length <= 500)
    }
    sendEmail(fileName){
        let postJson = {...this.state.postJson}
        postJson.setId = this.props?.setId;
        postJson.IDSuffix = this.props?.idSuffix;
        postJson.isCad = +this.state?.postJson?.isCad       
        postJson.emailTo = this.state?.postJson?.emailTo ? this.state?.postJson?.emailTo?.split(";"):[];
        postJson.emailCc = this.state?.postJson?.emailCc ? this.state?.postJson?.emailCc?.split(";") :[];
        postJson.cads = this.state?.postJson?.isCad ? this.props?.cadDrawings?.map(item => item?.name) : [];
        postJson.images = [fileName];
        postJson.isImages = fileName ? 1 : 0;
        postJson.userName = this.props?.userContext?.user_profile?.user_name
        this.setState({postInitiated:true,emailinValidInputTo:false,emailinValidInputCc:false})
        let isValidFields = this.validateEmailFields(postJson) && this.checkDuplicateEmails(this.state.postJson?.emailTo, this.state.postJson?.emailCc)
        if(!isValidFields){
            this.setState({isLoading:false})
            return
        }
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/sendEmail',postJson,this.props?.userContext?.active_tenant?.tenant_id)
        .then(response=>{
            if(response?.data?.error){
                this.setState({showFail:true,isLoading:false})
                return;
            }
            this.setState({
                            showCustom:true,
                            showSuccess:true,
                            customMsg:response?.data?.message ? response?.data?.message: "Email Sent Successfully" ,
                            isLoading:false,
                            postInitiated:false
                        })
        },(err)=>{    
            console.log("Error from Send Email",err)    
            this.setState({showFail:true,isLoading:false})    
        });
    }
    messagePopupConfirm(){
        if(this.state.showSuccess){
           this.props.onClose();            
        }
        this.setState({ showError: false,showSuccess: false, showFail: false,showCustom:false })
    }

    exportCatalog = () => {
        let getAllSelectedImges = this.catalogRef.current?.getAllSelected();
        this.setState({ isLoading: true, showSizeError:false })
                // page width - 594 page height - 459 in px
        // getTextWidth gives 950 the width of text which covers the whole 594 px width of page
        // So for aligning puprpose ) 0.62 factor is used at the plcae where getTextWidth is used
        var doc = new jsPDF('l', 'px', 'letter')
        doc.addFont('Times-Roman');
        doc.setFont('Times-Roman');
        var ids = (this.props?.setDetails?.id_prefix || this.props?.setDetails?.id_suffix) ?
        ((this.props?.setDetails?.id_prefix ? 
         this.props?.setDetails?.id_prefix : '-') 
        +' '+
        (this.props?.setDetails?.id_suffix ? this.props?.setDetails?.id_suffix : '-' )) : "-"
        var photoDateLastShow = (this.props?.setDetails?.last_show_used || this.props?.setDetails?.last_used_date) ?
        ((this.props?.setDetails?.last_show_used ? 
         this.props?.setDetails?.last_show_used : '-') 
        +' '+
        (this.props?.setDetails?.last_used_date ? getFormattedDate(this.props?.setDetails?.last_used_date) : '-' ))
        : "-"
        var showNameWidth = this.props?.setDetails?.show_name ? Math.ceil(doc.getTextWidth(this.props?.setDetails?.show_name)*0.62) : 0
        var setNameWidth = this.props?.setDetails?.set_name ? Math.ceil(doc.getTextWidth(this.props?.setDetails?.set_name)*0.62) : 0
        var idWidth = ids ? Math.ceil(doc.getTextWidth(ids))*0.62 : 0
        var photoDateLastShowWidth = Math.ceil(doc.getTextWidth(photoDateLastShow)*0.62) 
        var y = 0
        var self = this
        async function header  () {
            doc.setFontSize(10)
            doc.setTextColor(0, 0, 0)
            doc.setFont('Times-Roman', 'normal')
            doc.text(10, 20, 'Comments')
            doc.text(10, 30, self.props?.setDetails?.comments?.replaceAll('\n',' ') || "-",{ maxWidth: 180 })
            doc.setFont('Times-Roman', 'bold')
            doc.text(300 - showNameWidth/2, 20, self.props?.setDetails?.show_name || "-")
            doc.text(300 - setNameWidth/2, 30, self.props?.setDetails?.set_name || "-")
            doc.text(300 - idWidth/2, 40, ids || "-")
            doc.setFont('Times-Roman', 'normal')
            doc.text(584-photoDateLastShowWidth, 20, photoDateLastShow)
            return 60
        }

        if (getAllSelectedImges.length > 0) {
            try {
            var catalogName = `${self.props?.setDetails?.set_name}_${self.props?.setDetails?.id_prefix}_${self.props?.setDetails?.id_suffix}_Catalog.pdf`
            buttonClick()
            function getDataUri(url) {
                return new Promise(resolve => {
                    var image = new Image();
                    image.setAttribute('crossOrigin', 'anonymous'); 

                    image.onload = function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = this.naturalWidth;
                        canvas.height = this.naturalHeight;

                        var ctx = canvas.getContext('2d');
                        ctx.fillStyle = '#fff';  
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        canvas.getContext('2d').drawImage(this, 0, 0);

                        resolve(canvas.toDataURL('image/jpeg'));
                    };

                    image.src = url+`&${new Date().getTime()}`;
                })
            }
            async function generatePdf(imageUrls) {
                for (const [i, url] of imageUrls.entries()) {
                    const image = await getDataUri(url);
                    let x = 0
                    if(i % 4 === 0) {
                        if(i !== 0) {
                            doc.addPage()
                        }
                        y = await header()
                    }
                    if(i % 2 === 0) {
                        x = 10
                        y = i % 4 === 0 ? y : y + 195
                    } else {
                        x = 300
                    }
                    doc.addImage(image, 'PNG', x, y, 285, 190)
                }
                return doc;
            }

            function buttonClick() {
              savePdf().then(console.log('Email sent'));
            }

            async function savePdf() {
                const multiPng = await generatePdf(getAllSelectedImges?.map(item => item?.url));

                const dataURLString = multiPng.output("dataurlstring", catalogName.replaceAll('"',''));
                self.uploadFile(multiPng.output('blob'), `${catalogName}`.replaceAll('"',''))
            }         
        } catch (error) {
                alert(error);
        }
        } else {
            this.setState({showImageMandatory:true})
        }
    }

    uploadFile(blobFile, fileName){
        let cadFilesSize = this.state?.postJson?.isCad ? this.props?.cadDrawings?.map(item => item?.size )?.reduce((a, b) => a + b, 0) : 0
        if(cadFilesSize + blobFile?.size > 22500000 ) {
            this.setState({showSizeError : true, isLoading:false})
            return
        }
        var listFileParams =  {
            "tenantName": "WB Television",
            "tenantId": 1,
            "IDSuffix": "",
            "isDeleteAll": 0,
            "setId": null,
            "uploadLocation": "attachment",
            "fileName": "",
            "signedURLType": "",
            "oldFile":""   
        };
        listFileParams.setId = this.props?.setId
        listFileParams.IDSuffix = this.props?.idSuffix
        listFileParams.fileName = fileName;
        listFileParams.signedURLType = "upload"
        listFileParams.uploadLocation ="attachment"
        listFileParams.tenantId =  this.props?.userContext?.active_tenant?.tenant_id
        listFileParams.tenantName =  this.props?.userContext?.active_tenant?.tenant_name
        listFileParams.files = [fileName]

        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl +'/uploadFile', listFileParams, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => { 
                if(response.data.error){
                    this.setState({showFail:true, isLoading:false})
                    return;
                } 
                response?.data?.data?.map((item ,index)=> {
                    SetManagerService.uploadFile(item, blobFile).then(response => {
                        if(response.data.error){
                            this.setState({showFail:true, isLoading:false})
                            return;
                        }
                        this.sendEmail(fileName)                     
                    }, (err) => {
                        this.setState({showFail:true,isLoading:false})
                        return;
                    });
                  })           
                }, (err) => {
                    this.setState({showFail:true,isLoading:false})
                });
    }

    render() {
        let {postJson}= this.state;      
        let comfirmModalTitle = Messages.defaultTitle;
        let message = this.state.showCustom ?  this.state.customMsg : Messages.defaultMessage;
        return (
            <MDBContainer fluid className='EmailCatalogContainer'>
                 <MessageModal
                open={this.state.showCustom || this.state.showError || this.state.showFail || this.state.showSuccess || false}
                title={comfirmModalTitle}
                showError={this.state.showError || false}
                showFail={this.state.showFail || false}
                showSuccess={this.state.showSuccess || false}                
                message={message}
                showCustom={this.state.showCustom || false}
                hideCancel={this.state.showFail || this.state.showSuccess || false}
                primaryButtonText={"Ok"}
                secondaryButtonText={"Cancel"}  
                onConfirm={() => this.messagePopupConfirm()}
                handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false,showCustom:false,customMsg:''})}
                 
                   />
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicLabel text={"From"} />
                        <span className="userText">                            
                            {this.props?.userContext?.user_profile?.defaul_user_email}
                        </span>
                    </MDBCol>
                    <MDBCol md={8}>
                        <BasicTextField
                            id="subject"
                            label="Subject"
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            value={postJson?.subject}
                            onChange={(e)=>this.handleChange('subject',e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <BasicTextArea
                            label={"To"}
                            rows={1}
                            id={"toEmail"}
                            value={postJson?.emailTo}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            inValidInput={"Provide valid email"}
                            fieldValid={this.state.emailinValidInputTo}
                            onChange={(e)=>this.handleChange('emailTo',e.target.value)}
                        />
                        <span className='infoText'>You can add multiple email by ';' separated</span>
                    </MDBCol>
                    <MDBCol>
                        <BasicTextArea
                            label={"Cc"}
                            rows={1}
                            id={"cc"}
                            value={postJson?.emailCc}
                            inValidInput={"Provide valid email"}
                            fieldValid={this.state.emailinValidInputCc}
                            onChange={(e)=>this.handleChange('emailCc',e.target.value)}
                        />
                        <span className='infoText'>You can add multiple email by ';' separated</span>

                    </MDBCol>
                </MDBRow>
                <span className="errorText">{this.checkDuplicateEmails(this.state.postJson?.emailTo, this.state.postJson?.emailCc) ? null : "Please remove duplicate emails"}</span>
                <MDBRow>
                    <BasicTextArea
                        label={"Message"}
                        rows={1}
                        id={"message"}
                        isMandatory={true}
                        showMandatory={this.state.postInitiated || false}
                        value={postJson?.message}
                        onChange={(e)=>this.handleChange('message',e.target.value)}
                    />
                    {postJson?.message?.length > 500 &&<span className='errorText'>Exceeded 500 characters limit</span>}
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <BasicCheckbox
                            label="Attach CAD / Drawing File"
                            id="cadCheck"
                            checked={postJson?.isCad}
                            onChange={(e)=>this.handleChange('isCad',e.target.checked)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <CommonCatalog
                        key={"emailCatalog"}
                        id={"emailCatalog"}
                        showId={this.props?.showId}
                        showName={this.props?.showName}
                        setDetails={this.props?.setDetails}
                        showCheckbox={this.props?.showCheckbox}
                        catalogRef={this.catalogRef}
                        catalogFiles={this.props?.catalogFiles}
                        catalogType="email"
                        uploadType="images"
                        showSelectMandatory={this.state?.showImageMandatory || false}
                    />
                </MDBRow>
                <MDBRow className="p-2">
                    <MDBCol md={8} >
                        <span className="errorText">{this.state.showSizeError && "File size is exceeding email attachment limit, please unselect some item and try"}</span>
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            variant="contained"                          
                            type="inline"
                            text={this.state?.isLoading ? <CircularProgress color="inherit" size={15} /> : "Send"}
                            disabled={ this.state.postJson?.isCad ? this.props?.cadDrawings.length == 0 : this.props?.catalogFiles.length == 0 }
                            onClick={this.exportCatalog.bind(this)}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={this.props.onClose}
                            disabled={false}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(EmailCatalog);

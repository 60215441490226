import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import React from "react";
import {manageLookupType,manageLookupConfig} from './ManageLookupConfig'
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import InlineButtonField from '../../components/SharedComponents/InlineButtonField/InlineButtonField';
import TableComponent from '../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import SetManagerService from '../../services/service';
import * as Constants from '../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import { withUserContext } from '../../contexts/UserContext';
import './ManageLookup.scss'

class ManageLookup extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selectedType: "",
            tableData: [],
            config: manageLookupConfig,
            showContent: false,
            inlineButtonField: "",
            setIndex: 0,
            sortCount: 1,
            sortBy: "Value",
            isSortByAsc: true,
            formEdited: false,
            rowsPerPage: 5,
            page: 0,
            lookupType:[]
        }
    }


    componentDidMount() {
        this.setState({lookupType:manageLookupType})
    }

    fetchDetails = (val) => {
        this.setState({ selectedType: val });
        SetManagerService.getData(Constants.setManagerServiceBaseUrl + '/staticData?staticTable=' + val,this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, check: item.is_active === 1 ? true : false }));
                this.setState({ sortCount:1, tableData: formattedList, showContent: true, inlineButtonField: "", page: 0, setIndex:0 });
                //this.setState({ renderList: this.state.tableData });
                this.handleTableColumnClick('Value')
            },
                (err) => {
                    this.setState({ selectedType: val });
                    console.log("Error in fetching Market Types:", err)
                })
    }

    arrayCheckUncheck = (event, dataItem) => {
        console.log(event);
        console.log(dataItem);
        let details = this.state.tableData;
        details.map(item => {
            if (item.value === dataItem.value) {
                item.check = dataItem.check;
            }
        });
        this.setState({ tableData: details, formEdited: true }, () => {
            this.props.handleMessageModalStatus('unsaved', true);
        });
    }

    editData = (dataItem, node) => {
        console.log(node);
        console.log(dataItem);
        this.setState({ inlineButtonField: dataItem.label, setIndex: dataItem.value });
    }

    editList = () => {
        if (this.state.setIndex === 0) {
            if (this.state.inlineButtonField && this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit &&
                !this.state.tableData?.map(item => item?.label?.toLowerCase())?.includes(this.state.inlineButtonField)) {
                let details = this.state.tableData;
                details.push({ value: null, label: this.state.inlineButtonField, check: true })
                this.setState({ tableData: details, formEdited: true, inlineButtonField : ""}, () => {
                    this.setState({ renderList: this.state.tableData});
                    this.props.handleMessageModalStatus('unsaved', true);
                })
            }
        } else {
            if (this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit && this.state.inlineButtonField &&
                !this.state.tableData?.map(item => item?.value === this.state.setIndex ? null : item?.label?.toLowerCase())?.includes(this.state.inlineButtonField)) {
                let details = this.state.tableData;
                details.map(item => {
                    if (item.value === this.state.setIndex) {
                        item.label = this.state.inlineButtonField;
                    }
                });
                this.setState({ tableData: details, formEdited: true, inlineButtonField :"", setIndex:0}, () => {
                     this.props.handleMessageModalStatus('unsaved', true);
                });
            }
        }
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList:tableSortList("Name", this.getSortNode(colName), this.state.tableData, this.state.isSortByAsc)

            });
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList:  this.state.tableData });
    }

    getSortNode = (col) => {
        switch (col) {
            case "Value": return "label";
            case "Active": return "staticMode";
        }
    }

    handleSubmit = () => {
        this.setState({ isSubmit: true });
        let lookupArray = this.state.tableData.map(item =>
            ({ name: item.label, id: item.value, is_active: item.check ? 1 : 0 })
        );
        let lookupJson = {
            "table_name": this.state.selectedType,
            "values": lookupArray
        };
        SetManagerService.postDataParams(Constants.setManagerServiceBaseUrl + '/staticData', lookupJson, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ isSubmit: false },
                    () => {
                        if(response.data.error) {
                            this.props.handleMessageModalStatus('fail', true);
                        } else {
                            this.props.handleMessageModalStatus('success', true);
                            this.props.userContext?.getSetStaticList(this.props?.userContext?.active_tenant?.tenant_id);
                        }       
                    });
                console.log(response.data[0])
            },
                (err) => {
                    console.log("Error in fetching Work Details:", err)
                    this.setState({ isSubmit: false },
                        () => {
                            this.props.handleMessageModalStatus('fail', true);
                        });
                })
    }

    render(){
         return(
            <MDBContainer className="ManageLookupContainer">
            <MDBRow>
                <MDBCol md={8}>
                    <div className="pt5">
                        <SelectField
                            placeHolderText={"- Select Type -"}
                            value={this.state.selectedType}
                            options={this.state.lookupType}
                            onChange={(e) => {
                                this.fetchDetails(e.target.value)
                            }}
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <div className="pt5">
                    <TableComponent
                        list={this.state.renderList || []}
                        config={manageLookupConfig}
                        isLoading={false}
                        arrayCheckUncheck={this.arrayCheckUncheck}
                        editData={this.editData}
                        sortCallback={this.handleTableColumnClick}
                    />
                </div>
            </MDBRow>
            {/* <MDBRow className="Pagination">
                <TablePagination
                    component="div"
                    count={this.state.tableData ? this.state.tableData?.length : 0}
                    rowsPerPageOptions={[]}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state.rowsPerPage}
                />
            </MDBRow> */}
            <MDBRow>
                {!this.state.showContent ?
                    <div className="pt5">
                        <span className="productionFont pl3"> Choose a Lookup Type to View its values</span>
                    </div> : null}
            </MDBRow>
            <MDBRow>
                {this.state.showContent ?
                    <div>
                        <MDBRow>
                            <MDBCol md={12}>
                                <div className="pt5">
                                    <MDBCol md={8} className="inline-block">
                                        <InlineButtonField id={"key20"}
                                            value={this.state.inlineButtonField}
                                            placeholder={this.state.setIndex === 0 ? "Add Value" : "Edit Value"}
                                            onChange={(e) => this.setState({ inlineButtonField: e.target.value })}
                                            buttonOnClick={(e) => this.editList()}
                                            limit={sizeLimits.nameCharacterLimit}
                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                            fieldValid={this.state.setIndex === 0 ? this.state.tableData?.map(item => item?.label?.toLowerCase())?.includes(this.state.inlineButtonField?.toLowerCase()) : 
                                                this.state.tableData?.map(item => item?.value == this.state.setIndex ? null : item?.label?.toLowerCase())?.includes(this.state.inlineButtonField?.toLowerCase())}
                                            inValidInput={"Value already exists"}
                                        />
                                    </MDBCol>
                                    <MDBCol md={4} className="inline-block pl1">
                                        {this.state.setIndex != 0 ?
                                            <Button onClick={() => this.setState({ setIndex: 0, inlineButtonField: "" })}
                                                variant="contained"
                                                size="small"
                                                color="primary">Cancel</Button> : null
                                        }
                                    </MDBCol>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={5} >
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                    disabled={false}
                                    onClick={() => this.handleSubmit()}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={this.props?.secondaryButtonText}
                                    disabled={false}
                                    onClick={() => this.props.onModalClose()}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                    : null}
            </MDBRow>
        </MDBContainer>
         )
    }
}

export default withUserContext(ManageLookup);
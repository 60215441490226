import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import SelectField from  './SelectField/SelectField';
import BasicTextArea from './BasicTextArea/BasicTextArea';

class TableInlineEdits extends React.Component {
  constructor(props) {
    super(props);    
  }

  pickInlineEdits(inputType, inputValue, index, nodeName, selectOptions){
   
    switch(inputType) {
        case 'text':
          return <BasicTextField id={"txt-"+nodeName+"-"+index} 
                    value={inputValue}                        
                    onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}          
                    />;
          break;
        case 'textarea':
          return <BasicTextArea id={"txtarea-"+nodeName+"-"+index} 
                    value={inputValue}
                    rows ={2}  
                    showAsLabel = {false}  
                    onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}          
          />;
          break;
        case 'checkbox':
          return (inputValue !== null ? <BasicCheckbox id={"cb-"+nodeName+"-"+index}  checked ={inputValue}
                      //onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1": "0"))}                          
                      onChange={(e) => this.props.handleInlineEditChange((e.target.checked ? true: false), index, nodeName)}
                        /> : <> </>);
          break;
        case 'select':
          return <SelectField
                      id={"sel-"+nodeName+"-"+index} 
                      size="small"
                      label={name}
                      value={inputValue || ""}
                      options={selectOptions}
                      onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}                                   
                                  />;   
          break;                     
        default:
          return <></>;
      }

  }


  render() {
    return (
    <>
    {this.pickInlineEdits(this.props?.inputType, this.props?.inputValue, this.props?.index, this.props?.nodeName, this.props?.selectOptions)}
    </>) 
  }
}

export default TableInlineEdits;
import React from 'react';
import DateField from '../SharedComponents/DateField/DateField';
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import ImageCard from '../../Common/ImageComponent/ImageCard';
import './CommonCatalog.scss'
import { withUserContext } from '../../contexts/UserContext';
// import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
import MDBSortable from 'mdb-react-sortable';

import CatalogHeader from './CatalogHeader';
import {
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
class CommonCatalog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            catalogFiles: this.props?.catalogFiles,
            isAllChecked: false
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.catalogFiles !== this.props?.catalogFiles) {
            this.setState({ catalogFiles: this.props?.catalogFiles })
        }
    }
    handleCheckboxChange(e) {
        console.log(e);
        let checked = e.target.checked;
        let url = e.target?.value;
        let id = e.target?.id;
        let catalogFilesCheckedUnchecked = this.state.catalogFiles.map((item, index) => {
            let newItem = { ...item }
            if (index == id?.split("_")?.[0]) {
                newItem.checked = checked;
            }
            return newItem;
        })
        if (catalogFilesCheckedUnchecked?.every(item => item?.checked == true)) {
            this.setState({ isAllChecked: true })
        } else {
            this.setState({ isAllChecked: false })
        }
        this.setState({ catalogFiles: catalogFilesCheckedUnchecked }, () => {
            if (this.props?.catalogType === 'print' || this.props?.catalogType === "export") {
                let allSelected = this.handleAllSelected();
                this.props?.getAllSelectedFiles(allSelected);
            }
        })
    }
    deselectSelectAll() {
        if (this.state.isAllChecked) {
            let catalogFilesCheckedUnchecked = this.state.catalogFiles.map((item) => {
                let newItem = { ...item }
                newItem.checked = false;
                return newItem
            });
            this.setState({ catalogFiles: catalogFilesCheckedUnchecked, isAllChecked: false }, () => {
                if (this.props?.catalogType === 'print' || this.props?.catalogType === "export") {
                    let allSelected = this.handleAllSelected();
                    this.props?.getAllSelectedFiles(allSelected);
                }
            })
        } else {
            let catalogFilesCheckedUnchecked = this.state.catalogFiles.map((item) => {
                let newItem = { ...item }
                newItem.checked = true;
                return newItem
            });
            this.setState({ catalogFiles: catalogFilesCheckedUnchecked, isAllChecked: true }, () => {
                if (this.props?.catalogType === 'print' || this.props?.catalogType === "export") {
                    let allSelected = this.handleAllSelected();
                    this.props?.getAllSelectedFiles(allSelected);
                }
            })
        }
    }
    componentDidMount() {
        this.deselectSelectAll()
        if (this.props?.catalogRef) {
            this.props.catalogRef.current = {
                getAllSelected: this.handleAllSelected.bind(this)
            }
        }
        // this.getFiles()
    }
    returnFileUrl(filename) {
        return this.state.catalogFiles.filter((obj) => obj.name === filename)?.[0].url;
    }

    handleAllSelected() {
        return this.state.catalogFiles?.filter(item => item.checked === true);
    }
    handleTooltip(name) {
        let setTooltips = this.state.catalogFiles.map((item) => {
            let newItem = { ...item }
            if (newItem.name === name) {
                newItem.showtooltip = !newItem.showtooltip;
            }
            else {
                newItem.showtooltip = false
            }
            return newItem
        });
        this.setState({ catalogFiles: setTooltips })
    }
    getPageMargins = () => {
        return `@page { size: letter landscape; margin: 15px !important; }
                @media print {                  
                    .pagebreak { 
                        page-break-before: always !important; 
                     }
                    .catalog-print-container{
                        width:100%;
                        break-inside: auto !important;
                        page-break-inside: avoid !important;
                    }
                    .CommonCatalogContainer {
                        font-size: 13px !important;
                        color: black !important;
                        font-family : Times-Roman !important;
                        line-height: 15px  !important;
                    }
                   
                    .catalog-print-item-container{
                        margin: 0.4%;
                        display:inline-block;   
                        width: calc(50% - 1%);                        
                        height:330px;                   
                    }
                    
                    .catalog-print-item{
                        width: calc(50% - 1.5%);                        
                        height:330px;                      
                        vertical-align: middle;
                        text-align: center;
                        display: table-cell;
                    }
                   
                    .img-thumb{ 
                        max-width: 100%;                       
                        width:100%;
                        height:100%;
                        display: block;
                        margin: 0 auto;                       
                    }
                    .row {
                        --bs-gutter-x: 1.5rem;
                        --bs-gutter-y: 0;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: calc( var(--bs-gutter-y) * -1);
                        margin-right: calc( var(--bs-gutter-x) / -2);
                        margin-left: calc( var(--bs-gutter-x) / -2);
                    }                                                                                               
                    .col-md-4 {
                        flex: 0 0 auto;
                        width: 33.33333%;
                    }
                   

                }                
        `;
        // @media (orientation:landscape){ 
        //     .catalog-print-item-container{
        //         height: 200px;
        //     }
        //     .catalog-print-item{
        //         height: 200px;   
        //     }
        // }
        // @media (orientation:portrait){ 
        //     .catalog-print-item-container{
        //         height: 400px;
        //     }
        //     .catalog-print-item{
        //         height: 400px;   
        //     }
        // }
        // return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };


    render() {
        const { setDetails } = this.props;
        return (
            <div className='CommonCatalogContainer' id={this.props?.id}>
                {this.props?.catalogType === 'printPreview' &&
                    <style>{this.getPageMargins()}</style>
                }
                {this.props?.showCheckbox && this.props.catalogFiles.length > 0 &&
                    <MDBRow>
                        <span><b>Select Photos</b>{this.props?.catalogType === 'email' && <span className='text-danger'>*</span>}</span>
                        {this.props?.showSelectMandatory &&
                            <span className='errorText'>Please complete this mandatory field</span>
                        }
                    </MDBRow>
                }
                <CatalogHeader
                    showName={this.props?.showName}
                    setDetails={setDetails}
                />
                {
                    this.props.catalogFiles.length === 0 && <div className = 'noCatalog'>No Catalog Images</div>
                }
                {this.props?.showCheckbox === true && this.props.catalogFiles.length > 0 && <div className='deselect' onClick={this.deselectSelectAll.bind(this)}>{this.state.isAllChecked ? 'Deselect all' : 'Select all'}</div>}
                {this.props?.catalogType === 'printPreview' ?
                    <>
                        <div className='catalog-print-container'>
                            {this.state?.catalogFiles?.map((item, index) => {
                                return (
                                    <>
                                    {(index % 4 === 0 && index != 0) &&
                                    <>
                                        <div class="pagebreak"></div>
                                        <CatalogHeader
                                            showName={this.props?.showName}
                                            setDetails={setDetails}
                                        />
                                    </>
                                    }
                                    <div className='catalog-print-item-container'>
                                        <div className='catalog-print-item'>
                                            <ImageCard src={item.url} />
                                        </div>
                                    </div>
                                    </>
                                )
                            })}
                        </div>
                    </>
                    :
                    <div className='common-catalog-container'>
                        {this.state?.catalogFiles?.map((item, index) => {
                            return (

                                <div key={item?.url} className={`catalog-item-container`}>
                                    {this.props?.showCheckbox === true &&
                                        <BasicCheckbox
                                            key={item.name + "_" + index + "_" + this.props?.catalogType}
                                            id={index + "_" + item.name + "_" + this.props?.catalogType}
                                            value={item.url}
                                            // label={item.name}
                                            checked={item.checked || false}
                                            onChange={this.handleCheckboxChange.bind(this)}
                                        />
                                    }
                                    <div className={`catalog-item`} key={index + "_" + item.name}>
                                        {this.props?.catalogType === "main" ?
                                            <>
                                                <ImageCard src={item.url} onClick={this.handleTooltip.bind(this, item?.name)} />
                                                {this.props?.userContext?.active_tenant?.permissions?.isModifySet && !this.props?.history ?
                                                    <MDBListGroup style={{ minWidth: '22rem' }} className={item?.showtooltip ? "show" : null}>
                                                        <MDBListGroupItem onClick={() => this.props?.onClickMakePreview(item.name)}>Make Preview</MDBListGroupItem>
                                                        <MDBListGroupItem onClick={() => this.props?.onChangeClick(item.name)}>Change</MDBListGroupItem>
                                                        <MDBListGroupItem onClick={() => this.props?.onDeleteClick(item.name)}>Delete</MDBListGroupItem>
                                                    </MDBListGroup>
                                                    : null}
                                            </>
                                            :
                                            <ImageCard src={item.url} />
                                        }

                                    </div>
                                </div>

                            )
                        })}
                    </div>
                //  </>
                //  :
               
                }

            </div>
        )
    }
}

export default withUserContext(CommonCatalog);

import React from 'react';
import { MDBIcon } from 'mdbreact';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './shared.scss';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 1000,
});

const SearchSelectField = (props) => {
    return (
        props.multiple ?
            <div className="SearchSelectFieldContainer">
                <Autocomplete
                    noOptionsText={props?.noOptionsText ? props?.noOptionsText : 'No Options'}
                    id={props?.id} multiple limitTags={1}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    value={props?.value ? props?.value : null}
                    getOptionLabel={(option) => option.text ? option.text : ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(props, option) => (
                        <>
                            <MenuItem key={props?.value} disabled={props?.is_active === 0 ? true : false} value={props?.value}>
                                    {props?.text} 
                            </MenuItem>
                        </>
                    )}
                    //getOptionDisabled={(option => option?.is_active == 0)}
                    placeholder={props?.placeholder || ""}
                    filterOptions={filterOptions}
                    style={{ width: props.width ? props.width : '200px' }}
                    popupIcon={(props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                    onChange={props?.onChange}
                    renderInput={(params) => <TextField {...params} variant="outlined"  placeholder={props?.placeholder || ""}/>}
                />
            </div> :
            <div className="SearchSelectFieldContainer">
                <Autocomplete
                    noOptionsText={props?.noOptionsText ? props?.noOptionsText : 'No Options'}
                    id={props?.id}
                    disabled={props?.disabled ? props?.disabled : false}
                    options={props?.options ? props?.options : []}
                    value={props?.value ? props?.value : null}
                    getOptionLabel={(option) => option.text ? option.text : ''}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(props, option) => (
                        <>
                            <MenuItem key={props?.value} disabled={props?.is_active === 0 ? true : false} value={props?.value}>
                                    {props?.text} 
                            </MenuItem>
                        </>
                    )}
                    //getOptionDisabled={(option => option?.is_active == 0)}
                    placeholder={props?.placeholder || ""}
                    filterOptions={filterOptions}
                    style={{ width: props.width ? props.width : '200px' }}
                    popupIcon={(props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                    onChange={props?.onChange}
                    renderInput={(params) => <TextField {...params} variant="outlined"  placeholder={props?.placeholder || ""} />}
                />
            </div>
    );
}

export default SearchSelectField;

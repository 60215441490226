import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink, MDBRow
} from 'mdb-react-ui-kit';
import { fade, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import logo from '../assets/logo.png';
import { useHistory } from "react-router-dom";
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import AsynchronousSearch from '../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../contexts/UserContext';
import MessageModal from '../components/SharedComponents/MessageModal';
import Modal from '../components/SharedComponents/Modal/Modal';


export default //withRouter(
    withUserContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false,
                    showManageLookupModal:false
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (tenant_id, tenant_name) => {
                this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            confirmCancelChanges = (isCancel) => {
                //if (isCancel) {
                this.props.userContext.switchTenant(this.state.switch_tenant_id);
                //}
                //else {
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                //}
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }

            toggleManageLookupVisibility=()=>{
                this.setState({ showManageLookupModal: !this.state.showManageLookupModal });
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }

                return (
                    <div id="headerContent">
                        {this.state.showManageLookupModal && <Modal open={this.state.showManageLookupModal || false}
                                        modalName={'ManageLookup'} comfirmModalTitle={'Manage Lookup'}
                                        onClose={this.toggleManageLookupVisibility} />}
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1'>

                                            {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                                            <Link component={RouterLink} className="action-icon" to={'/'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="imageWidth"
                                                //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                                /></Link>

                                            {/* </MDBNavbarLink> */}
                                            <h1 className="app-title">
                                                SET MANAGER
                                            </h1>
                                        </MDBNavbarBrand>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>                                            {/* <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                            <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem> */}
                                            {!this.props?.userContext?.active_tenant?.permissions?.isManageUser && <MDBIcon fas size='2x' icon="bars" 
                                            className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7" 
                                            onClick={() => this.toggleManageLookupVisibility()}/>}
                                            {/* <MDBIcon fas size='2x' icon="user-cog" className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7" /> */}
                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >

                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                    {this.props.userContext?.user_profile?.user_roles?.map((tenant) => {
                                                            if (tenant.tenant_id != this.props.userContext?.active_tenant?.tenant_id)
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}
                                                                    onClick={this.switchTenant.bind(this, tenant.tenant_id, tenant.tenant_name)}>
                                                                     <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{this.props.userContext?.user_profile?.user_name}</small>
                                                                    </MDBDropdownLink>
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{tenant.role_name}</small>
                                                                    </MDBDropdownLink>                                                                 
                                                                   
                                                                </MDBDropdownItem>
                                                            else
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}                                                                >
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{this.props.userContext?.user_profile?.user_name}</small>
                                                                    </MDBDropdownLink>
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{tenant.role_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                        }
                                                        )}
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>

                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching tenant will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    ononfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                            </div>
                        </header>
                        {/* </Router> */}
                    </div>
                );
            }
        }
        )
    );
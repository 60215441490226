import React from 'react';;
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import Field1 from './Field1';
import Field2 from './Field2';
import Field3 from './Field3';
import Field4 from './Field4';
import Field5 from './Field5';
import { MDBFileUpload } from 'mdb-react-file-upload';
import ImageCard from '../../Common/ImageComponent/ImageCard';
import CommonCatalog from '../../components/CommonCatalog/CommonCatalog';
import CatalogHeader from '../../components/CommonCatalog/CatalogHeader';
import "./testcss.scss"
import {
    MDBDropdown,
    MDBDropdownLink,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownHeader,
    MDBDropdownDivider
} from 'mdb-react-ui-kit';
class TestBasicComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogFiles: [{
                checked: true,
                name:"",
                path:"",
                url:"https://picsum.photos/id/237/400/220",                  

            },
            {
                checked: true,
                name:"",
                path:"",
                url:"https://picsum.photos/400/220",                  

            },
            {
                checked: true,
                name:"",
                path:"",
                url:"https://picsum.photos/400/220",                  

            },
            {
                checked: true,
                name:"",
                path:"",
                url:"https://picsum.photos/400/220",                  

            },
            {
                checked: true,
                name:"",
                path:"",
                url:"https://picsum.photos/400/300",                  

            }],
            setDetails:{
                photos_taken_date:"2012-08-21",
                showName:"Living Biblically (By The Book)",
                comments:"show cancelled, triscenic disposal 6-2018.\nshow cancelled, triscenic disposal 6-2018.\nshow cancelled, triscenic disposal 6-2018.\nshow \n\nncelled, triscenic disposal 6-2018.\ncasdxcz cdgrfvdc nhgtres hgt",
            }
        }
    }
    render() {
        return (
            <>
                {/* <MDBCard>
                    <MDBCardBody>
                        <Field1 />
                        <Field2 />
                        <Field3 />
                        <Field4 />
                        <Field5 />
                    </MDBCardBody>
                </MDBCard> */}
                {/* <MDBFileUpload
                    getInputFiles={(files) => console.log(files)}
                    multiple
                    mainError={"something went wrong"}

                //   fileUploadRef={this.props?.innerRef}      
                //   acceptedExtensions={this.props?.accept}        
                /> */}
                {/* <MDBDropdown>
                    <MDBDropdownToggle tag="div">
                        <ImageCard src="https://mdbootstrap.com/img/new/standard/city/042.webp"/>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem>
                            <MDBDropdownLink href="#">Action</MDBDropdownLink>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBDropdownLink href="#">Another action</MDBDropdownLink>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBDropdownLink href="#">Something else here</MDBDropdownLink>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown> */}
                <div className='testPrint'>
                <div className='catalog-print-container'>
                <CatalogHeader
                                            showName={this.state?.setDetails?.showName}
                                            setDetails={this.state?.setDetails}
                                    />
                            {this.state?.catalogFiles?.map((item, index) => {
                                return (
                                    <>
                                     
                                    {(index % 5 === 4) &&
                                    <>
                                        <div class="pagebreak"></div>
                                        <CatalogHeader
                                            showName={this.state?.setDetails?.showName}
                                            setDetails={this.state?.setDetails}
                                        />
                                    </>
                                    }
                                    <div className='catalog-print-item-container'>
                                        <div className='catalog-print-item'>
                                            <ImageCard src={item.url} />
                                        </div>
                                    </div>
                                    </>
                                )
                            })}
                        </div>
                </div>

            </>
        );

    }

}

export default TestBasicComp;
import React from 'react';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { VersionHistoryList } from  '../CadDrawing/config';
import './VersionHistory.scss';
import { withUserContext } from '../../contexts/UserContext';
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel"

class VersionHistory extends React.Component {
    render() {
        return (
            <>
            <MDBRow className="mt-2">
                            <MDBCol md={12} className="underlineLabel">
                                <BasicLabel text="Version History" />
                            </MDBCol>
            </MDBRow>
            <div className='VersionHistoryContainer'>
                {
                    this.props.setDetailsRevampHistory?.map((item, index) => {
                        return (
                            <div key={`${item?.revamp_set_id}${item?.set_id}${index}${item?.revamp_history_id}`}>
                                <MDBRow >
                                    <MDBCol md={9}>
                                        <a className="versionTitle"href="javascript:void(0);" onClick={() => this.props?.showHistoryDetails(item)}>{item.set_name}</a>
                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <span className="versionDate"> {item.revamp_date}</span>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        )
                    })
                }
            </div>
            </>
        )
    }
}
export default withUserContext(VersionHistory);

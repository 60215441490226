import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {  MDBSelect, MDBIcon } from 'mdb-react-ui-kit';
import BasicLabel from '../BasicLabel/BasicLabel';
import './SelectFieldHeader.scss';

export default class SelectFieldHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatoryWarning: "Please complete this mandatory field",
            selectUnion: "Please select Union",
            placeHolderText: props.placeHolderText ? props.placeHolderText : 'Select'
        }
    }

    render() {
        let selectOptions = this.props?.options || [];
        let error = (this.props.showMandatory && !this.props?.value) || this.props.fieldValid || false;
        let showAsLabel = this.props.showAsLabel || false;
        const Placeholder = () => {
            return <div>{this.state.placeHolderText}</div>;
        };
        return (
            <div className={`SelectFieldHeaderContainer ${this.props?.className} ${error ? "error" : ""}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{selectOptions?.find(option => option.value === this.props?.value)?.label || ""} </div> :
                    <Select
                        id={this.props?.id}
                        value={this.props?.value}
                        variant="outlined"                        
                        required={this.props?.isMandatory || false}
                        className={this.props?.className || ""}
                        disabled={this.props?.disabled || false}
                        onChange={this.props?.onChange}                       
                        IconComponent={() =>  (<MDBIcon icon="chevron-down" />)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}>
                        
                        {
                            selectOptions?.map((item, index) => {
                                return <MenuItem key={index} value={item.value} selected={item.value == this.props?.value}>{item.label}</MenuItem>
                            })
                        }
                    </Select>                    
                    }
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                    (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</div>}
            </div>
        );
    }
}

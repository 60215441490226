const cadDrawings = [
    {
        id: 1,
        cad_file: "FileName.vfx",
    },
    {
        id: 2,
        cad_file: "FileName.pdf",
    },
    {
        id: 3,
        cad_file: "FileName.jpeg",
    },
    {
        id: 4,
        cad_file: "FileName.png",
    },
    {
        id: 5,
        cad_file: "FileName.csv",
    },
    {
        id: 6,
        cad_file: "FileName.vfx",
    },
]
const VersionHistoryList = [
    {
        id: 1,
        set_Name: "[Latest Revamped Set Name]",
        date_field: "MM/DD/YYYY"
    },
    {
        id: 2,
        set_Name: "[ Set Name]",
        date_field: "MM/DD/YYYY"
    },
    {
        id: 3,
        set_Name: "[Set Name]",
        date_field: "MM/DD/YYYY"
    },
    {
        id: 4,
        set_Name: "[Set Name]",
        date_field: "MM/DD/YYYY"
    },
    {
        id: 5,
        set_Name: "[Set Name]",
        date_field: "MM/DD/YYYY"
    },
    {
        id: 6,
        set_Name: "[Set Name]",
        date_field: "MM/DD/YYYY"
    },
]
export { cadDrawings, VersionHistoryList}